import { useSubscription } from '@apollo/client';
import {
  TicketEventResponse,
  TicketType,
} from '@bus-tickets-app/utils-constants';
import { TRIP_SUBSCRIPTION } from '../resolvers';

export default function useTripSubscription(
  directTrip: {
    tripId: string;
    date: string;
  },
  returnTrip:
    | undefined
    | {
        tripId: string;
        date: string;
      },
  callback: (data: TicketEventResponse, ticketType: TicketType) => void,
) {
  useSubscription<
    TicketEventResponse,
    { input: { tripId: string; date: string } }
  >(TRIP_SUBSCRIPTION, {
    variables: {
      input: directTrip,
    },
    onData: (response) => {
      const { data } = response?.data;
      if (directTrip.tripId === data?.tripSubscription.tripId) {
        callback(data, 'DIRECT_TRIP');
      }
    },
  });

  useSubscription<TicketEventResponse>(TRIP_SUBSCRIPTION, {
    variables: {
      input: returnTrip,
    },
    skip: !returnTrip,
    onData: (response) => {
      const { data } = response?.data;
      if (returnTrip && returnTrip.tripId === data?.tripSubscription.tripId) {
        callback(data, 'RETURN_TRIP');
      }
    },
  });
}
