import {
  YALLA_BUS_EMAIL,
  YALLA_BUS_FACEBOOK_LINK,
  YALLA_BUS_INSTAGRAM_LINK,
  YALLA_BUS_PHONE_NUMBER,
} from '@bus-tickets-app/utils-constants';
import EmailIcon from '@mui/icons-material/Email';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import PhoneIcon from '@mui/icons-material/Phone';
import { Box, Card, Typography } from '@mui/material';

export default function ContactUsCard() {
  return (
    <Card
      sx={{
        mx: 4,
        py: 4,
        px: { xs: 4, md: 8 },
        display: 'flex',
        flexDirection: 'column',
        gap: 4,
        width: { xs: '100%', md: 'fit-content' },
      }}
    >
      <Box display="flex" gap={2}>
        <EmailIcon color="primary" />
        <Typography>
          <a href={`mailto:${YALLA_BUS_EMAIL}`}>{YALLA_BUS_EMAIL}</a>
        </Typography>
      </Box>
      <Box display="flex" gap={2}>
        <PhoneIcon color="primary" />
        <Typography>
          <a href={`tel:${YALLA_BUS_PHONE_NUMBER}`}>{YALLA_BUS_PHONE_NUMBER}</a>
        </Typography>
      </Box>
      <Box display="flex" gap={2}>
        <FacebookIcon color="primary" />
        <Typography>
          <a href={YALLA_BUS_FACEBOOK_LINK} target="_blank" rel="noreferrer">
            Yalla Bus
          </a>
        </Typography>
      </Box>
      <Box display="flex" gap={2}>
        <InstagramIcon color="primary" />
        <Typography>
          <a href={YALLA_BUS_INSTAGRAM_LINK} target="_blank" rel="noreferrer">
            yallabus993
          </a>
        </Typography>
      </Box>
    </Card>
  );
}
