import { Alert, Snackbar } from '@mui/material';
import { PropsWithChildren } from 'react';

import NotificationProps from './types';

export default function Notification({
  dataTestId,
  open,
  severity,
  children,
  onClose,
}: PropsWithChildren<NotificationProps>) {
  return (
    <Snackbar
      data-testid={dataTestId}
      open={open}
      onClose={onClose}
      anchorOrigin={{
        horizontal: 'center',
        vertical: 'bottom',
      }}
      style={{
        width: 'calc(100% - 16px)',
      }}
    >
      <Alert
        onClose={onClose}
        severity={severity}
        sx={{
          width: '100%',
          maxWidth: '90%',
          marginLeft: 'auto',
          marginRight: 'auto',
        }}
      >
        {children}
      </Alert>
    </Snackbar>
  );
}
