import {
  LANGUAGES,
  LOCAL_STORAGE_LANGUAGE,
} from '@bus-tickets-app/utils-constants';

export const getDefaultLanguage = () => {
  let localStorageLanguage = localStorage.getItem(LOCAL_STORAGE_LANGUAGE);
  if (localStorageLanguage === LANGUAGES.EN) {
    localStorageLanguage = LANGUAGES.EN;
  } else {
    localStorageLanguage = LANGUAGES.AR;
  }
  return localStorageLanguage as LANGUAGES;
};
