import { Box } from '@mui/material';
import { PropsWithChildren } from 'react';

import ModalContentProps from './types';

const ModalContent = ({
  className,
  sx,
  children,
  ...boxProps
}: PropsWithChildren<ModalContentProps>) => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      {...boxProps}
      sx={{
        borderRadius: 2,
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        bgcolor: 'background.paper',
        filter: 'drop-shadow(0 0 4px #00000092)',
        boxShadow: 24,
        ...sx,
      }}
      className={className}
    >
      {children}
    </Box>
  );
};

export default ModalContent;
