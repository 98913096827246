import { Select } from '@mui/material';
import styled from 'styled-components';

export const StyledSelect = styled(Select)`
  & > ul {
    padding: 0px;
    border: none;
  }
  & > div {
    padding: 0px !important;
    display: flex;
  }
  & > svg {
    display: none;
  }
  & > fieldset {
    border: none;
  }
`;
