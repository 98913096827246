import { SearchTicket } from '@bus-tickets-app/utils-constants';
import {
  getSearchTicketRouteParams,
  SearchTicketRouteParams,
} from '@bus-tickets-app/utils-ui';
import { createSearchParams } from 'react-router-dom';

import { URL_SEARCH_PARAMS } from './urlSearchParams';

export default function computeSearchBooking(
  searchTicket: Partial<SearchTicket> & { cityId?: string },
  params?: URLSearchParams,
) {
  const searchTicketRouteParams = getSearchTicketRouteParams(searchTicket, {
    date: params?.get(URL_SEARCH_PARAMS.DATE) || undefined,
    returnDate: params?.get(URL_SEARCH_PARAMS.RETURN_DATE) || undefined,
    numberOfTickets:
      (params?.get(URL_SEARCH_PARAMS.NUMBER_OF_TICKETS)
        ? Number.parseInt(
            params?.get(URL_SEARCH_PARAMS.NUMBER_OF_TICKETS) || '0',
          )
        : 0) || undefined,
  });

  const searchParams = createSearchParams();
  Object.keys(searchTicketRouteParams).map((key) => {
    if (searchTicketRouteParams[key as keyof SearchTicketRouteParams]) {
      searchParams.set(
        key,
        searchTicketRouteParams[
          key as keyof SearchTicketRouteParams
        ]!.toString(),
      );
    }
  });

  return searchParams.toString();
}
