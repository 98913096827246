export * from './auth'
export * from './cancelationPolicy'
export * from './constants'
export * from './days'
export * from './discounts'
export * from './languages'
export * from './payment'
export * from './phone'
export * from './prices'
export * from './subscription'
export * from './time'
export * from './tickets'
export * from './theme'
export * from './tripTimeStatus'
export * from './validations'

export const YALLA_BUS_PHONE_NUMBER = '+964662103118';
export const YALLA_BUS_FACEBOOK_LINK = 'https://www.facebook.com/people/Yalla-bus/100093970951642/';
export const YALLA_BUS_INSTAGRAM_LINK = 'https://www.instagram.com/yallabus993?igsh=MWM2ZjFhbGdrc3cyNw==';
export const YALLA_BUS_EMAIL = 'contact@yallabus.net';
export const YALLA_BUS_CONTRY_CODE = 'IQ' as const;
export const YALLA_BUS_CURRENCY = 'IRQ' as const;
export const YALLA_BUS_COMPANY_NAME = 'Yalla Bus' as const;
export const YALLA_BUS_COMPANY_ADDRESS = 'Baghdad, str My street' as const;
export const YALLA_BUS_INVOICE = 'YB' as const;

export const YALLA_BUS_ZAIN_CASH_STORE_PAYMENNT_PHONE_NUMBER = '9647714021001' as const;

export const noop = () => {}
