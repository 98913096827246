import { gql } from '@apollo/client';

const BUS_QUERY = `
  id
  configurationName
  numberOfSeats
  seatsConfiguration {
    leftColumns
    rightColumns
    rows
    disabledSeats
    lastRowExtraSeats
    seatsCountDirection
  }
  facilities
`;

export const GET_BUSES_QUERY = gql`
  query GetBusConfigurations {
    getBusConfigurations {
      ${BUS_QUERY}
    }
  }
`;

export const CREATE_BUS_MUTATION = gql`
  mutation CreateBusConfiguration($input: BusDataInput!) {
    createBusConfiguration(input: $input) {
      ${BUS_QUERY}
    }
  }
`;

export const EDIT_BUS_MUTATION = gql`
  mutation EditBusConfiguration($busId: String!, $input: BusDataInput!) {
    editBusConfiguration(busId: $busId, input: $input) {
      ${BUS_QUERY}
    }
  }
`;

export const DELETE_BUS_MUTATION = gql`
  mutation DeleteBusConfiguration($busId: String!) {
    deleteBusConfiguration(busId: $busId) {
      id
    }
  }
`;
