import { colorsPalette, LANGUAGES } from '@bus-tickets-app/utils-constants';
import Diversity3Icon from '@mui/icons-material/Diversity3';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import EngineeringIcon from '@mui/icons-material/Engineering';
import GradeIcon from '@mui/icons-material/Grade';
import GroupsIcon from '@mui/icons-material/Groups';
import HandshakeIcon from '@mui/icons-material/Handshake';
import HealthAndSafetyIcon from '@mui/icons-material/HealthAndSafety';
import InsightsIcon from '@mui/icons-material/Insights';
import PaymentIcon from '@mui/icons-material/Payment';
import SportsScoreIcon from '@mui/icons-material/SportsScore';
import StoreIcon from '@mui/icons-material/Store';
import { Box, Typography } from '@mui/material';
import { cloneElement } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import useLogoColoredOnBlue from '../../../hooks/images/useLogoColoredOnBlue';
import useNameColoredOnBlue from '../../../hooks/images/useNameColoredOnBlue';
import useIsLandscape from '../../../hooks/useIsLandscape';
import useIsSmallScreen from '../../../hooks/useIsSmallScreen';

const sections = [
  {
    key: 'companyDetails',
    icon: <StoreIcon gradientTransform="" />,
    gradiend: {
      color1: colorsPalette.blue,
      color2: '#f67070',
    },
    paddingTop: {
      [LANGUAGES.AR]: '3%',
      [LANGUAGES.EN]: '3.5%',
    },
  },
  {
    key: 'innovation',
    icon: <GradeIcon />,
    gradiend: {
      color1: colorsPalette.orange,
      color2: '#f67070',
    },
    paddingTop: {
      [LANGUAGES.AR]: '5%',
      [LANGUAGES.EN]: '6%',
    },
  },
  {
    key: 'safetyAndSecurity',
    icon: <EngineeringIcon />,
    gradiend: {
      color1: colorsPalette.blue,
      color2: '#f6f170',
    },
    paddingTop: {
      [LANGUAGES.AR]: '7%',
      [LANGUAGES.EN]: '6%',
    },
  },
  {
    key: 'communityParticipation',
    icon: <Diversity3Icon />,
    gradiend: {
      color1: colorsPalette.orange,
      color2: '#f6f170',
    },
    paddingTop: {
      [LANGUAGES.AR]: '9%',
      [LANGUAGES.EN]: '8%',
    },
  },
  {
    key: 'mission',
    icon: <HandshakeIcon />,
    gradiend: {
      color1: colorsPalette.blue,
      color2: '#70f694',
    },
    paddingTop: {
      [LANGUAGES.AR]: '8%',
      [LANGUAGES.EN]: '15%',
    },
  },
  {
    key: 'vision',
    icon: <InsightsIcon />,
    gradiend: {
      color1: colorsPalette.orange,
      color2: '#70f694',
    },
    paddingTop: {
      [LANGUAGES.AR]: '8%',
      [LANGUAGES.EN]: '6%',
    },
  },
  {
    key: 'customerNeeds',
    icon: <HealthAndSafetyIcon />,
    gradiend: {
      color1: colorsPalette.blue,
      color2: '#db70f6',
    },
    paddingTop: {
      [LANGUAGES.AR]: '8%',
      [LANGUAGES.EN]: '8%',
    },
  },
  {
    key: 'serviceExcellence',
    icon: <EmojiEventsIcon />,
    gradiend: {
      color1: colorsPalette.orange,
      color2: '#db70f6',
    },
    paddingTop: {
      [LANGUAGES.AR]: '8%',
      [LANGUAGES.EN]: '9%',
    },
  },
  {
    key: 'paymentMethods',
    icon: <PaymentIcon />,
    gradiend: {
      color1: colorsPalette.blue,
      color2: '#f67070',
    },
    paddingTop: {
      [LANGUAGES.AR]: '9%',
      [LANGUAGES.EN]: '10%',
    },
  },
  {
    key: 'expansionAndGrowth',
    icon: <SportsScoreIcon />,
    gradiend: {
      color1: colorsPalette.orange,
      color2: '#f67070',
    },
    paddingTop: {
      [LANGUAGES.AR]: '8%',
      [LANGUAGES.EN]: '8%',
    },
  },
  {
    key: 'collaboration',
    icon: <GroupsIcon />,
    gradiend: {
      color1: colorsPalette.blue,
      color2: '#8050c0',
    },
    paddingTop: {
      [LANGUAGES.AR]: '8%',
      [LANGUAGES.EN]: '8%',
    },
  },
];

export default function About() {
  const { t, i18n } = useTranslation();
  const language = i18n.language as LANGUAGES;
  const isLandscape = useIsLandscape();
  const isSmallScreen = useIsSmallScreen();
  const LogoImage = useLogoColoredOnBlue();
  const NameImage = useNameColoredOnBlue();

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
      mb={2}
    >
      <Box
        display="flex"
        alignItems="center"
        flexDirection={{
          xs: isLandscape ? 'row' : 'column',
          md: 'row',
        }}
        width="fill-available"
        justifyContent="center"
        py={2}
        sx={{
          backgroundColor: colorsPalette.blue,
          color: 'white',
          boxShadow: `0px 2px 4px -1px ${colorsPalette.blue}80, 0px 4px 5px 0px ${colorsPalette.blue}86, 0px 1px 10px 0px ${colorsPalette.blue}88`,
        }}
        gap={{
          xs: isLandscape ? 8 : 1,
          md: 8,
        }}
      >
        <Box
          display="flex"
          alignItems="center"
          flexDirection="row"
          justifyContent="center"
          gap={4}
        >
          <img src={LogoImage} alt="Yalla Bus" style={{ height: '80px' }} />
          <img src={NameImage} alt="Yalla Bus" style={{ height: '60px' }} />
        </Box>
      </Box>
      {sections.map(({ key, icon, gradiend, paddingTop }, index) => {
        const Icon = cloneElement(icon, {
          style: {
            fill: `url(#gradient-${key})`,
            fontSize: '300px',
          },
        });
        return (
          <Box
            key={key}
            width="fill-available"
            paddingRight={
              isSmallScreen ? '32px' : index % 2 === 0 ? '100px' : '36px'
            }
            paddingLeft={
              isSmallScreen ? '32px' : index % 2 !== 0 ? '100px' : '36px'
            }
            sx={
              isSmallScreen
                ? {
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                  }
                : {}
            }
          >
            <svg
              width="300px"
              height="300px"
              style={{
                float: index % 2 === 0 ? 'left' : 'right',
                marginRight: isSmallScreen
                  ? 'auto'
                  : index % 2 === 1
                    ? '24px'
                    : '64px',
                marginLeft: isSmallScreen
                  ? 'auto'
                  : index % 2 !== 1
                    ? '24px'
                    : '64px',
              }}
            >
              <linearGradient
                id={`gradient-${key}`}
                x1="100%"
                y1="100%"
                x2="0%"
                y2="0%"
              >
                <stop stopColor={gradiend.color1} offset="0%" />
                <stop stopColor={gradiend.color2} offset="100%" />
              </linearGradient>
              {Icon}
            </svg>
            <Typography
              textAlign="justify"
              whiteSpace="pre-wrap"
              pt={isSmallScreen ? 0 : paddingTop[language]}
              mt={isSmallScreen ? '-24px' : 0}
              sx={
                isSmallScreen
                  ? {}
                  : {
                      marginLeft: index % 2 === 1 ? 0 : '64px',
                      marginRight: index % 2 !== 1 ? 0 : '64px',
                    }
              }
            >
              <Trans t={t} i18nKey={`about.${key}`} />
            </Typography>
          </Box>
        );
      })}
    </Box>
  );
}
