import { TICKET_STATUS, Ticket } from "../models";
import { LANGUAGES } from "../translations";

export const CALL_CENTER = 'callCenter'

export interface ReportInput {
  startDateSoldOn: string;
	endDateSoldOn: string;
	startDateSoldFor: string;
	endDateSoldFor: string;
	ticketStatus?: TICKET_STATUS | 'all';
	operator?: string;
	selfPurchased?: boolean;
	operatorPurchased?: boolean;
	authenticatedUser?: boolean;
	soldInLocation?: string;
	departureLocation?: string;
	arrivalLocation?: string;
	trip?: string;
	discount?: string;
}

export type ReportResponse = ({ createdAt: string } & Ticket)[]

export interface SendReport {
  email: string;
	fileName: string;
	data: string;
	language: LANGUAGES;
	isReturnTicket?: boolean;
}
