import { useMutation } from '@apollo/client';
import {
  CONFIRM_CLIENT_BOOKING_MUTATION,
  useCurrentCustomer,
  usePaymentSubscription,
  useRequenceBookTicketInformation,
  useTripSubscription,
} from '@bus-tickets-app/utils-apollo';
import {
  APOLLO_ERRORS,
  BookTicketInformation,
  BookTicketInformationResponse,
  BookTicketWithPaymentResponse,
  ClientDetails,
  CustomerBookTicketRequest,
  DEFAULT_PHONE_PREFIX,
  LANGUAGES,
  TicketEventResponse,
  TicketType,
  getTripStatusOnLastBooking,
} from '@bus-tickets-app/utils-constants';
import { useEffect, useState } from 'react';
import { PromoDetailCode } from '../types';

const initialClientDetails: ClientDetails = {
  name: '',
  email: '',
  phone: DEFAULT_PHONE_PREFIX,
};

export default function useBookTicket(
  t: any,
  language: LANGUAGES,
  initialTripDetails: {
    startLocationId: string;
    endLocationId: string;
    directTrip: {
      id: string;
      date: string;
    };
    returnTrip:
      | {
          id: string | undefined;
          date: string | undefined;
        }
      | undefined;
    numberOfTickets: number;
  },
  isPhoneValid: (phone: string) => boolean,
  onPaymentSubscriptionData: (transactionOrderId: string) => void,
  redirectToPayment: (paymentLink: string) => void,
  options: {
    DISABLE_BOOKING: boolean,
    WITH_PHONE_PREFIX: boolean,
  }
) {
  const [pending, setPending] = useState(false);
  const [laterPayment, setLaterPayment] = useState(false);
  const [silentNotification, setSilentNotification] = useState(false);
  const [apolloError, setApolloError] = useState<null | APOLLO_ERRORS>(null);
  const [ticketInformation, setTicketInformation] =
    useState<BookTicketInformationResponse | null>(null);
  const [availableSeatsOnTrip, setAvailableSeatsOnTrip] = useState<number>(-1);
  const [availableSeatsOnReturnTrip, setAvailableSeatsOnReturnTrip] =
    useState<number>(-1);

  const [ticket, setTicket] = useState<BookTicketInformation>({
    ...initialTripDetails,
    returnTrip:
      initialTripDetails.returnTrip?.id && initialTripDetails.returnTrip?.date
        ? (initialTripDetails.returnTrip as { id: string; date: string })
        : undefined,
  });

  const [applyFidelityDiscount, setApplyFidelityDiscount] = useState(false);
  const { user } = useCurrentCustomer();
  const [clientDetails, setClientDetails] = useState(options.WITH_PHONE_PREFIX ? initialClientDetails : {
    ...initialClientDetails,
    phone: '',
  });
  const [paymentLink, setPaymentLink] = useState<string | null>(null);
  const [transactionOrderId, setTransactionOrderId] = useState<
    string | undefined
  >(undefined);
  const [onlineBookingConfirmed, setOnlineBookingConfirmed] = useState(false);
  const [cancellingBooking, setCancellingBooking] = useState(false);
  const [promoDetailCode, setPromoDetailCode] = useState<
    PromoDetailCode | undefined
  >(undefined);

  usePaymentSubscription(transactionOrderId, () => {
    onPaymentSubscriptionData(transactionOrderId!);
  });

  const { refetch } = useCurrentCustomer();
  useEffect(() => {
    if (paymentLink) {
      refetch();
    }
  }, [paymentLink])

  const onBookTicketResponse = (
    bookTicketInformationResponse: BookTicketInformationResponse,
  ) => {
    setTicketInformation(bookTicketInformationResponse);

    const availableSeatsOnTrip =
      bookTicketInformationResponse.directTicket.bus.numberOfSeats -
      bookTicketInformationResponse.directTicket.seatsUnavailable.length;
    setAvailableSeatsOnTrip(availableSeatsOnTrip);
    
    if (bookTicketInformationResponse.returnTicket) {
      const availableSeatsOnReturnTrip =
        bookTicketInformationResponse.returnTicket.bus.numberOfSeats -
        bookTicketInformationResponse.returnTicket.seatsUnavailable.length;
      setAvailableSeatsOnReturnTrip(availableSeatsOnReturnTrip);
    }
  };

  const requestBookTicketInformation = useRequenceBookTicketInformation(
    setPending,
    setApolloError,
    onBookTicketResponse,
  );

  useTripSubscription(
    { tripId: ticket.directTrip.id, date: ticket.directTrip.date },
    ticket.returnTrip
      ? { tripId: ticket.returnTrip.id, date: ticket.returnTrip.date }
      : undefined,
    (data: TicketEventResponse, ticketType: TicketType) => {
      if (!paymentLink && !pending) {
        if (ticketType === 'DIRECT_TRIP') {
          setAvailableSeatsOnTrip(data.tripSubscription.availableSeats);
        } else {
          setAvailableSeatsOnReturnTrip(data.tripSubscription.availableSeats);
        }
        requestBookTicketInformation(ticket);
        setSilentNotification(true);
      }
    },
  );

  const [confirmBookingMutation] = useMutation<
    { confirmClientBooking: BookTicketWithPaymentResponse },
    { input: CustomerBookTicketRequest }
  >(CONFIRM_CLIENT_BOOKING_MUTATION, {
    onCompleted: ({ confirmClientBooking }) => {
      setPending(false);
      const { paymentLink, transactionOrderId } = confirmClientBooking;
      if (!paymentLink || !transactionOrderId) {
        setApolloError(APOLLO_ERRORS.UNABLE_INITIATE_ONLINE_PAYMENT);
        return;
      }

      setPaymentLink(paymentLink);
      setTransactionOrderId(transactionOrderId);
      if (!laterPayment) {
        redirectToPayment(paymentLink);
      }
    },
    onError: (error) => {
      setPending(false);
      setApolloError(error.message as APOLLO_ERRORS);
      if (error.message === APOLLO_ERRORS.SEATS_UNAVAILABLE) {
        requestBookTicketInformation(ticket);
      }
    },
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    requestBookTicketInformation(ticket);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onConfirmBooking = (payLater: boolean) => {
    if (disableConfirmBookingButton) {
      return;
    }
    setApolloError(null)
    setLaterPayment(payLater);
    setPending(true);
    const directTripTimeStatus = getTripStatusOnLastBooking(
      ticketInformation!.directTicket.date,
      ticketInformation!.directTicket.startTripTime,
    );
    const returnTripTimeStatus = ticketInformation!.returnTicket
      ? getTripStatusOnLastBooking(
          ticketInformation!.returnTicket.date,
          ticketInformation!.returnTicket!.startTripTime,
        )
      : undefined;
    const input: CustomerBookTicketRequest = {
      startLocationId: ticket.startLocationId,
      endLocationId: ticket.endLocationId,
      directTrip: {
        tripId: ticket.directTrip.id,
        date: ticket.directTrip.date,
        tripTimeStatus: directTripTimeStatus,
      },
      returnTrip: ticket.returnTrip
        ? {
            tripId: ticket.returnTrip.id,
            date: ticket.returnTrip.date,
            tripTimeStatus: returnTripTimeStatus!,
          }
        : undefined,
      language,
      nrOfSeats: ticket.numberOfTickets,
      applyFidelityDiscount,
      clientDetails: !user
        ? clientDetails
        : {
            name: `${user.firstName} ${user.firstName}`,
            phone: user.phone,
            email: user.email,
          },
      promoDetailCode: promoDetailCode
        ? {
            promoCode: promoDetailCode.promoCode,
            discountId: promoDetailCode.discount.id,
          }
        : undefined,
      sendPaymentNotifications: payLater,
    };
    confirmBookingMutation({
      variables: {
        input,
      },
    });
  };

  const disableConfirmBookingButton: string = options.DISABLE_BOOKING
    ? t('soon')
    : user
      ? ''
      : !clientDetails.name
        ? t('validations.missingField', {
            field: t('tickets.userDetails.name'),
          })
        : !clientDetails.phone || !isPhoneValid(clientDetails.phone)
          ? t('validations.invalidPhone')
          : '';

  return {
    pending,
    apolloError,
    ticket,
    ticketInformation,
    cancellingBooking,
    paymentLink,
    onlineBookingConfirmed,
    clientDetails,
    silentNotification,
    disableConfirmBookingButton,
    laterPayment,
    availableSeatsOnTrip,
    availableSeatsOnReturnTrip,
    applyFidelityDiscount,
    promoDetailCode,
    setTicket,
    setPending,
    setApolloError,
    setTicketInformation,
    setCancellingBooking,
    setOnlineBookingConfirmed,
    setClientDetails,
    onConfirmBooking,
    setApplyFidelityDiscount,
    setPromoDetailCode,
  };
}
