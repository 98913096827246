import { colorsPalette } from '@bus-tickets-app/utils-constants';
import styled, { css } from 'styled-components';

import TripRoad from '../../tripRoad/TripRoad';

export const StyledTripRoad = styled(TripRoad)<{ $selected?: boolean }>`
  ${({ $selected }) =>
    $selected &&
    css`
      box-shadow:
        0px 2px 4px -1px ${colorsPalette.orange}80,
        0px 2px 5px 0px ${colorsPalette.orange}86,
        0px 1px 10px 0px ${colorsPalette.orange}88 !important;
    `}
`;
