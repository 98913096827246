import { useContext } from 'react';

import CurrentCustomerContext from './CurrentCustomerContext';
import CurrentCustomerContextProps from './types';

export default function useCurrentCustomerContext(): CurrentCustomerContextProps {
  const currentCustomerContext = useContext(CurrentCustomerContext);

  if (!currentCustomerContext) {
    throw new Error(
      'useCurrentCustomerContext must be inside a CurrentCustomerContext provider',
    );
  }

  return currentCustomerContext;
}
