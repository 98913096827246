export const BOOKING_DATA_FIELDS = [
  {
    key: 'bookingName',
    label: 'bookingName',
  },
  {
    key: 'priceAfterDiscount',
    label: 'price',
  },
  {
    key: 'date',
    label: 'date',
  },
  {
    key: 'startLocationName',
    label: 'startLocationName',
  },
  {
    key: 'startTripTime',
    label: 'startTripTime',
  },
  {
    key: 'endLocationName',
    label: 'endLocationName',
  },
  {
    key: 'endTripTime',
    label: 'endTripTime',
  },
] as const;
