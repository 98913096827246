import { useLazyQuery, useMutation } from '@apollo/client';
import {
  GET_MY_BOOKINGS_QUERY,
  PAY_PENDING_BOOKING_MUTATION,
  usePaymentSubscription,
} from '@bus-tickets-app/utils-apollo';
import {
  APOLLO_ERRORS,
  GroupedTickets,
  LANGUAGES,
  PayPendingBookingRequest,
  TICKET_STATUS,
} from '@bus-tickets-app/utils-constants';
import { useEffect, useState } from 'react';

export default function useMyBookings(
  language: LANGUAGES,
  onPaymentSubscriptionData: (transactionOrderId: string) => void,
  onOpenPaymentLink: (paymentLink: string) => void,
) {
  const [paymentLink, setPaymentLink] = useState<string | null>(null);
  const [apolloError, setApolloError] = useState<null | APOLLO_ERRORS>(null);
  const [pending, setPending] = useState(false);
  const [selectedBookingIndex, setSelectedBookingIndex] = useState(-1);
  const [bookings, setBookings] = useState<GroupedTickets[]>([]);
  const [sendPaymentNotifications, setSendPaymentNotifications] =
    useState(false);
  const [transactionOrderId, setTransactionOrderId] = useState<
    string | undefined
  >(undefined);

  usePaymentSubscription(transactionOrderId, () =>
    onPaymentSubscriptionData(transactionOrderId!),
  );

  const [getSearchBookingsQuery] = useLazyQuery<{
    getMyBookings: GroupedTickets[];
  }>(GET_MY_BOOKINGS_QUERY, {
    onCompleted: ({ getMyBookings }) => {
      setPending(false);
      setBookings(getMyBookings);
    },
    onError: (error) => {
      setPending(false);
      setApolloError(error.message as APOLLO_ERRORS);
    },
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    setPending(true);
    getSearchBookingsQuery();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [payPendingBookingMutation] = useMutation<
    { payPendingBooking: { paymentLink: string; transactionOrderId: string } },
    PayPendingBookingRequest
  >(PAY_PENDING_BOOKING_MUTATION, {
    onCompleted: ({ payPendingBooking }) => {
      setPending(false);
      const { paymentLink, transactionOrderId } = payPendingBooking;
      if (!paymentLink || !transactionOrderId) {
        setApolloError(APOLLO_ERRORS.UNABLE_INITIATE_ONLINE_PAYMENT);
        return;
      }

      setPaymentLink(paymentLink);
      setTransactionOrderId(transactionOrderId);
      if (!sendPaymentNotifications) {
        onOpenPaymentLink(paymentLink);
      }
    },
    onError: (error) => {
      setPending(false);
      setApolloError(error.message as APOLLO_ERRORS);
    },
    fetchPolicy: 'network-only',
  });

  const onPayPendingBooking = (sendPaymentNotifications: boolean) => {
    setPending(true);
    setPaymentLink(null);
    setTransactionOrderId(undefined);
    if (apolloError) {
      setApolloError(null);
    }
    setSendPaymentNotifications(sendPaymentNotifications);
    payPendingBookingMutation({
      variables: {
        ticketIds: bookings[selectedBookingIndex].tickets.map(
          (ticket) => ticket.id,
        ),
        language,
        sendPaymentNotifications,
      },
    });
  };

  const needsPayment =
    selectedBookingIndex > -1 &&
    bookings[selectedBookingIndex] &&
    bookings[selectedBookingIndex]._id.status === TICKET_STATUS.PENDING;

  const showPrintTicketButton =
    selectedBookingIndex > -1 &&
    bookings[selectedBookingIndex] && 
    bookings[selectedBookingIndex]._id.status === TICKET_STATUS.BOOKED;

  const onSelectBooking = (bookingIndex: number) => {
    if (bookingIndex === selectedBookingIndex) {
      setSelectedBookingIndex(-1);
      return;
    }
    setSelectedBookingIndex(bookingIndex);
  };

  return {
    bookings,
    pending,
    apolloError,
    selectedBookingIndex,
    paymentLink,
    sendPaymentNotifications,
    needsPayment,
    showPrintTicketButton,
    setApolloError,
    onPayPendingBooking,
    onSelectBooking,
  };
}
