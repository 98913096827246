import { gql } from '@apollo/client';
import { LANGUAGES } from '@bus-tickets-app/utils-constants';

export const DISCOUNT_QUERY_SELECTION = `
  {
    id
    type
    name
    percentage
    firstDate
    lastDate
    deleted
    ${LANGUAGES.EN} {
      name
      description
    }
    ${LANGUAGES.AR} {
      name
      description
    }
    valability
    codes
  }
`;

export const GET_ALL_DISCOUNTS_QUERY = (query?: string) => gql`
  query GetAllDiscounts {
    getAllDiscounts ${query || DISCOUNT_QUERY_SELECTION}
  }
`;

export const GET_ACTIVE_DISCOUNTS_QUERY = gql`
  query GetActiveDiscounts {
    getActiveDiscounts ${DISCOUNT_QUERY_SELECTION}
  }
`;

export const GET_FIDELITY_POINTS_DISCOUNT = gql`
  query GetFidelityPointsDiscount {
    getFidelityPointsDiscount 
  }
`;


export const CREATE_DISCOUNT_MUTATION = gql`
  mutation CreateLDiscount($input: DiscountInput!) {
    createDiscount(input: $input) {
      id
    }
  }
`;

export const EDIT_DISCOUNT_MUTATION = gql`
  mutation EditDiscount($discountId: String!, $input: DiscountInput!) {
    editDiscount(discountId: $discountId, input: $input) {
      id
    }
  }
`;

export const DELETE_DISCOUNT_MUTATION = gql`
  mutation DeleteDiscount($discountId: String!) {
    deleteDiscount(discountId: $discountId) {
      id
    }
  }
`;

export const CHECK_PROMO_CODE_VALIDITY_QUERY = gql`
  query CheckPromoCodeValidity($promoCode: String!) {
    checkPromoCodeValidity(promoCode: $promoCode) {
      id
      type
      percentage
      ${LANGUAGES.EN} {
        name
        description
      }
      ${LANGUAGES.AR} {
        name
        description
      }
    }
  }
`;
