import { APOLLO_ERRORS, VERIFY_TOKEN } from '@bus-tickets-app/utils-constants';
import {
  DATA_TESTID_VERIFY_EMAIL_PASSWORD_TOAST,
  DATA_TESTID_VERIFY_EMAIL_STATUS_TOAST,
  useVerifyEmailContext,
} from '@bus-tickets-app/utils-ui';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import Notification from '../../notification';

type VERIFY_STATUS = 'true' | 'false' | APOLLO_ERRORS | null;

const VerifyEmail = () => {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const [verifyStatus, setVerifyStatus] = useState<VERIFY_STATUS>(
    searchParams.get(VERIFY_TOKEN.STATUS) as VERIFY_STATUS,
  );
  const { needToVerifyEmail, setNeedToVerifyEmail } = useVerifyEmailContext();

  useEffect(() => {
    const searchParam = searchParams.get(VERIFY_TOKEN.STATUS) as VERIFY_STATUS;
    if (searchParam) {
      setVerifyStatus(searchParam);
    }
  }, [searchParams]);

  const onCloseNotification = () => setNeedToVerifyEmail(false);
  const onCloseStatusNotification = () => {
    setVerifyStatus(null);
    setSearchParams('');
  };

  if (!needToVerifyEmail) {
    if (verifyStatus) {
      return (
        <Notification
          dataTestId={DATA_TESTID_VERIFY_EMAIL_STATUS_TOAST}
          open={!!verifyStatus}
          onClose={onCloseStatusNotification}
          severity={verifyStatus === 'true' ? 'success' : 'error'}
        >
          <span>
            {verifyStatus === 'true'
              ? t('authentication.emailVerified')
              : verifyStatus === 'false'
                ? t(`apolloErrors.${APOLLO_ERRORS.FAILED_TO_ACTIVATE_ACCOUNT}`)
                : t(`apolloErrors.${verifyStatus}`)}
          </span>
        </Notification>
      );
    }
    return null;
  }

  return (
    <Notification
      dataTestId={DATA_TESTID_VERIFY_EMAIL_PASSWORD_TOAST}
      open={!!needToVerifyEmail}
      onClose={onCloseNotification}
      severity="success"
    >
      {t('authentication.verifyPassword')}
    </Notification>
  );
};

export default VerifyEmail;
