import { SearchTicket } from '@bus-tickets-app/utils-constants';
import dayjs from 'dayjs';

export default function useSearchBookingValidation(
  t: any,
) {
  const onValidateTicket = (searchTicket: SearchTicket, hasReturnTicket: boolean) => {
    const missingField = (
      ['startLocationId', 'endLocationId', 'date', 'numberOfTickets'] as const
    ).find((field) => !searchTicket[field]);
    if (missingField) {
      return t('validations.missingField', { field: t(`tickets.${missingField}`) });
    }
    if (hasReturnTicket && !searchTicket.returnDate) {
    return t('validations.missingField', { field: t('tickets.returnDate') });
    }
    if (hasReturnTicket && searchTicket.returnDate! < searchTicket.date) {
      return t('tickets.validationReturnDate');
    }
    if (searchTicket.startLocationId === searchTicket.endLocationId) {
      return t('validations.differentStartLocationAndDestination');
    }
    if (
      !dayjs(searchTicket.date).isValid() ||
      (searchTicket.returnDate && !dayjs(searchTicket.returnDate).isValid())
    ) {
      return t('validations.invalidDate');
    }
    if (!searchTicket.numberOfTickets) {
      return t('validations.emptyNumberOfTickets');
    }
  }

  return onValidateTicket;
}