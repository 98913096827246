import { gql } from '@apollo/client';

export const LOGIN_ADMIN_USER_MUTATION = gql`
  mutation LoginAdminUser($username: String!, $password: String!) {
    loginAdminUser(username: $username, password: $password) {
      accessToken
      refreshToken
    }
  }
`;

export const CHANGE_PASSWORD_FOR_LOGGEDIN_USER_MUTATION = gql`
  mutation ChangePasswordForLoggedInUser(
    $password: String!
    $passwordConfirm: String!
    $currentPassword: String!
  ) {
    changePasswordForLoggedInUser(
      password: $password
      passwordConfirm: $passwordConfirm
      currentPassword: $currentPassword
    ) {
      error
    }
  }
`;

export const SIGN_UP_CUSTOMER = gql`
  mutation SignUp($input: SignUpInput!) {
    signUpUser(input: $input) {
      error
    }
  }
`;

export const RESET_PASSWORD_CUSTOMER = gql`
  mutation ResetPassword(
    $password: String!
    $passwordConfirm: String!
    $token: String!
    $userId: String!
  ) {
    resetPassword(
      password: $password
      passwordConfirm: $passwordConfirm
      token: $token
      userId: $userId
    ) {
      accessToken
      refreshToken
    }
  }
`;

export const LOGIN_CUSTOMER = gql`
  mutation Login($email: String!, $password: String!, $language: String!) {
    loginUser(email: $email, password: $password, language: $language) {
      accessToken
      refreshToken
    }
  }
`;

export const REQUEST_RESET_PASSWORD_MUTATION = gql`
  mutation RequestPasswordReset($email: String!, $language: String!) {
    requestPasswordReset(email: $email, language: $language) {
      error
    }
  }
`;

export const EXTERNAL_AUTH_MUTATION = gql`
  mutation ExternalAuth($input: ExternalAuthInput!) {
    externalAuth(input: $input) {
      accessToken
      refreshToken
      newUser
    }
  }
`;

export const REFRESH_ACCESS_TOKEN_QUERY = gql`
  query RefreshAccessToken {
    refreshAccessToken {
      accessToken
      refreshToken
    }
  }
`;