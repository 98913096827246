import { gql } from '@apollo/client';
import { LANGUAGES } from '@bus-tickets-app/utils-constants';

import { DISCOUNT_QUERY_SELECTION } from './discount';

const TICKET_TRIP_QUERY_SELECTION = `
  noTripsFound
  unavailableDay
  unavailableNumberOfSeats
  trips {
    id
    availableDays
    points {
      hour
      location
    }
    prices {
      cost
      start
      end
    }
    busConfigurationId
  }
  disabledTripDays
`;

export const GET_SEARCH_TICKET_QUERY = gql`
  query GetSearchTickets($input: SearchTicketInput) {
    getSearchTickets(input: $input) {
      ${TICKET_TRIP_QUERY_SELECTION}
      returnTrip {
        ${TICKET_TRIP_QUERY_SELECTION}
      }
      discount
    }
  }
`;

const TICKET_QUERY_SELECTION = `
  {
    date
    bus {
      seatsConfiguration {
        leftColumns
        rightColumns
        rows
        disabledSeats
        lastRowExtraSeats
        seatsCountDirection
      }
      numberOfSeats
    }
    totalPrice
    discountsApplied ${DISCOUNT_QUERY_SELECTION}
    seatsUnavailable
    startTripTime
    endTripTime
  }
`;

export const GET_BOOK_TICKET_INFORMATION_QUERY = gql`
  query GetBookTicketInformation($input: BookTicketInformationInput) {
    getBookTicketInformation(input: $input) {
      startLocation {
        id
        city
        ${LANGUAGES.AR} {
          address
        }
        ${LANGUAGES.EN} {
          address
        }
      }
      endLocation {
        id
        city
        ${LANGUAGES.AR} {
          address
        }
        ${LANGUAGES.EN} {
          address
        }
      }
      directTicket ${TICKET_QUERY_SELECTION}
      returnTicket ${TICKET_QUERY_SELECTION}
    }
  }
`;

export const GET_MY_TICKETS_PDF_URL = gql`
  query GetMyTicketsPdfUrl($directTicketIds: [String]!, $returnTicketIds: [String]!, $language: String!) {
    getMyTicketsPdfUrl(
      directTicketIds: $directTicketIds,
      returnTicketIds: $returnTicketIds,
      language: $language
    ) {
      fileName
      uri
    }
  }
`;

export const TICKET_DATA_QUERY = `{
  id
  tripId
  seatNumber
  startLocationId
  endLocationId
  date
  bookingName
  clientDetails {
    name
    phone
  }
  status
  price
  priceAfterDiscount
}`;

export const GET_MY_BOOKED_TICKETS = gql`
  query GetMyBookedTickets($ticketIds: [String]!) {
    getMyBookedTickets(ticketIds: $ticketIds) ${TICKET_DATA_QUERY}
  }
`;

export const CONFIRM_OPERATOR_BOOKING_MUTATION = gql`
  mutation ConfirmOperatorBooking($input: ConfirmOperatorBookingInput!) {
    confirmOperatorBooking(input: $input) {
      directTickets ${TICKET_DATA_QUERY}
      returnTickets ${TICKET_DATA_QUERY}
      paymentLink
      transactionOrderId
      receipt {
        date
        number
      }
    }
  }
`;

export const CONFIRM_CLIENT_BOOKING_MUTATION = gql`
  mutation ConfirmClientBooking($input: ConfirmCustomerBookingInput!) {
    confirmClientBooking(input: $input) {
      paymentLink
      transactionOrderId
    }
  }
`;

export const CANCEL_OPERATOR_BOOKING_MUTATION = gql`
  mutation CancelOperatorBooking($input: CancelOperatorBookingInput!) {
    cancelOperatorBooking(input: $input) {
      error
    }
  }
`;
export const VALIDATE_TICKET = gql`
  mutation ValidateTicket($ticketId: String!) {
    validateTicket(ticketId: $ticketId)
  }
`;

export const INVALIDATE_BOOKING_MUTATION = gql`
  mutation InvalidateBookingMutation($ticketIds: [String]!) {
    invalidateBooking(ticketIds: $ticketIds) {
      error
    }
  }
`;
