import { LANGUAGES } from '../translations';
import { Bus } from './bus';

export enum DAY {
  EVERYDAY = 'everyday',
  MONDAY = 'monday',
  TUESDAY = 'tuesday',
  WEDNESDAY = 'wednesday',
  THURSDAY = 'thursday',
  FRIDAY = 'friday',
  SATURDAY = 'saturday',
  SUNDAY = 'sunday'
}

export interface TripPoint {
  location: string;
  hour: string;
}

export interface Price {
  start: string;
  end: string;
  cost: number;
}

export interface Trip {
  id: string;
  points: TripPoint[];
  busConfigurationId: string;
  availableDays: DAY[];
  prices: Price[]
}

export type BusForTrip = Pick<Bus, 'id' | 'configurationName' | 'facilities'>

export type LocationForTripTranslatedData = {
  address: string
}

export type LocationForTrip = {
  id: string
  city: string
  [LANGUAGES.AR]: LocationForTripTranslatedData
  [LANGUAGES.EN]: LocationForTripTranslatedData
}
