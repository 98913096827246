import { GET_PAYMENT_STATUS, useGetCitiesDictionary } from '@bus-tickets-app/utils-apollo';
import { APOLLO_ERRORS, PaymentStatusResponse, TRANSACTION_STATUS, Ticket, YALLA_BUS_EMAIL, YALLA_BUS_PHONE_NUMBER } from '@bus-tickets-app/utils-constants';
import { useEffect, useState } from 'react';
import { useLazyQuery } from '@apollo/client';

export default function usePaymentResult(t: any, orderId: string | undefined) {
  const [apolloError, setApolloError] = useState('');
  const [pending, setPending] = useState(false);
  const [tickets, setTickets] = useState<{
    directTickets: Ticket[];
    returnTickets: Ticket[];
  } | null>(null);
  const { citiesDictionary } = useGetCitiesDictionary();

  const [paymentStatus, setPaymentStatus] = useState<
    undefined | TRANSACTION_STATUS
  >(undefined);
  const [getPaymentStatusQuery] = useLazyQuery<
    { getPaymentStatus: PaymentStatusResponse },
    {
      input: {
        orderId: string;
      };
    }
  >(GET_PAYMENT_STATUS);


  useEffect(() => {
    setApolloError('');
    setPending(true);
    if (!orderId) {
      setApolloError(
        t(`apolloErrors.${APOLLO_ERRORS.INVALID_PAYMENT_RESULT_PARAMS}`, {
          email: YALLA_BUS_EMAIL,
          phone: YALLA_BUS_PHONE_NUMBER,
        })!,
      );
      return;
    }
    getPaymentStatusQuery({
      variables: {
        input: {
          orderId,
        },
      },
    })
      .then((result) => {
        setPending(false);
        if (!result.data?.getPaymentStatus) {
          setApolloError(
            t(`apolloErrors.${APOLLO_ERRORS.CANNOT_GET_PAYMENT_STATUS}`)!,
          );
          return;
        }
        const { status, tickets } = result.data.getPaymentStatus;
        setTickets(tickets || null);
        setPaymentStatus(status);
      })
      .catch((error) => {
        setPending(false);
        setApolloError(error.message as APOLLO_ERRORS);
      });
  }, [orderId]);

  return {
    pending,
    apolloError,
    paymentStatus,
    tickets,
    citiesDictionary,
  }
}
