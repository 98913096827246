import { colorsPalette } from '@bus-tickets-app/utils-constants';
import { Box } from '@mui/material';
import styled, { css } from 'styled-components';

const cssFixedScreen = css`
  z-index: 2;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
`;

export const StyledContainer = styled(Box)`
  ${cssFixedScreen}
  background-color: ${colorsPalette.orange};
`;
