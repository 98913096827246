import { useMutation } from '@apollo/client';
import { LANGUAGES, SuccessfulResponse } from '@bus-tickets-app/utils-constants';
import { UPDATE_PREFERED_LANGUAGE_MUTATION } from '../resolvers/customer';

export default function useUpdatePreferedLanguage() {
  const [updatePreferedLanguageMutation] = useMutation<
    SuccessfulResponse,
    { language: LANGUAGES }
  >(UPDATE_PREFERED_LANGUAGE_MUTATION, {
    fetchPolicy: 'network-only',
  });

  const onUpdatePreferedLanguage = (language: LANGUAGES) => {
    updatePreferedLanguageMutation({
      variables: {
        language
      }
    })
  }
  return onUpdatePreferedLanguage;
}