import { getLocationCityName, getTripCircuit } from '@bus-tickets-app/utils-apollo';
import { City, LANGUAGES, LocationForTrip, TripPoint } from '@bus-tickets-app/utils-constants';
import dayjs from 'dayjs';

export default function useTripRoad(
  locations: LocationForTrip[],
  citiesDictionary: Record<string, City>,
  language: LANGUAGES,
  tripPoints: TripPoint[],
  unavailableDays: string[]
): ({
  startLocation: LocationForTrip,
  startLocationCityName: string,
  endLocation: LocationForTrip,
  endLocationCityName: string,
  circuit: string[],
  disabledDaysMessage: string
} | null) {

  const startLocation = locations.find(
    (location) => location.id === tripPoints[0].location,
  )!;
  if (!startLocation) {
    return null;
  }

  const startLocationCityName = getLocationCityName(
    startLocation,
    citiesDictionary,
    language,
  );

  const endLocation = locations.find(
    (location) => location.id === tripPoints[tripPoints.length - 1].location,
  )!;
  if (!endLocation) {
    return null;
  }

  const endLocationCityName = getLocationCityName(
    endLocation,
    citiesDictionary,
    language,
  );

  const circuit = getTripCircuit(
    tripPoints,
    locations,
    citiesDictionary,
    language,
  );

  const disabledDaysMessage = unavailableDays.length
    ? unavailableDays.map((day) => dayjs(day).format('DD MMM')).join(', ')
    : '';

  return {
    startLocation,
    startLocationCityName,
    endLocation,
    endLocationCityName,
    circuit,
    disabledDaysMessage
  }
}