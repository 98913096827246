import { RefObject, useCallback, useEffect, useRef, useState } from 'react';

import {
  pageSections,
  SECTIONS,
} from '../../../../contexts/selectedMenuItem/constants';
import useSelectedMenuItemContext from '../../../../contexts/selectedMenuItem/useSelectedMenuItem';
import { MENU_HEIGHT } from '../../../customer/menu';
import useObservable from './useObservable';

const options = {
  threshold: 0,
  rootMargin: `-${MENU_HEIGHT}px 0px 0px 0px`,
} as const;

export default function useSectionsObservable(
  containerRef: RefObject<HTMLDivElement>,
) {
  const { clickedMenuItem, setSelectedMenuItem, setClickedMenuItem } =
    useSelectedMenuItemContext();
  const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout | null>(null);

  const bookingView = useObservable(options);
  const locationsView = useObservable(options);
  const pricesView = useObservable(options);
  const tripsView = useObservable(options);
  const aboutView = useObservable(options);
  const contactView = useObservable(options);

  const sectionsView = {
    [SECTIONS.BOOKING]: bookingView,
    [SECTIONS.LOCATIONS]: locationsView,
    [SECTIONS.PRICES]: pricesView,
    [SECTIONS.TRIPS]: tripsView,
    [SECTIONS.ABOUT]: aboutView,
    [SECTIONS.CONTACT]: contactView,
  };

  const sectionRefs = useRef(
    Object.fromEntries(
      pageSections.map(({ key }) => [key, null as HTMLDivElement | null]),
    ),
  );

  const setSectionRef = useCallback(
    (key: SECTIONS) => (node: HTMLDivElement | null) => {
      sectionRefs.current[key] = node;
      sectionsView[key].ref(node);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      // eslint-disable-next-line react-hooks/exhaustive-deps
      sectionsView[SECTIONS.BOOKING].ref,
      // eslint-disable-next-line react-hooks/exhaustive-deps
      sectionsView[SECTIONS.LOCATIONS].ref,
      // eslint-disable-next-line react-hooks/exhaustive-deps
      sectionsView[SECTIONS.PRICES].ref,
      // eslint-disable-next-line react-hooks/exhaustive-deps
      sectionsView[SECTIONS.TRIPS].ref,
      // eslint-disable-next-line react-hooks/exhaustive-deps
      sectionsView[SECTIONS.ABOUT].ref,
      // eslint-disable-next-line react-hooks/exhaustive-deps
      sectionsView[SECTIONS.CONTACT].ref,
    ],
  );

  useEffect(() => {
    if (clickedMenuItem) {
      return;
    }

    pageSections.find(({ key }) => {
      if (sectionsView[key].inView) {
        setSelectedMenuItem(key);
        return true;
      }
      return false;
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    // eslint-disable-next-line react-hooks/exhaustive-deps
    sectionsView[SECTIONS.BOOKING].inView,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    sectionsView[SECTIONS.LOCATIONS].inView,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    sectionsView[SECTIONS.PRICES].inView,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    sectionsView[SECTIONS.TRIPS].inView,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    sectionsView[SECTIONS.ABOUT].inView,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    sectionsView[SECTIONS.CONTACT].inView,
    setSelectedMenuItem,
  ]);

  useEffect(() => {
    if (clickedMenuItem) {
      const selectedElement =
        sectionRefs.current[clickedMenuItem as SECTIONS] ||
        sectionRefs.current[SECTIONS.BOOKING];
      if (containerRef.current && selectedElement) {
        containerRef.current.scroll({
          top: selectedElement.offsetTop - MENU_HEIGHT,
          behavior: 'smooth',
        });
      }
      setSelectedMenuItem(clickedMenuItem);
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
      const timeout = setTimeout(() => {
        setClickedMenuItem(null);
      }, 1000);
      setTimeoutId(timeout);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clickedMenuItem]);

  return {
    setSectionRef,
  };
}
