import { City, LANGUAGES, LocationForTrip, TripPoint } from '@bus-tickets-app/utils-constants';

export const getCitiesDictionary = (cities: City[]) => {
  return cities.reduce((result: Record<string, City>, city) => {
    result[city.id] = {
      ...city,
    };
    return result;
  }, {});
};

export const getLocationCityName = (
  location: LocationForTrip,
  citiesDictionary: Record<string, City>,
  language: LANGUAGES,
) => {
  const city = citiesDictionary[location.city];
  if (!city) {
    return '';
  }

  return city[language].name;
};

export const getTripCircuit = (
  tripPoints: TripPoint[],
  locations: LocationForTrip[],
  citiesDictionary: Record<string, City>,
  language: LANGUAGES
) => {
  const circuit = tripPoints.reduce<string[]>((circuit, point) => {
    const location = locations.find(
      (location) => location.id === point.location,
    )!;
    if (!location) {
      return circuit;
    }
    const locationCityName = getLocationCityName(
      location,
      citiesDictionary,
      language,
    );
    if (!locationCityName) {
      return circuit;
    }

    circuit.push(locationCityName);
    return circuit;
  }, []);

  return circuit;
}