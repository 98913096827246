import { useQuery } from '@apollo/client';
import { GET_CITIES_QUERY } from '../resolvers';
import { City } from '@bus-tickets-app/utils-constants';

export default function useGetCities() {
  const {
    data: getCityConfigurationsQueryData,
    loading,
    refetch,
  } = useQuery<{ getCityConfigurations: City[] }>(GET_CITIES_QUERY, {
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
  });
  const cities = getCityConfigurationsQueryData?.getCityConfigurations || [];

  return {
    cities,
    loading,
    refetch,
  };
}
