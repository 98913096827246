import { gql } from '@apollo/client';

import { TICKET_DATA_QUERY } from './ticket';

export const GET_SEARCH_BOOKINGS_QUERY = gql`
  query GetSearchBookings($input: SearchBookingsInput) {
    getSearchBookings(input: $input) {
      _id {
        bookingName
        customerName
        customerPhone
        date
        price
        priceAfterDiscount
        startLocationName
        startTripTime
        endLocationName
        endTripTime
        status
      }
      tickets {
        id
        seatNumber
      }
    }
  }
`;

export const GET_MY_BOOKINGS_QUERY = gql`
  query GetMyBookings {
    getMyBookings {
      _id {
        bookingName
        date
        price
        priceAfterDiscount
        startLocationName
        startTripTime
        endLocationName
        endTripTime
        status
      }
      tickets {
        id
        seatNumber
      }
    }
  }
`;

export const CANCEL_BOOKINGS_MUTATION = gql`
  mutation CancelBookings($ticketIds: [String]!, $language: String!) {
    cancelBookings(ticketIds: $ticketIds, language: $language) {
      error
    }
  }
`;

export const GET_TICKETS_BOOKING_QUERY = gql`
  query GetTicketsBooking($ticketIds: [String]!, $language: String!) {
    getTicketsBooking(ticketIds: $ticketIds, language: $language) {
      directTickets ${TICKET_DATA_QUERY}
      returnTickets ${TICKET_DATA_QUERY}
      receipt {
        number
        date
      }
    }
  }
`;


export const CONFIRM_PENDING_BOOKING_MUTATION = gql`
  mutation ConfirmPendingBooking($ticketIds: [String]!, $language: String!) {
    confirmPendingBooking(ticketIds: $ticketIds, language: $language) {
      directTickets ${TICKET_DATA_QUERY}
      returnTickets ${TICKET_DATA_QUERY}
      receipt {
        number
        date
      }
    }
  }
`;

export const PAY_PENDING_BOOKING_MUTATION = gql`
  mutation PayPendingBooking(
    $ticketIds: [String]!
    $language: String!
    $sendPaymentNotifications: Boolean!
  ) {
    payPendingBooking(
      ticketIds: $ticketIds
      language: $language
      sendPaymentNotifications: $sendPaymentNotifications
    ) {
      paymentLink
      transactionOrderId
    }
  }
`;
