import { LANGUAGES } from '../translations';

export const DEFAULT_LANGUAGE = LANGUAGES.AR

const arabicNumbers = '\u0660\u0661\u0662\u0663\u0664\u0665\u0666\u0667\u0668\u0669'

export const getNumberTranslation = (language: LANGUAGES, number: number) => {
  if (language === LANGUAGES.AR) {
    return new String(number).replace(/[0123456789]/g, (d: string)=>{
      return arabicNumbers[d as unknown as number]
    });
  }
  return number.toString()
}