import { useLazyQuery } from '@apollo/client';
import { GET_MY_BOOKED_TICKETS } from '@bus-tickets-app/utils-apollo';
import { APOLLO_ERRORS, GroupedTickets, Ticket } from '@bus-tickets-app/utils-constants';
import { useEffect, useState } from 'react';

export default function useShowPrintTicket(
  t: any,
  groupedTickets: GroupedTickets | undefined,
  setApolloError: (apolloError: APOLLO_ERRORS) => void
) {
  const [showPrintWindow, setShowPrintWindow] = useState(false);
  const [tickets, setTickets] = useState<Ticket[] | null>(null);

  useEffect(() => {
    setShowPrintWindow(false);
    setTickets(null);
  }, [groupedTickets]);

  const [getMyBookedTicketsLazyQuery] = useLazyQuery<
    { getMyBookedTickets: Ticket[] },
    { ticketIds: string[] }
  >(GET_MY_BOOKED_TICKETS, {
    onCompleted: ({ getMyBookedTickets }) => {
      setTickets(getMyBookedTickets || null);
    },
    onError: (error) => {
      setApolloError(error.message as APOLLO_ERRORS);
    },
    fetchPolicy: 'network-only',
  });

  const requestTickets = () => {
    if (groupedTickets) {
      getMyBookedTicketsLazyQuery({
        variables: {
          ticketIds: groupedTickets.tickets.map(({ id }) => id),
        },
      });
    }
    setShowPrintWindow(true);
  };

  return {
    showPrintWindow,
    tickets,
    requestTickets,
  }
}