/* eslint-disable no-unreachable */
import './App.css';
import './i18n.config';

import { LANGUAGES } from '@bus-tickets-app/utils-constants';
import { ThemeProvider } from '@mui/material';
import * as Sentry from '@sentry/react';
import dayjs, { PluginFunc } from 'dayjs';
import * as localizedFormat from 'dayjs/plugin/localizedFormat';
import * as preParsePostFormat from 'dayjs/plugin/preParsePostFormat';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { BrowserRouter } from 'react-router-dom';

import ErrorBoundary from './components/errorBoundary';
import Navigation from './components/navigation';
import useImagesPreloaded from './hooks/useImagesPreloaded';
import theme from './utils/theme';

require('dayjs/locale/ar');
require('dayjs/locale/en');

dayjs.extend(localizedFormat as unknown as PluginFunc<unknown>);
dayjs.extend(preParsePostFormat as unknown as PluginFunc<unknown>);

function App() {
  const { i18n } = useTranslation();
  const language = i18n.language as LANGUAGES;

  useEffect(() => {
    const direction = language === LANGUAGES.AR ? 'rtl' : 'ltr';

    if (language === LANGUAGES.AR) {
      document.dir = 'rtl';
    } else {
      document.dir = 'ltr';
    }
    document.getElementsByTagName('body')[0].style.direction = direction;
  }, [language]);

  useImagesPreloaded();

  return (
    <ThemeProvider theme={theme}>
      <Sentry.ErrorBoundary fallback={ErrorBoundary}>
        <BrowserRouter>
          <Navigation />
        </BrowserRouter>
      </Sentry.ErrorBoundary>
    </ThemeProvider>
  );
}

export default App;
