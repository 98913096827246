import { BUS_FACILITIES } from '@bus-tickets-app/utils-constants';
import AcUnitIcon from '@mui/icons-material/AcUnit';
import CoffeeIcon from '@mui/icons-material/Coffee';
import ConnectedTvIcon from '@mui/icons-material/ConnectedTv';
import MusicNoteIcon from '@mui/icons-material/MusicNote';
import WcIcon from '@mui/icons-material/Wc';
import WifiIcon from '@mui/icons-material/Wifi';

export const BusFacilities = [
  {
    id: BUS_FACILITIES.WIFI,
    label: 'WIFI',
    Icon: WifiIcon,
    color: 'success',
  },
  {
    id: BUS_FACILITIES.AC,
    label: 'AC',
    Icon: AcUnitIcon,
    color: 'primary',
  },
  {
    id: BUS_FACILITIES.DRINK,
    label: 'DRINK',
    Icon: CoffeeIcon,
    color: 'warning',
  },
  {
    id: BUS_FACILITIES.WC,
    label: 'WC',
    Icon: WcIcon,
    color: 'info',
  },
  {
    id: BUS_FACILITIES.TV,
    label: 'TV',
    Icon: ConnectedTvIcon,
    color: 'secondary',
  },
  {
    id: BUS_FACILITIES.MUSIC,
    label: 'MUSIC',
    Icon: MusicNoteIcon,
    color: 'error',
  },
] as const;
