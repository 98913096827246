/* eslint-disable @typescript-eslint/promise-function-async */
import { useLazyQuery } from '@apollo/client';
import { GET_CURRENT_USER_QUERY } from '@bus-tickets-app/utils-apollo';
import { ACCESS_TOKEN_KEY } from '@bus-tickets-app/utils-constants';
import { Box } from '@mui/material';
import { Fragment, Suspense, useEffect } from 'react';
import { Navigate, Outlet, Route, Routes, useLocation } from 'react-router-dom';

import { removeAuthToken } from '../../contexts/auth';
import lazy from '../../utils/componentLoader';
import Loading from '../common/loading';
import LangingScreen from '../customerScreens/landingScreen';
import SearchTicketScreen from '../customerScreens/searchTicketScreen';
import RewardPoints from '../rewardPoints';
import CustomerProvider from './providers/CustomerProvider';
import SelectedMenuItemProvider from './providers/SelectedMenuItemProvider';
import { routeRequiresPermissions, ROUTES } from './routes';
import SelectLanguage from './selectLanguage';
import useNavigateToLogin from './useNavigateToLogin';

const ResetPassword = lazy(
  /* webpackChunkName: "resetPassword" */ () => import('../auth/resetPassword'),
);
const MyBookings = lazy(
  /* webpackChunkName: "myBookings" */ () =>
    import('../customerScreens/myBookings'),
);
const PrintTicketsScreen = lazy(
  /* webpackChunkName: "printTicket" */ () =>
    import('../customerScreens/printTicketsScreen'),
);
const AdminLogin = lazy(
  /* webpackChunkName: "adminLogin" */ () => import('../logistics/adminLogin'),
);
const ConfigureBuses = lazy(
  /* webpackChunkName: "configureBuses" */ () =>
    import('../logistics/adminPanel/configureBuses'),
);
const ConfigureCities = lazy(
  /* webpackChunkName: "configureCities" */ () =>
    import('../logistics/adminPanel/configureCities'),
);
const ConfigureLocations = lazy(
  /* webpackChunkName: "configureLocations" */ () =>
    import('../logistics/adminPanel/configureLocations'),
);
const ConfigureTrips = lazy(
  /* webpackChunkName: "configureTrips" */ () =>
    import('../logistics/adminPanel/configureTrips'),
);
const DisabledTripDays = lazy(
  /* webpackChunkName: "configureTrips" */ () =>
    import('../logistics/adminPanel/disabledTripDays'),
);

const ConfigureDiscounts = lazy(
  /* webpackChunkName: "discounts" */ () =>
    import('../logistics/adminPanel/discounts'),
);

const Contacts = lazy(
  /* webpackChunkName: "contacts" */ () =>
    import('../logistics/adminPanel/contacts'),
);

const AdminDashboard = lazy(
  /* webpackChunkName: "manageUsers" */ () =>
    import('../logistics/adminPanel/manageUsers'),
);
const Reports = lazy(
  /* webpackChunkName: "reports" */ () =>
    import('../logistics/adminPanel/reports'),
);
const ValidateTicketQR = lazy(
  /* webpackChunkName: "validateTicketQR" */ () =>
    import('../logistics/adminPanel/validateTicketQR'),
);
const ManagementMenu = lazy(
  /* webpackChunkName: "logistictsMenu" */ () => import('../logistics/menu'),
);
const CustomerMenu = lazy(
  /* webpackChunkName: "customerMenu" */ () => import('../customer/menu'),
);
const BookTicketScreen = lazy(
  /* webpackChunkName: "bookTicketScreen" */ () =>
    import('../customerScreens/bookTicketScreen'),
);
const UploadPaymentProofScreen = lazy(
  /* webpackChunkName: "uploadPaymentProof" */ () =>
    import('../customerScreens/uploadPaymentProof'),
);
const Booking = lazy(
  /* webpackChunkName: "booking" */ () =>
    import('../logistics/operatorPanel/booking'),
);
const Bookings = lazy(
  /* webpackChunkName: "bookings" */ () =>
    import('../logistics/operatorPanel/bookings'),
);
const Schedule = lazy(
  /* webpackChunkName: "schedule" */ () =>
    import('../logistics/operatorPanel/schedule'),
);
const Tickets = lazy(
  /* webpackChunkName: "tickets" */ () =>
    import('../logistics/operatorPanel/tickets'),
);
const DriverTrip = lazy(
  /* webpackChunkName: "driverTrip" */ () =>
    import('../logistics/operatorPanel/driverTrip'),
);
const PaymentResult = lazy(
  /* webpackChunkName: "paymentResult" */ () => import('../paymentResult'),
);

const Policy = lazy(/* webpackChunkName: "policy" */ () => import('../policy'));
const DataDeletion = lazy(
  /* webpackChunkName: "dataDeletion" */ () => import('../dataDeletion'),
);

export default function Navigation() {
  const localStorageAccessToken = localStorage.getItem(ACCESS_TOKEN_KEY);
  const navigateToLogin = useNavigateToLogin();

  const [getCurrentUser] = useLazyQuery(GET_CURRENT_USER_QUERY, {
    variables: {},
  });
  const location = useLocation();

  useEffect(() => {
    if (location.pathname === ROUTES.PASSWORD_REST.path) {
      localStorage.removeItem(ACCESS_TOKEN_KEY);
      return;
    }
    if (localStorageAccessToken) {
      getCurrentUser()
        .then((result) => {
          if (result?.error) {
            removeAuthToken();
            navigateToLogin();
          }
        })
        .catch(() => {
          if (routeRequiresPermissions(location.pathname)) {
            removeAuthToken();
            navigateToLogin();
          }
        });
    } else {
      if (routeRequiresPermissions(location.pathname)) {
        localStorage.removeItem(ACCESS_TOKEN_KEY);
        navigateToLogin();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Suspense fallback={<Loading primaryBackground />}>
      <Routes>
        <Route element={<CustomerProvider />}>
          <Route element={<SelectedMenuItemProvider />}>
            <Route element={<CustomerMenu />}>
              <Route
                path={ROUTES.PUBLIC_ROUTE_POLICY.path}
                element={<Policy />}
              />
              <Route
                path={ROUTES.PUBLIC_ROUTE_DATA_DELETION.path}
                element={<DataDeletion />}
              />
              <Route
                path={ROUTES.PRINT_TICKETS.path}
                element={<PrintTicketsScreen />}
              />
              <Route element={<RewardPoints />}>
                <Route
                  path={ROUTES.DEFAULT_ROUTE.path}
                  element={<LangingScreen />}
                />
                <Route
                  path={ROUTES.SEARCH_BOOKING.path}
                  element={<SearchTicketScreen />}
                />
                <Route
                  path={ROUTES.BOOK_TICKET.path}
                  element={<BookTicketScreen />}
                />
                <Route
                  path={ROUTES.MY_BOOKINGS.path}
                  element={<MyBookings />}
                />
                <Route
                  path={ROUTES.PUBLIC_ROUTE_SCHEDULE.path}
                  element={<Schedule publicAccess />}
                />
              </Route>
              <Route
                path={ROUTES.PAYMENT_RESULT_ROUTE.path}
                element={<PaymentResult />}
              />
              <Route
                path={ROUTES.PUBLIC_ROUTE_UPLOAD_PAYMENT_PROOF.path}
                element={<UploadPaymentProofScreen />}
              />
            </Route>
            <Route
              path={ROUTES.PASSWORD_REST.path}
              element={<ResetPassword />}
            />
          </Route>
        </Route>
        <Route
          element={
            <Fragment>
              <Box zIndex={2} position="fixed" top="16px" right="16px">
                <SelectLanguage />
              </Box>
              <Outlet />
            </Fragment>
          }
        >
          <Route
            path={ROUTES.ADMIN_PANEL_LOGIN_ROUTE.path}
            element={<AdminLogin />}
          />
          <Route
            path={ROUTES.VALIDATE_TICKET_QR.path}
            element={<ValidateTicketQR />}
          />
          <Route element={<ManagementMenu />}>
            <Route
              path={ROUTES.ADMIN_PANEL_ROUTE.path}
              element={<AdminDashboard />}
            />
            <Route
              path={ROUTES.ADMIN_PANEL_ROUTE_CONFIGURE_BUSES.path}
              element={<ConfigureBuses />}
            />
            <Route
              path={ROUTES.ADMIN_PANEL_ROUTE_CONFIGURE_CITIES.path}
              element={<ConfigureCities />}
            />
            <Route
              path={ROUTES.ADMIN_PANEL_ROUTE_CONFIGURE_LOCATIONS.path}
              element={<ConfigureLocations />}
            />
            <Route
              path={ROUTES.ADMIN_PANEL_ROUTE_CONFIGURE_TRIPS.path}
              element={<ConfigureTrips />}
            />
            <Route
              path={ROUTES.ADMIN_PANEL_ROUTE_DISABLED_TRIP_DAYS.path}
              element={<DisabledTripDays />}
            />
            <Route
              path={ROUTES.ADMIN_PANEL_ROUTE_CONFIGURE_REPORTS.path}
              element={<Reports />}
            />
            <Route
              path={ROUTES.ADMIN_PANEL_ROUTE_DISCOUNTS.path}
              element={<ConfigureDiscounts />}
            />
            <Route
              path={ROUTES.ADMIN_PANEL_ROUTE_CONTACTS.path}
              element={<Contacts />}
            />
            <Route
              path={ROUTES.OPERATOR_PANEL_ROUTE_TICKETS.path}
              element={<Tickets />}
            />
            <Route
              path={ROUTES.OPERATOR_PANEL_ROUTE_BOOKING.path}
              element={<Booking />}
            />
            <Route
              path={ROUTES.OPERATOR_PANEL_ROUTE_BOOKINGS.path}
              element={<Bookings />}
            />
            <Route
              path={ROUTES.OPERATOR_PANEL_ROUTE_SCHEDULE.path}
              element={<Schedule />}
            />
            <Route
              path={ROUTES.OPERATOR_PANEL_ROUTE_DRIVER_TRIP.path}
              element={<DriverTrip />}
            />
            <Route
              path="*"
              element={<Navigate replace to={ROUTES.DEFAULT_ROUTE.path} />}
            />
          </Route>
        </Route>
      </Routes>
    </Suspense>
  );
}
