import {
  Customer,
  EMAIL_MATCH,
  NAME_MATCH,
} from '@bus-tickets-app/utils-constants';

export const SIGN_UP_FIELS = [
  {
    key: 'firstName',
    required: true,
    validation: NAME_MATCH,
    type: undefined,
  },
  {
    key: 'lastName',
    required: true,
    validation: NAME_MATCH,
    type: undefined,
  },
  {
    key: 'email',
    required: true,
    validation: EMAIL_MATCH,
    type: undefined,
  },
  {
    key: 'phone',
    required: true,
    validation: null,
    type: undefined,
  },
  {
    key: 'password',
    required: true,
    validation: null,
    type: 'password',
  },
  {
    key: 'passwordConfirm',
    required: true,
    validation: null,
    type: 'password',
  },
] as const;

export type SignUpUser = Omit<Customer, 'id' | 'roles'> & {
  password: string;
  passwordConfirm: string;
};

export interface SignUpProps {
  className?: string;
  withIcon?: boolean;
}
