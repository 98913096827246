import { LANGUAGES } from "../translations"

export enum DISCOUNT_TYPE {
  FIRST_TRIP = 'FIRST_TRIP',
  PERIOD = 'PERIOD',
  FIDELITY_POINTS = 'FIDELITY_POINTS',
  ROUND_TRIP = 'ROUND_TRIP',
  PROMO_CODES = 'PROMO_CODES'
}

export type DiscountTemplate<T extends DISCOUNT_TYPE> = {
  id: string;
  name: string;
  type: T
  percentage: number
  deleted: number
}

export type PeriodDiscountDescription = {
  name: string
  description: string
}

export type PeriodDiscount = DiscountTemplate<DISCOUNT_TYPE.PERIOD> & {
  firstDate: string
  lastDate: string
  [LANGUAGES.AR]: PeriodDiscountDescription,
  [LANGUAGES.EN]: PeriodDiscountDescription
}

export type PromoCodesDiscount = Omit<PeriodDiscount, 'type'> & {
  type: DISCOUNT_TYPE.PROMO_CODES
  valability: number
  codes: string[]
}

export type Discount = 
  | DiscountTemplate<DISCOUNT_TYPE.FIRST_TRIP>
  | DiscountTemplate<DISCOUNT_TYPE.FIDELITY_POINTS>
  | DiscountTemplate<DISCOUNT_TYPE.ROUND_TRIP>
  | PeriodDiscount
  | PromoCodesDiscount

export const DiscountTypes = [
  DISCOUNT_TYPE.PERIOD,
  DISCOUNT_TYPE.ROUND_TRIP,
  DISCOUNT_TYPE.FIRST_TRIP,
  DISCOUNT_TYPE.FIDELITY_POINTS,
  DISCOUNT_TYPE.PROMO_CODES
]

export const isDiscountAppliedOnPeriod = (discountType: DISCOUNT_TYPE) =>
  discountType === DISCOUNT_TYPE.PERIOD || discountType === DISCOUNT_TYPE.PROMO_CODES

export const PROMO_CODE_DEFAULT_PREFIX = 'YALLABUS'