import { Box } from '@mui/material';

import BusLateral from '../../assets/bus-lateral.png';
import { AnimatedImage } from './styles';

export default function BusAnimation() {
  return (
    <Box width="fill-available">
      <AnimatedImage src={BusLateral} width="200px" height="52px" />
    </Box>
  );
}
