export enum BUS_FACILITIES {
  WIFI = 'WIFI',
  AC = 'AC',
  DRINK = 'DRINK',
  TV = 'TV',
  WC = 'WC',
  MUSIC = 'MUSIC'
}

export enum SEATS_COUNT_DIRECTION {
  ROW = 'row',
  COLUMN = 'column',
  NONE = 'none'
}

export interface LevelConfiguration {
  leftColumns: number;
  rightColumns: number;
  rows: number;
  disabledSeats: number[];
  lastRowExtraSeats?: number;
  seatsCountDirection: SEATS_COUNT_DIRECTION;
}

export interface Bus {
  id: string;
  configurationName: string;
  numberOfSeats: number;
  seatsConfiguration: LevelConfiguration[];
  facilities: BUS_FACILITIES[];
}