import { TICKET_STATUS } from '@bus-tickets-app/utils-constants';

export { default as useMyBookings } from './useMyBookings';
export { default as useShowPrintTicket } from './useShowPrintTicket';

export * from './types';
export * from './constants';

export const getVariantForBookingStatus = (status: TICKET_STATUS) => {
  if (status === TICKET_STATUS.BOOKED) {
    return 'success';
  }
  if (
    status ===  TICKET_STATUS.FAILED_ONLINE_PAYMENT ||
      status === TICKET_STATUS.PAYMENT_NOT_PROVIDED
  ) {
    return 'error';
  }
  if (status === TICKET_STATUS.CANCELLED) {
    return 'default';
  }
  return 'info';
}
