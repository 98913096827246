import {
  colorsPalette,
  DATE_FORMAT_DETAILS,
  LANGUAGES,
} from '@bus-tickets-app/utils-constants';
import {
  BookingSummaryProps,
  useBookingSummary,
} from '@bus-tickets-app/utils-ui';
import DirectionsBusIcon from '@mui/icons-material/DirectionsBus';
import EventIcon from '@mui/icons-material/Event';
import KeyboardReturnIcon from '@mui/icons-material/KeyboardReturn';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import SavingsIcon from '@mui/icons-material/Savings';
import SellIcon from '@mui/icons-material/Sell';
import { Box, Typography } from '@mui/material';
import dayjs from 'dayjs';
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';

export default function BookingSummary(props: BookingSummaryProps) {
  const { trip, returnTrip, nrOfPassangers, proceedToBookingButton } = props;

  const { t, i18n } = useTranslation();
  const language = i18n.language as LANGUAGES;

  const { totalAmount, getTripInfo } = useBookingSummary(language, props);

  const renderTrip = (isReturnTrip: boolean) => {
    const {
      startCityTripName,
      startLocationTripAddress,
      endCityTripName,
      endLocationTripAddress,
      tripDate,
      tripHour,
    } = getTripInfo(isReturnTrip);

    const Icon = isReturnTrip ? KeyboardReturnIcon : DirectionsBusIcon;
    return (
      <Box display="flex" flexDirection="column" gap={0.5}>
        <Box display="flex" gap={2} alignItems="center">
          <Icon fontSize="small" color="primary" />
          <Typography variant="body1" fontWeight="bold">
            {startCityTripName}
            <Typography variant="caption" ml={1}>
              {startLocationTripAddress}
            </Typography>
          </Typography>
        </Box>
        <Box display="flex" gap={2} alignItems="center">
          <LocationOnIcon fontSize="small" color="primary" />
          <Typography variant="body1" fontWeight="bold">
            {endCityTripName}
            <Typography variant="caption" ml={1}>
              {endLocationTripAddress}
            </Typography>
          </Typography>
        </Box>
        <Box display="flex" gap={2} alignItems="center">
          <EventIcon fontSize="small" color="primary" />
          <Typography variant="body2" fontWeight="bold">
            {dayjs(new Date(tripDate)).format(DATE_FORMAT_DETAILS)} - {tripHour}
          </Typography>
        </Box>
      </Box>
    );
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      gap={0.5}
      px={4}
      py={2}
      sx={{
        opacity: 0.8,
        backgroundColor: colorsPalette.orange,
        color: 'white',
      }}
    >
      <Box display="flex" gap={2} alignItems="center">
        <PeopleAltIcon fontSize="small" color="primary" />
        <Typography variant="body1" fontWeight="bold">
          {nrOfPassangers}
        </Typography>
        <SellIcon
          fontSize="small"
          sx={{ marginLeft: { md: '32px', sx: '0px' } }}
          color="primary"
        />
        <Typography
          variant="body1"
          fontWeight="bold"
          sx={{ textDecoration: returnTrip ? 'line-through' : 'none' }}
        >
          {totalAmount} {t('currency')}
        </Typography>
        {returnTrip && (
          <Fragment>
            <SavingsIcon
              fontSize="small"
              sx={{ marginLeft: { md: '32px', sx: '0px' } }}
              color="primary"
            />
            <Typography variant="body1" fontWeight="bold">
              {t('tickets.returnTripDiscount')}
            </Typography>
          </Fragment>
        )}
      </Box>
      <Box
        display="flex"
        flexDirection={{ xs: 'column', md: 'row' }}
        justifyContent="space-between"
        rowGap={{ xs: 1, md: 4 }}
      >
        <span>{trip && renderTrip(false)}</span>
        <span>{returnTrip && renderTrip(true)}</span>
      </Box>
      <span>
        {proceedToBookingButton && (
          <Box mt={1} display="flex" justifyContent="center">
            {proceedToBookingButton}
          </Box>
        )}
      </span>
    </Box>
  );
}
