import { DATA_TESTID_LOADING } from '@bus-tickets-app/utils-ui';
import { Box } from '@mui/material';
import { Fragment } from 'react';

import BusAnimation from '../../busAnimation';
import { StyledContainer } from './styles';
import LoadingProps from './types';

export default function Loading({ primaryBackground }: LoadingProps) {
  const Container = primaryBackground ? StyledContainer : Fragment;
  return (
    <Container>
      <Box
        data-testid={DATA_TESTID_LOADING}
        position="fixed"
        left={0}
        top={0}
        right={0}
        bottom={0}
        display="flex"
        alignItems="center"
        justifyContent="center"
        zIndex={2}
        sx={{ backgroundColor: 'rgba(0, 0, 0, 0.5)', direction: 'ltr' }}
      >
        <BusAnimation />
      </Box>
    </Container>
  );
}
