import styled, { css } from 'styled-components';

import Login from '../../auth/clientLogin/Login';
import SignUp from '../../auth/signUp/SignUp';

const cssStyledListItemButton = css`
  padding: 12px 24px !important;
  width: fill-available;
  justify-content: flex-start !important;
  text-transform: capitalize !important;
  font-size: unset !important;
`;

export const StyledLogin = styled(Login)`
  ${cssStyledListItemButton}

  & > .text {
    flex: 1;
  }
`;

export const StyledSignUp = styled(SignUp)`
  ${cssStyledListItemButton}

  & > .text {
    flex: 1;
  }
`;
