import { useQuery } from '@apollo/client';

import { GET_DISABLED_TRIP_DAYS_FOR_ALL_TRIPS_QUERY } from '../resolvers/disabledTripDays';

export default function useGetDisabledTripDays() {
  const { data } = useQuery<{
    getDisabledTripDaysForAllTrips: string[];
  }>(GET_DISABLED_TRIP_DAYS_FOR_ALL_TRIPS_QUERY, {
    fetchPolicy: 'cache-and-network',
  });
  const days = data?.getDisabledTripDaysForAllTrips || [];

  return {
    days,
  };
}
