import { gql } from '@apollo/client';

export const GET_REPORT_QUERY = gql`
  query GetReport($input: ReportInput!) {
    getReport(input: $input) {
      id
      tripId
      date
      startLocationId
      endLocationId
      userId
      status
      selfPurchased
      price
      createdAt
      soldInLocation
      priceAfterDiscount
      returnedAmount
      isReturnTicket
    }
  }
`;

export const SEND_REPORT_QUERY = gql`
  query SendReport($input: SendReportInput!) {
    sendReport(input: $input) {
      error
    }
  }
`;
