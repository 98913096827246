import {
  getCitiesDictionary,
  useGetCities,
} from '@bus-tickets-app/utils-apollo';
import { LANGUAGES } from '@bus-tickets-app/utils-constants';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import Loading from '../loading/Loading';
import SearchLocationProps from './types';

export default function SearchLocation({
  locations,
  id,
  label,
  value,
  size,
  disabled,
  additionalMenuItems,
  onChange,
}: SearchLocationProps) {
  const { i18n } = useTranslation();
  const language = i18n.language as LANGUAGES;
  const { cities, loading: citiesAreLoading } = useGetCities();
  const citiesDictionary = useMemo(() => getCitiesDictionary(cities), [cities]);

  if (citiesAreLoading) {
    return <Loading />;
  }

  return (
    <FormControl disabled={disabled} size={size}>
      <InputLabel size={size} id={`${id}Label`}>
        {label}
      </InputLabel>
      <Select
        id={id}
        size={size}
        labelId={`${id}Label`}
        name={id}
        label={label}
        value={value}
        onChange={onChange}
        disabled={disabled}
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
        MenuProps={{
          PaperProps: {
            style: {
              width: 250,
            },
          },
        }}
      >
        {additionalMenuItems?.map(({ value, label }) => (
          <MenuItem key={value} value={value}>
            {label}
          </MenuItem>
        ))}
        {locations.map((location) => {
          const city = citiesDictionary[location.city];
          if (!city) {
            return null;
          }
          return (
            <MenuItem key={`${location.id}`} value={location.id}>
              {`${city[language].name} - ${location[language].address}`}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
}
