import { useGetTrips } from '@bus-tickets-app/utils-apollo';
import { getTripSectorCost } from '@bus-tickets-app/utils-constants';
import { DisplayTicketsResultProps } from '@bus-tickets-app/utils-ui';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import { Alert, Box, Button, Typography } from '@mui/material';
import { Fragment } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { ROUTES } from '../../navigation/routes';
import Loading from '../loading';
import { StyledTripRoad } from './styles';

export default function DisplayTicketsResult({
  data,
  locations,
  startLocationId,
  endLocationId,
  date,
  isCustomer,
  selectedTrip,
  setSelectedTrip,
}: DisplayTicketsResultProps) {
  const { t } = useTranslation();
  const { buses } = useGetTrips();

  const proceedToScheduleLink = isCustomer
    ? ROUTES.PUBLIC_ROUTE_SCHEDULE.path
    : ROUTES.OPERATOR_PANEL_ROUTE_SCHEDULE.path;

  if (!data) {
    return <Loading />;
  }
  const { noTripsFound, unavailableDay, trips, unavailableNumberOfSeats } =
    data;
  const proceedToAddTicket =
    !noTripsFound && !unavailableDay && !unavailableNumberOfSeats;

  return (
    <span>
      {(noTripsFound || unavailableNumberOfSeats || unavailableDay) && (
        <Alert severity="warning" sx={{ width: 'fill-available' }}>
          <span>
            {noTripsFound && (
              <Trans t={t}>
                <Typography>
                  {t('tickets.searchResults.noTripsFound')}
                  <Link
                    to={proceedToScheduleLink}
                    style={{ marginLeft: '4px' }}
                  >
                    {t('link')}
                  </Link>
                </Typography>
              </Trans>
            )}
          </span>
          <span>
            {unavailableNumberOfSeats && (
              <Trans t={t}>
                <Typography>
                  {t('tickets.searchResults.unavailableNumberOfSeats')}
                  <Link
                    to={proceedToScheduleLink}
                    style={{ marginLeft: '4px' }}
                  >
                    {t('link')}
                  </Link>
                </Typography>
              </Trans>
            )}
          </span>
          <span>
            {unavailableDay && (
              <Trans t={t}>
                <Typography>
                  {t('tickets.searchResults.unavailableDay')}
                  <Link
                    to={proceedToScheduleLink}
                    style={{ marginLeft: '4px' }}
                  >
                    {t('link')}
                  </Link>
                </Typography>
              </Trans>
            )}
          </span>
        </Alert>
      )}
      {trips.map((trip) => {
        const { price, disableTrip } = getTripSectorCost({
          prices: trip.prices,
          points: trip.points,
          noTripsFound,
          unavailableDay,
          startLocationId,
          endLocationId,
          date,
        });
        if (price === undefined) {
          return null;
        }

        const isTripSelected = trip.id === selectedTrip?.id;
        const bus = buses.find((bus) => bus.id === trip.busConfigurationId);

        return (
          <Fragment key={trip.id}>
            <Box mb={1} display="flex" gap={2} alignItems="center">
              <Typography variant="subtitle1">{t('tickets.price')}</Typography>
              <Typography
                variant="subtitle1"
                fontWeight="bold"
                sx={{ textDecoration: data.discount ? 'line-through' : 'none' }}
              >
                {price} {t('tickets.currency')}
              </Typography>
            </Box>
            <Box width="100%">
              <StyledTripRoad
                $selected={isTripSelected}
                disabled={disableTrip}
                trip={trip}
                locations={locations}
                busFacilities={bus?.facilities || []}
                onClick={
                  disableTrip
                    ? undefined
                    : () => setSelectedTrip(isTripSelected ? undefined : trip)
                }
              />
            </Box>
            {proceedToAddTicket && (
              <Box width="100%" mt={2} display="flex" justifyContent="flex-end">
                {isTripSelected && (
                  <Button
                    sx={{ float: 'right', marginRight: 2 }}
                    variant="text"
                    onClick={() => setSelectedTrip(undefined)}
                    startIcon={<ClearIcon />}
                    color="error"
                  >
                    {t('tickets.removeTicket')}
                  </Button>
                )}
                <Button
                  disabled={disableTrip}
                  variant="contained"
                  onClick={() => setSelectedTrip(trip)}
                  startIcon={isTripSelected ? <CheckIcon /> : undefined}
                  color={isTripSelected ? 'success' : 'primary'}
                >
                  {isTripSelected ? (
                    <span>{t('tickets.ticketAdded')}</span>
                  ) : (
                    <span>{t('tickets.chooseTicket')}</span>
                  )}
                </Button>
              </Box>
            )}
          </Fragment>
        );
      })}
    </span>
  );
}
