import { useMutation } from '@apollo/client';
import { LOGIN_CUSTOMER } from '@bus-tickets-app/utils-apollo';
import {
  APOLLO_ERRORS,
  LANGUAGES,
  LoginCustomerRequest,
  PASSWORD_MIN_LENGTH,
  SuccessfulAuthResponse,
} from '@bus-tickets-app/utils-constants';
import { useEffect, useState } from 'react';

import { LOGIN_FIELDS } from './types';
import { useVerifyEmailContext } from '../context';

const defaultUser: Omit<LoginCustomerRequest, 'language'> = {
  email: '',
  password: '',
};

export default function useLogin(
  t: any,
  language: LANGUAGES,
  onSuccess: (token: string) => void,
  onFail: () => void,
  onReceiveVerifyEmail?: () => void,
) {
  const [user, setUser] = useState<Omit<LoginCustomerRequest, 'language'>>(defaultUser);
  const [isModalOpen, setOpenModal] = useState(false);
  const [validationError, setValidationError] = useState('');
  const [isLoading, setLoading] = useState(false);
  const { setNeedToVerifyEmail } = useVerifyEmailContext();

  const [loginCustomerMutation] = useMutation<
    { loginUser: SuccessfulAuthResponse },
    LoginCustomerRequest
  >(LOGIN_CUSTOMER, {
    onCompleted: ({ loginUser: { accessToken } }) => {
      setLoading(false);
      if (!accessToken) {
        setValidationError(t(`apolloErrors.${APOLLO_ERRORS.UNKNOWN_ERROR}`)!);
        onFail();
      } else {
        if (validationError) {
          setValidationError('');
        }
      }
      onSuccess(accessToken);
    },
    onError: (error: any) => {
      if (error.message === APOLLO_ERRORS.EMAIL_NOT_VERIFIED) {
        if (validationError) {
          setValidationError('');
        }
        setNeedToVerifyEmail(true);
        if (onReceiveVerifyEmail) {
          closeModal();
          onReceiveVerifyEmail();
        }
      } else {
        setValidationError(t(`apolloErrors.${error.message}`)!);
      }
      setLoading(false);
      onFail();
    },
  });

  useEffect(() => {
    setUser(defaultUser);
    setValidationError('');
  }, [isModalOpen]);

  const openModal = () => setOpenModal(true);
  const closeModal = () => setOpenModal(false);

  const onChangeUser = (key: string, value: string) => {
    if (validationError) {
      setValidationError('');
    }
    const updatedUser = {
      ...user,
      [key]: value.trim(),
    };
    setUser(updatedUser);
  };

  const login = () => {
    const missingField = LOGIN_FIELDS.find(
      (field) => field.required && user[field.key].length === 0,
    );
    if (missingField) {
      setValidationError(
        t('validations.missingField', {
          field: t(`authentication.${missingField.key}`),
        })!,
      );
      return;
    }
    const invalidField = LOGIN_FIELDS.find(
      (field) =>
        user[field.key] &&
        field.validation &&
        !new RegExp(field.validation).test(user[field.key]!),
    );
    if (invalidField) {
      setValidationError(
        t('validations.invalidField', {
          field: t(`authentication.${invalidField.key}`),
        })!,
      );
      return;
    }
    if (user.password.length < PASSWORD_MIN_LENGTH) {
      setValidationError(
        t(`apolloErrors.${APOLLO_ERRORS.INVALID_PASSWORD_LENGTH}`)!,
      );
      return;
    }

    setLoading(true);
    loginCustomerMutation({
      variables: {
        ...user,
        language,
      },
    });
  };

  return {
    isModalOpen,
    user,
    validationError,
    isLoading,
    openModal,
    closeModal,
    login,
    onChangeUser,
  };
}
