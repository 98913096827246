import { useMemo } from 'react';

import { getCitiesDictionary } from '../utils/cities';
import useGetCities from './useGetCities';

export default function useGetCitiesDictionary() {
  const { cities, loading } = useGetCities();
  const citiesDictionary = useMemo(() => getCitiesDictionary(cities), [cities]);

  return { citiesDictionary, loading };
}
