import { useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';
import { CHANGE_PASSWORD_FOR_LOGGEDIN_USER_MUTATION } from '@bus-tickets-app/utils-apollo';
import { APOLLO_ERRORS, ChangePasswordRequest, ChangePasswordResponse, PASSWORD_MIN_LENGTH, PASSWORD_SHOULD_MATCH } from '@bus-tickets-app/utils-constants';

export default function useChangePassword(
  t: any,
  modalIsOpen: boolean,
) {
  const [passwordSuccessfullyUpdated, setPasswordSuccessfullyUpdated] =
    useState(false);
  const [password, setPassword] = useState('');
  const [passwordConfirm, setConfirmPassword] = useState('');
  const [currentPassword, setCurrentPassword] = useState('');
  const [validationError, setValidationError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (modalIsOpen) {
      setPasswordSuccessfullyUpdated(false);
      setPassword('');
      setCurrentPassword('');
      setConfirmPassword('');
      setValidationError(null);
    }
  }, [modalIsOpen]);

  const [changePasswordMutation] = useMutation<
    ChangePasswordResponse,
    ChangePasswordRequest
  >(
    CHANGE_PASSWORD_FOR_LOGGEDIN_USER_MUTATION,
    {
      onCompleted: () => {
        setPasswordSuccessfullyUpdated(true);
        setIsLoading(false);
      },
      onError: (error) => {
        setValidationError(t(`apolloErrors.${error.message}`)!);
        setIsLoading(false);
      },
    },
  );

  const onConfirm = () => {
    setValidationError(null);

    if (!new RegExp(PASSWORD_SHOULD_MATCH).test(password)) {
      setValidationError(t(`apolloErrors.${APOLLO_ERRORS.INVALID_PASSWORD}`)!);
      return;
    }

    if (password.length < PASSWORD_MIN_LENGTH) {
      setValidationError(
        t(`apolloErrors.${APOLLO_ERRORS.INVALID_PASSWORD_LENGTH}`)!,
      );
      return;
    }

    if (password !== passwordConfirm) {
      setValidationError(
        t(`apolloErrors.${APOLLO_ERRORS.INVALID_PASSWORD_CONFIRMATION}`)!,
      );
      return;
    }

    setIsLoading(true);
    changePasswordMutation({
      variables: {
        password,
        passwordConfirm,
        currentPassword,
      },
    });
  };

  return {
    password,
    passwordConfirm,
    currentPassword,
    passwordSuccessfullyUpdated,
    validationError,
    isLoading,
    setValidationError,
    setCurrentPassword,
    setPassword,
    setConfirmPassword,
    setPasswordSuccessfullyUpdated,
    onConfirm,
  };
}