import { useContext } from 'react';

import SelectedMenuItemContext from '.';
import SelectedMenuItemContextProps from './types';

export default function useSelectedMenuItemContext(): SelectedMenuItemContextProps {
  const selectedMenuItemContext = useContext(SelectedMenuItemContext);

  if (!selectedMenuItemContext) {
    throw new Error(
      'useSelectedMenuItemContext must be inside a SelectedMenuItemContext provider',
    );
  }

  return selectedMenuItemContext;
}
