import { LANGUAGES } from '@bus-tickets-app/utils-constants';
import { useMemo } from 'react';

import { getCitiesDictionary } from '../utils/cities';
import useGetTrips from './useGetTrips';
import useGetCities from './useGetCities';

export default function useTripNames(language: LANGUAGES, withHours?: boolean) {
  const { cities } = useGetCities();
  const citiesDictionary = useMemo(
    () => getCitiesDictionary(cities),
    [cities, language],
  );

  const { trips: tripsData, locations } = useGetTrips();

  const tripNames = useMemo(() => {
    return tripsData.map((trip) => ({
      id: trip.id,
      value: trip.points
        .map((point) => {
          const location = locations.find(
            (location) => location.id === point.location,
          );
          if (!location) {
            return '';
          }
          const pointCity = location.city;
          if (!pointCity) {
            return '';
          }
          if (!citiesDictionary[pointCity]) {
            return '';
          }
          return (
            citiesDictionary[pointCity][language].name +
            (withHours ? ` (${point.hour}) ` : '')
          );
        })
        .join(' - '),
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tripsData, locations, citiesDictionary, language]);

  return tripNames;
}
