export function generatePassword (username: string) {
  return `${username.split('-').join('')}12345`.toLowerCase()
}

export function generateUsername (firstName: string, lastName: string) {
  return `${firstName.split(' ').join('-')}-${lastName.split(' ').join('-')}`.toLowerCase()
}

export const VERIFY_TOKEN = {
  ROUTE: '/verify',
  PARAM: 'token',
  STATUS: 'verify-status'
} as const
