import * as Sentry from '@sentry/browser';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { URL_SEARCH_PARAMS } from '../utils/urlSearchParams';

export default function useSearchParamsTripDetails(route?: string) {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const tripId = searchParams.get(URL_SEARCH_PARAMS.TRIP_ID)!;
  if (tripId === 'undefined') {
    Sentry.captureMessage('tripId undefined: ' + searchParams.toString());
  }
  let returnTripId =
    searchParams.get(URL_SEARCH_PARAMS.RETURN_TRIP_ID) || undefined;
  if (returnTripId === 'undefined') {
    Sentry.captureMessage('returnTripId undefined: ' + searchParams.toString());
    returnTripId = undefined;
  }
  const startLocationId = searchParams.get(
    URL_SEARCH_PARAMS.START_LOCATION_ID,
  )!;
  const endLocationId = searchParams.get(URL_SEARCH_PARAMS.END_LOCATION_ID)!;
  const numberOfTickets = Number.parseInt(
    searchParams.get(URL_SEARCH_PARAMS.NUMBER_OF_TICKETS)!,
  );
  const date = searchParams.get(URL_SEARCH_PARAMS.DATE)!;
  const returnDate =
    searchParams.get(URL_SEARCH_PARAMS.RETURN_DATE) || undefined;

  if (route) {
    if (!startLocationId || !endLocationId || !numberOfTickets || !date) {
      navigate(route);
    }
    if ((returnTripId && !returnDate) || (!returnTripId && returnDate)) {
      searchParams.delete(URL_SEARCH_PARAMS.RETURN_TRIP_ID);
      searchParams.delete(URL_SEARCH_PARAMS.RETURN_DATE);
      setSearchParams(searchParams);
      navigate(route);
    }
  }

  return {
    startLocationId,
    endLocationId,
    directTrip: {
      id: tripId,
      date,
    },
    returnTrip:
      returnTripId || returnDate
        ? {
            id: returnTripId,
            date: returnDate,
          }
        : undefined,
    numberOfTickets,
  };
}
