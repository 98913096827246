import { useLazyQuery } from '@apollo/client';
import { CHECK_PROMO_CODE_VALIDITY_QUERY } from '@bus-tickets-app/utils-apollo';
import {
  APOLLO_ERRORS,
  PromoCodesDiscount,
} from '@bus-tickets-app/utils-constants';
import { useEffect, useState } from 'react';
import { PromoDetailCode } from '../types';

export default function usePromoCode(
  t: any,
  setPromoDetailCodes: (promoCode: PromoDetailCode) => void,
) {
  const [promoCode, setPromoCode] = useState('');
  const [inputPromoCode, setInputPromoCode] = useState(false);
  const [successSnackbar, setSuccessSnackbar] = useState(false);

  const [error, setError] = useState('');
  const [validationInProgress, setValidationInProgress] = useState(false);

  useEffect(() => {
    if (!inputPromoCode) {
      setError('');
      setPromoCode('');
    }
  }, [inputPromoCode])

  const [checkPromoCodeValidityQuery] = useLazyQuery<
    { checkPromoCodeValidity: PromoCodesDiscount },
    { promoCode: string }
  >(CHECK_PROMO_CODE_VALIDITY_QUERY, {
    onCompleted: ({ checkPromoCodeValidity: discount }) => {
      setPromoDetailCodes({
        promoCode,
        discount,
      });
      setValidationInProgress(false);
      setInputPromoCode(false);
      setSuccessSnackbar(true);
      setPromoCode('');
    },
    onError: (error) => {
      setValidationInProgress(false);
      setError(t(`apolloErrors.${error.message as APOLLO_ERRORS}`)!);
    },
    fetchPolicy: 'network-only',
  });

  const onValidatePromoCode = (promoCode: string) => {
    setError('');
    setValidationInProgress(true);
    checkPromoCodeValidityQuery({
      variables: {
        promoCode,
      },
    });
  };

  return {
    error,
    validationInProgress,
    promoCode,
    inputPromoCode,
    successSnackbar,
    setSuccessSnackbar,
    setInputPromoCode,
    setPromoCode,
    onValidatePromoCode,
    setError,
  };
}
