import AirlineStopsIcon from '@mui/icons-material/AirlineStops';
import ContactSupportIcon from '@mui/icons-material/ContactSupport';
import InfoIcon from '@mui/icons-material/Info';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import RecentActorsIcon from '@mui/icons-material/RecentActors';

export enum SECTIONS {
  BOOKING = 'booking',
  LOCATIONS = 'locations',
  PRICES = 'prices',
  TRIPS = 'trips',
  ABOUT = 'about',
  CONTACT = 'contact',
}

export const pageSections = [
  {
    key: SECTIONS.BOOKING,
    icon: <RecentActorsIcon />,
  },
  {
    key: SECTIONS.LOCATIONS,
    icon: <LocationOnIcon />,
  },
  {
    key: SECTIONS.PRICES,
    icon: <MonetizationOnIcon />,
  },
  {
    key: SECTIONS.TRIPS,
    icon: <AirlineStopsIcon />,
  },
  {
    key: SECTIONS.ABOUT,
    icon: <InfoIcon />,
  },
  {
    key: SECTIONS.CONTACT,
    icon: <ContactSupportIcon />,
  },
] as const;
