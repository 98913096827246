import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import useIsLandscape from './useIsLandscape';

export default function useIsSmallScreen() {
  const theme = useTheme();
  const isLandscape = useIsLandscape();
  const matchesSmSize = useMediaQuery(theme.breakpoints.down('sm'));
  const matchesMdSize = useMediaQuery(theme.breakpoints.down('md'));

  return matchesSmSize || (matchesMdSize && isLandscape);
}
