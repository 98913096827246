import { useGetDisabledTripDays } from '@bus-tickets-app/utils-apollo';
import {
  colorsPalette,
  DATE_FORMAT_SERVER,
} from '@bus-tickets-app/utils-constants';
import { useSearchBooking } from '@bus-tickets-app/utils-ui';
import {
  Box,
  Button,
  Fade,
  FormControl,
  SelectChangeEvent,
  TextField,
  Typography,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import { ChangeEvent, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import useIsLandscape from '../../hooks/useIsLandscape';
import useIsSmallScreen from '../../hooks/useIsSmallScreen';
import Loading from '../common/loading';
import SearchLocation from '../common/searchLocation/SearchLocation';
import ValidationError from '../validationError';
import { OneWayTripIcon, ReturnTripIcon, TripButton } from './styles';
import SearchBookingProps from './types';
import useInitialTicket from './useInitialTicket';

export const RETURN_DATE_DATA_TEST_ID = 'ReturnDateDataTestId';
export const TRIP_BUTTON_DATA_TEST_ID = 'TripButtonDataTestId';

export default function SearchBooking({
  onUpdateTicket,
  onSearchTicket,
}: SearchBookingProps) {
  const { t } = useTranslation();
  const isSmallScreen = useIsSmallScreen();
  const isLandscape = useIsLandscape();
  const [initialTicket, cityId] = useInitialTicket();

  const {
    searchTicket,
    locations,
    validationError,
    hasReturnTicket,
    searchInProgress,
    setValidationError,
    onClickSearchTicket,
    setSearchTicket,
    setReturnTicket,
  } = useSearchBooking(t, initialTicket, cityId, onSearchTicket);
  const { days: disabledDates } = useGetDisabledTripDays();

  useEffect(() => {
    if (onUpdateTicket) {
      onUpdateTicket(searchTicket);
    }
  }, [onUpdateTicket, searchTicket]);

  const onChangeTicketDate =
    (key: 'date' | 'returnDate') => (date: Date | null) => {
      if (validationError) {
        setValidationError('');
      }
      if (date && !dayjs(date).isValid()) {
        return;
      }
      setSearchTicket({
        ...searchTicket,
        [key]: dayjs(date).format(DATE_FORMAT_SERVER),
      });
    };

  const onChangeSelection = (event: SelectChangeEvent<string>) => {
    if (validationError) {
      setValidationError('');
    }
    setSearchTicket({
      ...searchTicket,
      [event.target.name]: event.target.value,
    });
  };

  const onChangeNumeric = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    if (validationError) {
      setValidationError('');
    }
    setSearchTicket({
      ...searchTicket,
      [event.target.name]: Number.parseInt(event.target.value),
    });
  };

  const shouldDisableDay = (day: Date) =>
    disabledDates.indexOf(dayjs(day).format(DATE_FORMAT_SERVER)) > -1;

  const buttonSize = isSmallScreen ? 'small' : 'large';
  const inputSize = isSmallScreen ? 'small' : undefined;

  return (
    <Box
      display="flex"
      flexDirection="column"
      gap={isSmallScreen ? (isLandscape ? '6px' : '16px') : '18px'}
      sx={{
        '& > .MuiFormControl-root': {
          backgroundColor: colorsPalette.white,
          borderRadius: '4px',
        },
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
      }}
    >
      <TripButton
        data-testid={TRIP_BUTTON_DATA_TEST_ID}
        $type={hasReturnTicket ? 'ONE_WAY' : 'ROUND_TRIP'}
        sx={{
          height: isSmallScreen ? '40px' : '60px',
          float: 'left',
          marginBottom: isSmallScreen ? 0 : 1,
        }}
        size={buttonSize}
        variant="contained"
        onClick={() => setReturnTicket(!hasReturnTicket)}
        startIcon={
          hasReturnTicket ? (
            <ReturnTripIcon $size={buttonSize} />
          ) : (
            <OneWayTripIcon $size={buttonSize} />
          )
        }
      >
        <Box fontWeight="bold">
          {hasReturnTicket ? (
            <span>{t('tickets.oneWay')}</span>
          ) : (
            <span>
              {t('tickets.roundTicket')}
              <Typography
                fontSize={isSmallScreen ? '10px' : '12px'}
                fontWeight="bold"
                textTransform="capitalize"
              >
                {t('tickets.discount')} %
              </Typography>
            </span>
          )}
        </Box>
      </TripButton>
      <SearchLocation
        size={inputSize}
        id="startLocationId"
        label={t('tickets.startLocationId')}
        value={searchTicket.startLocationId}
        locations={locations}
        onChange={onChangeSelection}
      />
      <SearchLocation
        size={inputSize}
        id="endLocationId"
        label={t('tickets.endLocationId')}
        value={searchTicket.endLocationId}
        locations={locations}
        onChange={onChangeSelection}
      />
      <Box
        display="grid"
        gridTemplateColumns="1fr 1fr"
        sx={{ backgroundColor: 'transparent' }}
        gap={1}
      >
        <FormControl size={inputSize}>
          <DatePicker
            key="date"
            label={t('tickets.date')}
            defaultValue={undefined}
            value={searchTicket.date ? new Date(searchTicket.date) : null}
            slotProps={{ textField: { size: inputSize } }}
            sx={{
              '.MuiInputBase-root': {
                backgroundColor: 'white',
              },
            }}
            disablePast
            shouldDisableDate={shouldDisableDay}
            onChange={onChangeTicketDate('date')}
            onError={(newError: any) => {
              if (newError) {
                setSearchTicket({
                  ...searchTicket,
                  date: null as unknown as string,
                });
                setValidationError(t('validations.invalidDate')!);
              }
            }}
          />
        </FormControl>
        <Box sx={{ display: 'flex' }}>
          <Fade in={hasReturnTicket} key="returnDateFade">
            <FormControl
              size={inputSize}
              data-testid={RETURN_DATE_DATA_TEST_ID}
            >
              <DatePicker
                key="returnDate"
                label={t('tickets.returnDate')}
                defaultValue={undefined}
                value={
                  searchTicket.returnDate
                    ? new Date(searchTicket.returnDate)
                    : null
                }
                slotProps={{ textField: { size: inputSize } }}
                disablePast
                shouldDisableDate={shouldDisableDay}
                sx={{
                  '.MuiInputBase-root': {
                    backgroundColor: 'white',
                  },
                }}
                onChange={onChangeTicketDate('returnDate')}
                onError={(newError: any) => {
                  if (newError) {
                    setSearchTicket({
                      ...searchTicket,
                      date: null as unknown as string,
                    });
                    setValidationError(t('validations.invalidDate')!);
                  }
                }}
              />
            </FormControl>
          </Fade>
        </Box>
      </Box>

      <TextField
        size={inputSize}
        id="numberOfTickets"
        name="numberOfTickets"
        label={t('tickets.numberOfTickets')}
        type="number"
        inputProps={{ min: 1 }}
        value={searchTicket.numberOfTickets}
        onChange={onChangeNumeric}
      />
      {validationError ? (
        <ValidationError validationError={validationError} />
      ) : (
        <Button
          style={{ alignSelf: 'end' }}
          variant="contained"
          onClick={onClickSearchTicket}
        >
          {t('search')}
        </Button>
      )}
      {searchInProgress && <Loading />}
    </Box>
  );
}
