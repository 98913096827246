import {
  YALLA_BUS_FACEBOOK_LINK,
  YALLA_BUS_PHONE_NUMBER,
} from '@bus-tickets-app/utils-constants';
import { Alert, Box } from '@mui/material';
import { Trans } from 'react-i18next';

export default function ErrorBoundary() {
  return (
    <Box p={2}>
      <Alert severity="error" sx={{ width: 'fill-available' }}>
        <Trans
          i18nKey="generalError"
          tOptions={{
            phoneNumber: YALLA_BUS_PHONE_NUMBER,
          }}
          components={{
            phone: <a href={`tel:${YALLA_BUS_PHONE_NUMBER}`}></a>,
            facebook: (
              <a
                href={YALLA_BUS_FACEBOOK_LINK}
                target="_blank"
                rel="noreferrer"
              ></a>
            ),
          }}
        />
      </Alert>
    </Box>
  );
}
