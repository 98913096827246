import { useGetCities } from '@bus-tickets-app/utils-apollo';
import { colorsPalette, LANGUAGES } from '@bus-tickets-app/utils-constants';
import CallMadeIcon from '@mui/icons-material/CallMade';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import {
  Box,
  Button,
  Grid,
  Link,
  Modal,
  Tooltip,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Background2 from '../../../assets/background_2.png';
import BusLateral from '../../../assets/bus-lateral.png';
import Map from '../../../assets/map.png';
import useIsLandscape from '../../../hooks/useIsLandscape';
import useIsSmallScreen from '../../../hooks/useIsSmallScreen';
import ModalContent from '../../layout/modalContent';
import { backgroundStyles, StyledIconButton } from './styles';
import useNavigateToSearchBooking from './useNavigateToSearchBooking';

export default function Cities() {
  const { t, i18n } = useTranslation();
  const language = i18n.language as LANGUAGES;
  const navigateToSearchBooking = useNavigateToSearchBooking();
  const { cities } = useGetCities();

  const isLandscape = useIsLandscape();
  const isSmallScreen = useIsSmallScreen();
  const [selectedCity, setSelectedCity] = useState<null | string>();

  const city = selectedCity
    ? cities.find((city) => city.id === selectedCity)
    : undefined;

  return (
    <Box
      display="flex"
      alignItems="flex-start"
      justifyContent="center"
      flexDirection="column"
      sx={{
        backgroundImage: `url(${Background2})`,
        ...backgroundStyles,
        height: 'fit-content',
        minHeight: 'unset',
      }}
      px={{
        xs: 3,
        md: 6,
      }}
      py={4}
    >
      <Grid
        display="grid"
        gridTemplateColumns={{
          xs: isLandscape ? '1fr 2fr' : '1fr 1fr 1fr',
          md: isSmallScreen ? '1fr 2fr' : '1fr 2fr',
        }}
        sx={{
          backgroundImage: `url(${Map})`,
          backgroundPosition: '10% center',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'contain',
          display: 'grid',
          minHeight: '200px',
        }}
        my={4}
        ml={{
          xs: isLandscape ? 8 : 0,
          md: 8,
        }}
        width="fill-available"
      >
        <Grid
          gridColumn={{
            xs: isLandscape ? 2 : 3,
            md: isSmallScreen ? 2 : 2,
          }}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <Box textAlign="center">
            <Typography
              color={colorsPalette.blue}
              fontWeight="bold"
              display="contents"
              justifyContent="center"
              variant={isSmallScreen ? 'h5' : 'h3'}
            >
              {t('locations.choose')}
              <LocationOnIcon
                sx={{ ml: 1, color: colorsPalette.blue, mb: -0.3 }}
                fontSize={isSmallScreen ? 'medium' : 'large'}
              />
            </Typography>
          </Box>
        </Grid>
      </Grid>
      <Grid
        display="grid"
        gridTemplateColumns={
          isLandscape
            ? 'repeat(5, calc(64vw / 5))'
            : isSmallScreen
              ? 'repeat(4, 1fr)'
              : 'repeat(5, calc(64vw / 5))'
        }
        gridTemplateRows={
          isLandscape
            ? 'repeat(auto-fit, max(calc(72vw / 5), 65px))'
            : isSmallScreen
              ? 'repeat(3, max(calc(72vw / 5), 65px))'
              : 'repeat(auto-fit, max(calc(72vw / 5), 65px))'
        }
        width="fill-available"
        px={{
          xs: isLandscape ? '5%' : '1%',
          md: '5%',
        }}
        mx={{
          xs: isLandscape ? '5%' : '1%',
          md: '5%',
        }}
        borderRadius="20%"
        gap={{
          xs: isLandscape ? '5%' : isSmallScreen ? '2%' : '5%',
          md: '5%',
        }}
      >
        {cities.map((city) => {
          const { name } = city[language];
          return (
            <Tooltip key={city.id} title={name}>
              <StyledIconButton
                key={city.id}
                sx={{
                  padding: 0,
                  borderRadius: '20%',
                  margin: '5%',
                  position: 'relative',
                }}
                onClick={() => setSelectedCity(city.id)}
                $selected={city.id === selectedCity}
              >
                <Box
                  sx={{
                    backgroundImage: `url(${city.imageLink})`,
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    height: '100%',
                    width: '100%',
                    borderRadius: '16%',
                  }}
                />
                {!city.active && (
                  <Box
                    position="absolute"
                    left="-4%"
                    right="0"
                    width="calc(108%)"
                    sx={{
                      backgroundImage: `linear-gradient(240deg, ${colorsPalette.white}, ${colorsPalette.orange})`,
                      transform: 'rotate(-20deg) skew(-20deg)',
                    }}
                  >
                    <Typography
                      variant="body1"
                      fontSize={{ xs: '12px', md: '18px' }}
                    >
                      {t('soon')}
                    </Typography>
                  </Box>
                )}
              </StyledIconButton>
            </Tooltip>
          );
        })}
        <Grid
          gridArea={
            isLandscape ? '2/1/2/2' : isSmallScreen ? '2/1/2/4' : '2/1/2/2'
          }
        >
          <img
            src={BusLateral}
            alt="bus"
            width={isLandscape ? '400%' : isSmallScreen ? '100%' : '400%'}
            style={{
              marginLeft: isLandscape ? '-290%' : isSmallScreen ? '0' : '-290%',
            }}
          />
        </Grid>
      </Grid>
      <Modal open={!!city} onClose={() => setSelectedCity(null)}>
        {city ? (
          <ModalContent
            sx={{
              p: 6,
              gap: {
                xs: 2,
                md: 4,
              },
              width: {
                xs: isLandscape ? 'unset' : '70%',
                md: 'unset',
              },
              maxHeight: {
                xs: '70%',
                md: 'unset',
              },
            }}
          >
            <Typography variant="h4">{city[language].name}</Typography>
            <Typography overflow="auto" textAlign="justify">
              {city[language].description}
            </Typography>
            <Link
              target="_blank"
              rel="noopener"
              underline="hover"
              href={`https://www.google.com/maps/place/${
                city[LANGUAGES.EN].name
              },+Irak/`}
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 0.2,
                width: 'fit-content',
              }}
            >
              {t('googleMaps')}
              <CallMadeIcon />
            </Link>
            <Button
              onClick={() =>
                navigateToSearchBooking(city.active ? { cityId: city.id } : {})
              }
              variant="contained"
              sx={{ alignSelf: 'flex-end', width: 'fit-content' }}
            >
              {t('goToBooking')}
            </Button>
          </ModalContent>
        ) : (
          <span />
        )}
      </Modal>
    </Box>
  );
}
