import { LANGUAGES } from '@bus-tickets-app/utils-constants';
import {
  DATA_TESTID_REQUEST_PASSWORD_CANCEL,
  DATA_TESTID_REQUEST_PASSWORD_ERROR,
  DATA_TESTID_REQUEST_PASSWORD_INPUT,
  DATA_TESTID_REQUEST_PASSWORD_LINK,
  DATA_TESTID_REQUEST_PASSWORD_MODAL,
  DATA_TESTID_REQUEST_PASSWORD_SUBMIT,
  DATA_TESTID_REQUEST_PASSWORD_SUCCESS,
  useRequestPasswordReset,
} from '@bus-tickets-app/utils-ui';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Link,
  TextField,
  Typography,
} from '@mui/material';
import { Fragment, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import useLogoImage from '../../../../hooks/images/useNameColoredImage';
import Loading from '../../../common/loading/Loading';
import Toast from '../../../notification';
import ValidationError from '../../../validationError';

export default function RequestPasswordReset() {
  const { t, i18n } = useTranslation();
  const language = i18n.language as LANGUAGES;
  const LogoImage = useLogoImage();

  const [copyEmail, setCopyEmail] = useState('');

  const {
    isRequestPasswordResetInProgress,
    validationError,
    isLoading,
    requestPasswordResetSuccessfully,
    email,
    startRequestPasswordResetProcess,
    endRequestForgotPasswordProcess,
    requestPasswordReset,
    onChangeEmail,
    onClosePasswordSentNotification,
  } = useRequestPasswordReset(t, language);

  return (
    <Fragment>
      <Link
        onClick={startRequestPasswordResetProcess}
        underline="hover"
        sx={{ cursor: 'pointer' }}
        data-testid={DATA_TESTID_REQUEST_PASSWORD_LINK}
      >
        {t('authentication.forgotPassword.question')}
      </Link>
      <Dialog
        PaperProps={{
          sx: {
            width: '550px',
            maxWidth: '80%',
            py: 3,
            px: { xs: 2, md: 4 },
            mx: { xs: 1 },
          },
        }}
        onClose={endRequestForgotPasswordProcess}
        open={isRequestPasswordResetInProgress}
        data-testid={DATA_TESTID_REQUEST_PASSWORD_MODAL}
      >
        <DialogTitle
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: { xs: 'center', md: 'space-between' },
          }}
        >
          <Typography
            variant="h5"
            sx={{ display: { xs: 'none', md: 'block' } }}
          >
            {t('authentication.forgotPassword.action')}
          </Typography>
          <img src={LogoImage} alt="yalla-bus" width="200px" />
        </DialogTitle>
        <DialogContent
          sx={{ display: 'flex', flexDirection: 'column', gap: 2, mt: 1 }}
        >
          {isLoading && <Loading />}
          <Typography>{t('authentication.forgotPassword.message')}</Typography>
          <TextField
            name="email"
            id="email"
            key="email"
            variant="outlined"
            value={email}
            onChange={(event) => onChangeEmail(event.target.value)}
            inputProps={{
              'data-testid': DATA_TESTID_REQUEST_PASSWORD_INPUT,
            }}
          />
          <ValidationError
            data-testid={DATA_TESTID_REQUEST_PASSWORD_ERROR}
            validationError={validationError}
          />
        </DialogContent>
        <DialogActions sx={{ mx: 2 }}>
          <Button
            sx={{ mr: 2, float: 'right' }}
            variant="text"
            onClick={endRequestForgotPasswordProcess}
            data-testid={DATA_TESTID_REQUEST_PASSWORD_CANCEL}
          >
            {t('cancel')}
          </Button>
          <Button
            sx={{ float: 'right' }}
            variant="contained"
            onClick={() => {
              setCopyEmail(email);
              requestPasswordReset();
            }}
            data-testid={DATA_TESTID_REQUEST_PASSWORD_SUBMIT}
          >
            {t('submit')}
          </Button>
        </DialogActions>
      </Dialog>
      <Toast
        dataTestId={DATA_TESTID_REQUEST_PASSWORD_SUCCESS}
        severity="success"
        open={requestPasswordResetSuccessfully}
        onClose={() => {
          onClosePasswordSentNotification();
          setCopyEmail('');
        }}
      >
        <Trans
          i18nKey="authentication.forgotPassword.success"
          components={[<span key="bold" style={{ fontWeight: 'bold' }}></span>]}
          values={{ email: copyEmail }}
        />
      </Toast>
    </Fragment>
  );
}
