import { getCitiesDictionary, useGetCities } from '@bus-tickets-app/utils-apollo';
import { LANGUAGES, LocationForTrip, TicketType, Trip } from '@bus-tickets-app/utils-constants';
import { useEffect, useState, useMemo } from 'react';

export default function useSearchBookingResult(
  language: LANGUAGES,
  locations: LocationForTrip[],
  startLocationId: string,
  endLocationId: string,
  hasReturnTicket: boolean
) {
  const [selectedTrip, setSelectedTrip] = useState<Trip | undefined>(undefined);
  const [selectedReturnTrip, setSelectedReturnTrip] = useState<
    Trip | undefined
  >(undefined);

  const { cities } = useGetCities();
  const citiesDictionary = useMemo(() => getCitiesDictionary(cities), [cities]);

  const [ticketType, setTicketType] = useState<TicketType>('DIRECT_TRIP');

  useEffect(() => {
    if (hasReturnTicket && selectedTrip && !selectedReturnTrip) {
     setTicketType('RETURN_TRIP'); 
    }
  }, [selectedTrip]);

  useEffect(() => {
    if (selectedReturnTrip && !selectedTrip) {
     setTicketType('DIRECT_TRIP'); 
    }
  }, [selectedReturnTrip]);

  const startLocation = locations.find(
    (location) => location.id === startLocationId,
  );
  const endLocation = locations.find(
    (location) => location.id === endLocationId,
  );

  const startLocationCityName =
    startLocation && citiesDictionary[startLocation.city]
      ? citiesDictionary[startLocation.city][language].name
      : '';
  const endLocationCityName =
    endLocation && citiesDictionary[endLocation.city]
      ? citiesDictionary[endLocation.city][language].name
      : '';
  const enableProceedToBooking =
    !!selectedTrip && (hasReturnTicket ? !!selectedReturnTrip : true);

  const showTicketsSummary = !!selectedTrip || !!selectedReturnTrip;

  return {
    ticketType,
    setTicketType,
    startLocationCityName,
    endLocationCityName,
    selectedTrip,
    setSelectedTrip,
    selectedReturnTrip,
    setSelectedReturnTrip,
    enableProceedToBooking,
    showTicketsSummary,
  }
}