import { ACCESS_TOKEN_KEY, LANGUAGES } from '@bus-tickets-app/utils-constants';
import { LOGIN_FIELDS, useLogin } from '@bus-tickets-app/utils-ui';
import LoginIcon from '@mui/icons-material/Login';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
} from '@mui/material';
import { ChangeEventHandler, Fragment, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { setAuthToken } from '../../../contexts/auth';
import useLogoImage from '../../../hooks/images/useNameColoredImage';
import ValidationError from '../../validationError';
import ExternalAuth from '../externalAuth';
import RequestPasswordReset from './requestPasswordReset';
import { LoginProps } from './types';

export default function Login({
  className,
  text,
  withIcon,
  ...buttonProps
}: LoginProps) {
  const { t, i18n } = useTranslation();
  const language = i18n.language as LANGUAGES;

  const onLoginSuccess = (token: string) => setAuthToken(token);
  const onLoginFail = () => localStorage.removeItem(ACCESS_TOKEN_KEY);

  const {
    user,
    isLoading,
    isModalOpen,
    validationError,
    onChangeUser,
    openModal,
    closeModal,
    login,
  } = useLogin(t, language, onLoginSuccess, onLoginFail);
  const LogoImage = useLogoImage();

  const onChangeInput = (event: Event & { target: HTMLTextAreaElement }) => {
    onChangeUser(event.target.name, event.target.value);
  };

  const style = useMemo(() => {
    if (language === LANGUAGES.AR) {
      return { ml: 4 };
    }
    return { mr: 4 };
  }, [language]);

  return (
    <Fragment>
      <Button className={className} onClick={openModal} {...buttonProps}>
        {withIcon && <LoginIcon sx={style} />}
        <span className="text">{text || t('auth.login')}</span>
      </Button>
      <Dialog
        PaperProps={{
          sx: {
            width: { xs: 'fill-available', md: '450px' },
            py: 3,
            px: { xs: 2, md: 4 },
          },
        }}
        onClose={isLoading ? undefined : closeModal}
        open={isModalOpen}
      >
        <DialogTitle
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: { xs: 'center', md: 'space-between' },
          }}
        >
          <Typography
            variant="h5"
            sx={{ display: { xs: 'none', md: 'block' } }}
          >
            {t('auth.login')}
          </Typography>
          <img src={LogoImage} alt="yalla-bus" width="200px" />
        </DialogTitle>
        <DialogContent
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
            mt: 1,
            pt: '8px !important',
          }}
        >
          {LOGIN_FIELDS.map((field) => (
            <TextField
              name={field.key}
              id={field.key}
              key={field.key}
              type={field.type}
              required={field.required}
              label={t(`auth.${field.key}`)}
              variant="outlined"
              value={user[field.key]}
              onChange={
                onChangeInput as unknown as ChangeEventHandler<
                  HTMLTextAreaElement | HTMLInputElement
                >
              }
            />
          ))}
          <ValidationError validationError={validationError} />
          <ExternalAuth />
          <RequestPasswordReset />
        </DialogContent>
        <DialogActions sx={{ px: 3 }}>
          <Box display="flex" gap={2}>
            <Button variant="text" onClick={closeModal} disabled={isLoading}>
              {t('cancel')}
            </Button>
            <LoadingButton
              variant="contained"
              onClick={login}
              loading={isLoading}
            >
              {t('submit')}
            </LoadingButton>
          </Box>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
}
