import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber';
import EditIcon from '@mui/icons-material/Edit';
import HttpsIcon from '@mui/icons-material/Https';
import LogoutIcon from '@mui/icons-material/Logout';

export enum CUSTOMER_OPTION {
  EDIT_PROFILE = 'edit-profile',
  MY_BOOKINGS = 'my-bookings',
  CHANGE_PASSWORD = 'change-password',
  LOGOUT = 'logout',
}

export const customerOptions = [
  {
    key: CUSTOMER_OPTION.EDIT_PROFILE,
    value: 'editProfile',
    icon: <EditIcon />,
  },
  {
    key: CUSTOMER_OPTION.MY_BOOKINGS,
    value: 'myBookings',
    icon: <ConfirmationNumberIcon />,
  },
  {
    key: CUSTOMER_OPTION.CHANGE_PASSWORD,
    value: 'changePassword',
    icon: <HttpsIcon />,
  },
  {
    key: CUSTOMER_OPTION.LOGOUT,
    value: 'logout',
    icon: <LogoutIcon />,
  },
] as const;
