import {
  ApolloClient,
  ApolloLink,
  ApolloProvider,
  concat,
  HttpLink,
  InMemoryCache,
  split,
} from '@apollo/client';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { getMainDefinition } from '@apollo/client/utilities';
import { inMemoryCachePolicies } from '@bus-tickets-app/utils-apollo';
import { ACCESS_TOKEN_KEY } from '@bus-tickets-app/utils-constants';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { enUS } from '@mui/x-date-pickers/locales';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import * as Sentry from '@sentry/react';
import { createClient } from 'graphql-ws';
import ReactDOM from 'react-dom/client';

import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

const localStorageAccessToken = localStorage.getItem(ACCESS_TOKEN_KEY);
const authorization = localStorageAccessToken
  ? `Bearer ${localStorageAccessToken}`
  : '';

const wsLink = new GraphQLWsLink(
  createClient({
    url: process.env.REACT_APP_GRAPHQL_WS_SERVER!,
    connectionParams: {
      Authorization: authorization,
    },
  }),
);

const httpLink = new HttpLink({ uri: process.env.REACT_APP_GRAPHQL_SERVER });

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    );
  },
  wsLink,
  httpLink,
);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

Sentry.init({
  dsn: 'https://d227ecdc63b5439c9b09d0bfd7e45aea@o4505572288167936.ingest.sentry.io/4505572367728640',
  debug: true,
  enabled: process.env.NODE_ENV !== 'development',
  initialScope: {
    tags: { env: process.env.REACT_APP_SENTRY_ENV },
  },
  environment: process.env.REACT_APP_SENTRY_ENV,
  integrations: [
    new Sentry.BrowserTracing({
      tracePropagationTargets: [process.env.REACT_APP_GRAPHQL_SERVER!],
    }),
    new Sentry.Replay(),
  ],
  replaysSessionSampleRate: 0,
  replaysOnErrorSampleRate:
    process.env.REACT_APP_SENTRY_ENV === 'development' ? 0 : 1.0,
});

const client = new ApolloClient({
  cache: new InMemoryCache({
    typePolicies: inMemoryCachePolicies,
  }),
  link: concat(
    new ApolloLink((operation, forward) => {
      // add the authorization to the headers
      operation.setContext(({ headers = {} }) => ({
        headers: {
          ...headers,
          Authorization: authorization,
        },
      }));
      return forward(operation);
    }),
    splitLink,
  ),
});

root.render(
  <ApolloProvider client={client}>
    <LocalizationProvider
      dateAdapter={AdapterDateFns}
      localeText={
        enUS.components.MuiLocalizationProvider.defaultProps.localeText
      }
    >
      <App />
    </LocalizationProvider>
  </ApolloProvider>,
);

serviceWorkerRegistration.unregister();
