import { colorsPalette } from '@bus-tickets-app/utils-constants';
import { Box, Typography } from '@mui/material';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';

import Background1 from '../../../assets/background_1.jpg';
import Background3 from '../../../assets/background_3.png';
import PricesBackground from '../../../assets/prices-background.jpeg';
import { SECTIONS } from '../../../contexts/selectedMenuItem/constants';
import useIsLandscape from '../../../hooks/useIsLandscape';
import useIsSmallScreen from '../../../hooks/useIsSmallScreen';
import { MENU_HEIGHT } from '../../customer/menu';
import Footer from '../../footer';
import SearchBooking from '../../searchBooking';
import About from './About';
import Cities from './Cities';
import Contact from './Contact';
import Prices from './Prices';
import useSectionsObservable from './sectionsObservable';
import { backgroundStyles, StyledViewTrips } from './styles';
import useNavigateToSearchBooking from './useNavigateToSearchBooking';

export default function LangingScreen() {
  const { t } = useTranslation();
  const isLandscape = useIsLandscape();
  const isSmallScreen = useIsSmallScreen();

  const navigateToSearchBooking = useNavigateToSearchBooking();
  const containerRef = useRef<HTMLDivElement>(null);

  const { setSectionRef } = useSectionsObservable(containerRef);

  return (
    <Box
      ref={containerRef}
      overflow="scroll"
      sx={{ overflowX: 'auto' }}
      height="100vh"
    >
      <Box
        id={SECTIONS.BOOKING}
        key={SECTIONS.BOOKING}
        ref={setSectionRef(SECTIONS.BOOKING)}
        display="flex"
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
        sx={{
          ...backgroundStyles,
          backgroundColor: `${colorsPalette.orange}80`,
          backgroundImage: `url(${Background1})`,
          alignItems: {
            xs: 'flex-end',
            md: 'flex-end',
          },
          backgroundPosition: {
            md: 'left center',
            xs: '43% center',
          },
          backgroundPositionY: '70%',
          justifyContent: {
            xs: 'flex-start',
          },
          height: `calc(100vh - ${MENU_HEIGHT}px)`,
          direction: 'ltr',
        }}
        pt={8}
      >
        <Box
          display="flex"
          flexDirection="column"
          gap={{
            xs: isLandscape ? 0.8 : 3,
            md: isSmallScreen ? 0.8 : 3,
          }}
          marginTop={{
            xs: isLandscape ? 'auto' : 12,
            md: isSmallScreen ? 'auto' : '9%',
          }}
          marginBottom={{
            xs: isLandscape ? 'auto' : 'unset',
            md: isSmallScreen ? 'auto' : 'unset',
          }}
          marginLeft={{
            xs: '4%',
            md: 'auto',
          }}
          marginRight={{
            xs: '4%',
            md: '12%',
            lg: '20%',
          }}
          width={{
            md: '300px',
          }}
          maxWidth={{
            xs: '250px',
            md: '300px',
          }}
          sx={{ direction: 'ltr' }}
        >
          <SearchBooking onSearchTicket={navigateToSearchBooking} />
        </Box>
      </Box>
      <Box
        sx={{ direction: 'ltr' }}
        id={SECTIONS.LOCATIONS}
        key={SECTIONS.LOCATIONS}
        ref={setSectionRef(SECTIONS.LOCATIONS)}
      >
        <Cities />
      </Box>
      <Box
        id={SECTIONS.PRICES}
        key={SECTIONS.PRICES}
        ref={setSectionRef(SECTIONS.PRICES)}
        sx={{
          backgroundImage: `url(${PricesBackground})`,
          backgroundRepeat: 'repeat',
          backgroundSize: '8%',
        }}
        display="flex"
        flexDirection="column"
        alignItems="center"
        pb={4}
      >
        <Box
          sx={{
            backgroundColor: colorsPalette.orange,
            boxShadow: `0px 2px 4px -1px ${colorsPalette.orange}80, 0px 4px 5px 0px ${colorsPalette.orange}86, 0px 1px 10px 0px ${colorsPalette.orange}88`,
          }}
          display="flex"
          alignItems="center"
          justifyContent="center"
          gap={2}
          py={2}
          mb={4}
          width="fill-available"
        >
          <Typography textAlign="center" color="white" variant="h4">
            {t('prices.title')}
          </Typography>
        </Box>
        <Prices />
      </Box>
      <Box
        id={SECTIONS.TRIPS}
        key={SECTIONS.TRIPS}
        ref={setSectionRef(SECTIONS.TRIPS)}
      >
        <Box
          sx={{
            backgroundImage: `url(${Background3})`,
            ...backgroundStyles,
            backgroundPosition: {
              xs: 'left bottom',
              md: 'left center',
            },
            height: 'fit-content',
            minHeight: 'unset',
          }}
          pb={4}
        >
          <Box
            sx={{
              backgroundColor: colorsPalette.blue,
              boxShadow: `0px 2px 4px -1px ${colorsPalette.blue}80, 0px 4px 5px 0px ${colorsPalette.blue}86, 0px 1px 10px 0px ${colorsPalette.blue}88`,
            }}
            display="flex"
            alignItems="center"
            justifyContent="center"
            gap={2}
            py={2}
          >
            <Typography textAlign="center" color="white" variant="h4">
              {t('trips.viewTrips')}
            </Typography>
          </Box>
          <StyledViewTrips />
        </Box>
      </Box>
      <Box
        id={SECTIONS.ABOUT}
        key={SECTIONS.ABOUT}
        ref={setSectionRef(SECTIONS.ABOUT)}
      >
        <About />
      </Box>
      <Box
        id={SECTIONS.CONTACT}
        key={SECTIONS.CONTACT}
        ref={setSectionRef(SECTIONS.CONTACT)}
      >
        <Contact />
      </Box>
      <Footer />
    </Box>
  );
}
