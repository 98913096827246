import { colorsPalette } from '@bus-tickets-app/utils-constants';
import { createTheme } from '@mui/material';

const theme = createTheme({
  palette: {
    primary: {
      main: colorsPalette.blue,
      contrastText: colorsPalette.white,
    },
    secondary: {
      main: colorsPalette.orange,
      contrastText: colorsPalette.white,
    },
  },
  components: {
    MuiAlert: {
      styleOverrides: {
        message: {
          paddingRight: '8px',
          paddingLeft: '8px',
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          minWidth: 'unset',
        },
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          gap: '32px',
        },
      },
    },
  },
});

export default theme;
