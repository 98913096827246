import { gql } from '@apollo/client';

export const GET_DRIVER_TRIP_CUSTOMERS = gql`
  query GetDriverTripCustomers($input: DriverTripCustomersInput!) {
    getDriverTripCustomers(input: $input) {
      id
      bookingName
      seatNumber
      clientDetails {
        name
      }
      startLocationId
      endLocationId
    }
  }
`;
