import { useMutation } from '@apollo/client';
import { REQUEST_RESET_PASSWORD_MUTATION } from '@bus-tickets-app/utils-apollo';
import {
  APOLLO_ERRORS,
  EMAIL_MATCH,
  LANGUAGES,
  RequestResetPasswordRequest,
  SuccessfulResponse,
} from '@bus-tickets-app/utils-constants';
import { useEffect, useState } from 'react';

export default function useRequestPasswordReset(
  t: any,
  language: LANGUAGES,
) {
  const [email, setEmail] = useState('');
  const [isRequestPasswordResetInProgress, setRequestPasswordResetInProcess] =
    useState(false);
  const [validationError, setValidationError] = useState('');
  const [
    requestPasswordResetSuccessfully,
    setRequestPasswordResetSuccessfully,
  ] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [requestPassworResetMutation] = useMutation<
    { requestPasswordReset: SuccessfulResponse },
    RequestResetPasswordRequest
  >(REQUEST_RESET_PASSWORD_MUTATION, {
    onCompleted: (result) => {
      const {
        requestPasswordReset: { error },
      } = result;

      setIsLoading(false);
      endRequestForgotPasswordProcess();
      if (error) {
        setValidationError(t(`apolloErrors.${APOLLO_ERRORS.UNKNOWN_ERROR}`)!);
        return;
      }
      setRequestPasswordResetSuccessfully(true);
    },
    onError: (error: any) => {
      setIsLoading(false);
      setValidationError(t(`apolloErrors.${error.message}`)!);
    },
  });

  useEffect(() => {
    setValidationError('');
    setEmail('');
    if (isRequestPasswordResetInProgress && requestPasswordResetSuccessfully) {
      setRequestPasswordResetSuccessfully(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRequestPasswordResetInProgress]);

  const startRequestPasswordResetProcess = () =>
    setRequestPasswordResetInProcess(true);

  const endRequestForgotPasswordProcess = () =>
    setRequestPasswordResetInProcess(false);

  const onChangeEmail = (email: string) => {
    if (validationError) {
      setValidationError('');
    }
    setEmail(email.trim());
  };

  const requestPasswordReset = () => {
    if (validationError) {
      setValidationError('');
    }
    if (!email.length) {
      setValidationError(
        t('validations.missingField', { field: t('authentication.email') })!,
      );
      return;
    }
    if (!new RegExp(EMAIL_MATCH).test(email)) {
      setValidationError(
        t('validations.invalidField', { field: t('authentication.email') })!,
      );
      return;
    }

    setIsLoading(true);
    requestPassworResetMutation({
      variables: {
        email,
        language,
      },
    });
  };

  const onClosePasswordSentNotification = () => {
    setRequestPasswordResetSuccessfully(false);
  }
  
  return {
    isRequestPasswordResetInProgress,
    validationError,
    isLoading,
    requestPasswordResetSuccessfully,
    email,
    onChangeEmail,
    startRequestPasswordResetProcess,
    endRequestForgotPasswordProcess,
    requestPasswordReset,
    onClosePasswordSentNotification,
  };
}
