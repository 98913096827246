import {
  ChangePasswordProps,
  DATA_TESTID_CHANGE_PASSWORD_CANCEL,
  DATA_TESTID_CHANGE_PASSWORD_ERROR,
  DATA_TESTID_CHANGE_PASSWORD_FIELD_CONFIRM_PASSWORD,
  DATA_TESTID_CHANGE_PASSWORD_FIELD_CURRENT_PASSWORD,
  DATA_TESTID_CHANGE_PASSWORD_FIELD_PASSWORD,
  DATA_TESTID_CHANGE_PASSWORD_MODAL,
  DATA_TESTID_CHANGE_PASSWORD_SUBMIT,
  DATA_TESTID_CHANGE_PASSWORD_SUCCESS,
  useChangePassword,
} from '@bus-tickets-app/utils-ui';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from '@mui/material';
import { ChangeEventHandler, Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import ValidationError from '../../validationError';

export default function ChangePassword({ isOpen, close }: ChangePasswordProps) {
  const { t } = useTranslation();

  const {
    password,
    passwordConfirm,
    currentPassword,
    passwordSuccessfullyUpdated,
    validationError,
    setValidationError,
    setCurrentPassword,
    setPassword,
    setConfirmPassword,
    setPasswordSuccessfullyUpdated,
    onConfirm,
  } = useChangePassword(t, isOpen);

  return (
    <Fragment>
      <Dialog
        PaperProps={{
          sx: {
            width: { xs: 'fill-available', md: '450px' },
            py: 3,
            px: { xs: 2, md: 4 },
          },
        }}
        onClose={close}
        open={isOpen}
        data-testid={DATA_TESTID_CHANGE_PASSWORD_MODAL}
      >
        <DialogTitle>{t('manageUsers.actions.changePassword')}</DialogTitle>
        <DialogContent>
          <Box display="grid" gridTemplateColumns="1fr" gap={2} pt={2}>
            <TextField
              inputProps={{
                'data-testid':
                  DATA_TESTID_CHANGE_PASSWORD_FIELD_CURRENT_PASSWORD,
              }}
              name="currentPassword"
              id="currentPassword"
              key="currentPassword"
              required
              type="password"
              label={t('changePassword.currentPassword')}
              variant="outlined"
              value={currentPassword}
              onChange={
                ((event: Event & { target: HTMLInputElement }) => {
                  setValidationError(null);
                  setCurrentPassword(event.target.value);
                }) as unknown as ChangeEventHandler<HTMLInputElement>
              }
            />
            <TextField
              inputProps={{
                'data-testid': DATA_TESTID_CHANGE_PASSWORD_FIELD_PASSWORD,
              }}
              name="password"
              id="password"
              required
              type="password"
              label={t('changePassword.newPassword')}
              variant="outlined"
              value={password}
              onChange={
                ((event: Event & { target: HTMLInputElement }) => {
                  setValidationError(null);
                  setPassword(event.target.value);
                }) as unknown as ChangeEventHandler<HTMLInputElement>
              }
            />
            <TextField
              inputProps={{
                'data-testid':
                  DATA_TESTID_CHANGE_PASSWORD_FIELD_CONFIRM_PASSWORD,
              }}
              name="passwordConfirm"
              id="passwordConfirm"
              key="passwordConfirm"
              required
              type="password"
              label={t('changePassword.passwordConfirm')}
              variant="outlined"
              value={passwordConfirm}
              onChange={
                ((event: Event & { target: HTMLInputElement }) => {
                  setValidationError(null);
                  setConfirmPassword(event.target.value);
                }) as unknown as ChangeEventHandler<HTMLInputElement>
              }
            />
            <ValidationError
              data-testid={DATA_TESTID_CHANGE_PASSWORD_ERROR}
              validationError={validationError}
            />
          </Box>
        </DialogContent>
        <DialogActions sx={{ px: 3 }}>
          <Button
            sx={{ mt: 4, mr: 2, float: 'right' }}
            variant="text"
            data-testid={DATA_TESTID_CHANGE_PASSWORD_CANCEL}
            onClick={close}
          >
            {t('cancel')}
          </Button>
          <Button
            sx={{ mt: 4, float: 'right' }}
            variant="contained"
            data-testid={DATA_TESTID_CHANGE_PASSWORD_SUBMIT}
            onClick={onConfirm}
          >
            {t('confirm')}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        PaperProps={{ sx: { p: 2, maxWidth: 350, width: '85%' } }}
        onClose={close}
        open={passwordSuccessfullyUpdated}
        data-testid={DATA_TESTID_CHANGE_PASSWORD_SUCCESS}
      >
        <DialogTitle>{t('manageUsers.actions.changePassword')}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {t('manageUsers.passwordChangedSubbessfully')}
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ px: 3 }}>
          <Button
            sx={{ float: 'right' }}
            variant="contained"
            onClick={() => {
              setPasswordSuccessfullyUpdated(false);
              close();
            }}
          >
            {t('continue')}
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
}
