import { gql } from '@apollo/client';
import { LANGUAGES } from '@bus-tickets-app/utils-constants';

const LOCATION_QUERY = `
  id
  city
  locationLink
  contactPhone
  contactEmail
  ${LANGUAGES.AR} {
    address
    openSchedule
  }
  ${LANGUAGES.EN} {
    address
    openSchedule
  }
`;

export const GET_LOCATIONS_QUERY = gql`
  query GetLocationsConfiguration {
    getLocationConfigurations {
      ${LOCATION_QUERY}
    }
  }
`;

export const CREATE_LOCATION_MUTATION = gql`
  mutation CreateLocationConfiguration(
    $input: LocationConfigurationDataInput!
  ) {
    createLocationConfiguration(input: $input) {
      ${LOCATION_QUERY}
    }
  }
`;

export const EDIT_LOCATION_MUTATION = gql`
  mutation EditLocationConfiguration(
    $locationId: String!
    $input: LocationConfigurationDataInput!
  ) {
    editLocationConfiguration(locationId: $locationId, input: $input) {
      ${LOCATION_QUERY}
    }
  }
`;

export const DELETE_LOCATION_MUTATION = gql`
  mutation DeleteLocationConfiguration($locationId: String!) {
    deleteLocationConfiguration(locationId: $locationId) {
      id
    }
  }
`;
