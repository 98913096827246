import { useLocation, useNavigate } from 'react-router-dom';

import { ROUTES } from './routes';

export default function useNavigateToLogin() {
  const location = useLocation();
  const navigate = useNavigate();

  const navigateToLogin = () => {
    if (location.pathname.startsWith(ROUTES.ADMIN_PANEL_ROUTE.path)) {
      navigate(ROUTES.ADMIN_PANEL_LOGIN_ROUTE.path);
      return;
    }
    navigate(ROUTES.DEFAULT_ROUTE.path);
  };
  return navigateToLogin;
}
