import { useLazyQuery } from '@apollo/client';
import {
  APOLLO_ERRORS,
  BookTicketInformation,
  BookTicketInformationResponse,
} from '@bus-tickets-app/utils-constants';
import { GET_BOOK_TICKET_INFORMATION_QUERY } from '../resolvers';

export default function useRequenceBookTicketInformation(
  setPending: (pending: boolean) => void,
  setApolloError: (error: APOLLO_ERRORS) => void,
  callback: (
    bookTicketInformationResponse: BookTicketInformationResponse,
  ) => void,
) {
  const [bookTicketInformationQuey] = useLazyQuery<
    { getBookTicketInformation: BookTicketInformationResponse },
    { input: BookTicketInformation }
  >(GET_BOOK_TICKET_INFORMATION_QUERY);

  const requestBookTicketInformation = (
    input: BookTicketInformation,
    silent?: boolean,
  ) => {
    if (!silent) {
      setPending(true);
      bookTicketInformationQuey({
        variables: {
          input,
        },
        fetchPolicy: 'network-only',
      })
        .then((result) => {
          if (!silent) {
            setPending(false);
          }
          if (!result.data) {
            if (silent) {
              return;
            }
            if (result.error) {
              setApolloError(result.error.message as APOLLO_ERRORS);
              return;
            }
            setApolloError(APOLLO_ERRORS.UNKNOWN_ERROR);
            return;
          }
          callback(result.data.getBookTicketInformation);
        })
        .catch((error) => {
          setPending(false);
          setApolloError(error.message as APOLLO_ERRORS);
        });
    }
  };

  return requestBookTicketInformation;
}
