import { DATE_FORMAT_SERVER, SearchTicket } from '@bus-tickets-app/utils-constants'
import dayjs from 'dayjs';

export type SearchTicketRouteParams = {
  startLocationId?: string;
  endLocationId?: string;
  date: string;
  returnDate?: string;
  numberOfTickets: number;
  cityId?: string;
};

export type BookTicketRouteParams = {
  tripId: string,
  returnTripId?: string
  date: string;
  returnDate?: string;
  startLocationId: string;
  endLocationId: string;
  numberOfTickets: number;
};

export const getSearchTicketRouteParams = (
  searchTicket: Partial<SearchTicket> & { cityId?: string },
  existingParams: { date?: string, returnDate?: string, numberOfTickets?: number } | undefined = {}
): SearchTicketRouteParams => {
  const {
    startLocationId,
    endLocationId,
    date,
    returnDate,
    numberOfTickets,
    cityId,
  } = searchTicket;

  let resultDate;
  if (date) {
    resultDate = date;
  } else {
    if (existingParams.date) {
      resultDate = existingParams.date;
    } else {
      resultDate = dayjs(new Date()).format(DATE_FORMAT_SERVER);
    }
  }

  let resultNumberOfTickets;
  if (numberOfTickets) {
    resultNumberOfTickets = numberOfTickets;
  } else {
    if (existingParams.numberOfTickets) {
      resultNumberOfTickets = existingParams.numberOfTickets;
    } else {
      resultNumberOfTickets = 1;
    }
  }
  const result: SearchTicketRouteParams = {
    date: resultDate,
    numberOfTickets: resultNumberOfTickets,
  };

  if (startLocationId) {
    result.startLocationId = startLocationId;
  }
  if (endLocationId) {
    result.endLocationId = endLocationId;
  }
  
  if (returnDate) {
    result.returnDate = returnDate;
  } else {
    if (existingParams.returnDate) {
      result.returnDate = existingParams.returnDate;
    }
  }

  if (cityId) {
    result.cityId = cityId;
  }

  return result;
}