import { gql } from '@apollo/client';

export const GET_CURRENT_USER_QUERY = gql`
  query GetCurrentUser {
    getMe {
      id
      username
      roles
    }
  }
`;

export const GET_CURRENT_CUSTOMER_QUERY = gql`
  query GetCurrentUser {
    getMe {
      id
      username
      roles
      firstName
      lastName
      email
      phone
      points
      hasFirstTrip
    }
  }
`;

export const LOGOUT_USER_QUERY = gql`
  query LogoutUser {
    logoutUser
  }
`;

export const GET_MANAGEBLE_USERS_QUERY = gql`
  query GetManagebleUsers {
    getManagebleUsers {
      id
      username
      firstName
      lastName
      email
      phone
      roles
      location
      deleted
    }
  }
`;

export const GET_OPERATORS = gql`
  query GetOperators {
    getOperators {
      id
      username
    }
  }
`;

export const CREATE_USER_MUTATION = gql`
  mutation CreateManagebleUser($input: UserDataInput!) {
    createManagebleUser(input: $input) {
      id
      username
      firstName
      lastName
      email
      phone
      roles
      password
      location
      deleted
    }
  }
`;

export const EDIT_USER_MUTATION = gql`
  mutation EditManagebleUser($userId: String!, $input: UserDataInput!) {
    editManagebleUser(userId: $userId, input: $input) {
      id
      username
      firstName
      lastName
      email
      phone
      roles
      location
      deleted
    }
  }
`;

export const GENERATE_PASSWORD_MUTATION = gql`
  mutation GeneratePasswordForManagebleUser($userId: String!) {
    generatePasswordForManagebleUser(userId: $userId) {
      id
      username
      firstName
      lastName
      email
      phone
      roles
      password
      location
      deleted
    }
  }
`;

export const DELETE_USER_MUTATION = gql`
  mutation DeleteManagebleUser($userId: String!) {
    deleteManagebleUser(userId: $userId) {
      id
    }
  }
`;
