import { LANGUAGES } from '@bus-tickets-app/utils-constants';
import { useTranslation } from 'react-i18next';

import ArImage from '../../assets/ar-name-on-blue.png';
import EnImage from '../../assets/en-name-on-blue.png';

export default function useNameColoredOnBlue() {
  const { i18n } = useTranslation();
  if (i18n.language === LANGUAGES.EN) {
    return EnImage;
  }
  return ArImage;
}
