import { colorsPalette, LANGUAGES } from '@bus-tickets-app/utils-constants';
import { useCurrentCustomerContext } from '@bus-tickets-app/utils-ui';
import MenuIcon from '@mui/icons-material/Menu';
import PaymentIcon from '@mui/icons-material/Payment';
import {
  AppBar,
  Box,
  Button,
  Container,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem as MUIListItem,
  Toolbar,
  Tooltip,
  Typography,
} from '@mui/material';
import { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet, useNavigate } from 'react-router-dom';

import {
  pageSections,
  SECTIONS,
} from '../../../contexts/selectedMenuItem/constants';
import useSelectedMenuItemContext from '../../../contexts/selectedMenuItem/useSelectedMenuItem';
import useLogo from '../../../hooks/images/useLogo';
import useNameImage from '../../../hooks/images/useNameImage';
import Login from '../../auth/clientLogin/Login';
import SignUp from '../../auth/signUp/SignUp';
import { ROUTES } from '../../navigation/routes';
import SelectLanguage from '../../navigation/selectLanguage/SelectLanguage';
import Customer from '../Customer';
import { MENU_HEIGHT } from './constants';
import ListItem from './listItem';
import { StyledLogin, StyledSignUp } from './styles';

export default function CustomerMenu() {
  const { t, i18n } = useTranslation();
  const language = i18n.language as LANGUAGES;
  const { selectedMenuItem, setSelectedMenuItem, setClickedMenuItem } =
    useSelectedMenuItemContext();
  const navigate = useNavigate();
  const { currentCustomer: user } = useCurrentCustomerContext();
  const NameImage = useNameImage();
  const LogoImage = useLogo();
  const [anchorElNav, setAnchorElNav] = useState<null | HTMLElement>(null);

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const navigateToSection = (sectionKey: string) => {
    handleCloseNavMenu();
    navigate(ROUTES.DEFAULT_ROUTE.path);
    setClickedMenuItem(sectionKey as SECTIONS);
  };

  const navigateToHomepage = () => {
    navigate(ROUTES.DEFAULT_ROUTE.path);
    setClickedMenuItem(null);
  };

  const handleNavigateUploadProofPayment = () => {
    handleCloseNavMenu();
    navigate(ROUTES.PUBLIC_ROUTE_UPLOAD_PAYMENT_PROOF.path);
    setSelectedMenuItem(null);
  };

  return (
    <Fragment>
      <AppBar
        position="fixed"
        sx={{
          backgroundColor: colorsPalette.orange,
          height: `${MENU_HEIGHT}px`,
          justifyContent: 'center',
        }}
      >
        <Container maxWidth="xl">
          <Toolbar disableGutters>
            <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="inherit"
              >
                <MenuIcon />
              </IconButton>
              <Box
                sx={{
                  flexGrow: 1,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <img
                  src={NameImage}
                  alt="yalla-bus"
                  width="150px"
                  onClick={navigateToHomepage}
                  style={{
                    cursor: 'pointer',
                    alignSelf: 'center',
                  }}
                />
              </Box>
            </Box>
            <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
              <img
                src={NameImage}
                alt="yalla-bus"
                width="150px"
                onClick={navigateToHomepage}
                style={{ cursor: 'pointer' }}
              />
              <Box
                display="flex"
                sx={{ flexGrow: 1, justifyContent: 'center', gap: 2 }}
              >
                {pageSections.map((section) => (
                  <Button
                    key={section.key}
                    onClick={() => navigateToSection(section.key)}
                    sx={{
                      fontWeight: 'bold',
                      textTransform: 'capitalize',
                      color:
                        selectedMenuItem === section.key
                          ? 'white'
                          : colorsPalette.blue,
                    }}
                  >
                    {t(`menu.${section.key}`)}
                  </Button>
                ))}
              </Box>
            </Box>
            {user && (
              <Box mr={2}>
                <Customer />
              </Box>
            )}
            {!user && (
              <Box
                mr={2}
                alignItems="center"
                sx={{ display: { xs: 'none', md: 'flex' } }}
              >
                <Login />
                <Typography component="span" color={colorsPalette.blue}>
                  /
                </Typography>
                <SignUp />
              </Box>
            )}
            <SelectLanguage />
            <Tooltip title={t('menu.payment')}>
              <IconButton
                size="large"
                onClick={handleNavigateUploadProofPayment}
                color="primary"
                sx={{
                  display: {
                    xs: 'none',
                    md: 'block',
                  },
                  paddingBottom: 1,
                }}
              >
                <PaymentIcon />
              </IconButton>
            </Tooltip>
          </Toolbar>
        </Container>
        <Drawer
          anchor={language === LANGUAGES.AR ? 'right' : 'left'}
          open={Boolean(anchorElNav)}
          onClose={handleCloseNavMenu}
          keepMounted
        >
          <Box
            sx={{ width: 'fit-content' }}
            role="presentation"
            onClick={handleCloseNavMenu}
            onKeyDown={handleCloseNavMenu}
          >
            <List sx={{ pt: 0 }}>
              <MUIListItem
                onClick={navigateToHomepage}
                sx={{
                  boxShadow:
                    '0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)',
                  backgroundColor: colorsPalette.orange,
                  py: 1.9,
                  gap: 3,
                  justifyContent: 'center',
                  cursor: 'pointer',
                }}
              >
                <img src={LogoImage} alt="yalla-bus" width="18px" />
                <img src={NameImage} alt="yalla-bus" width="150px" />
              </MUIListItem>
              <Divider />
              {!user && (
                <Fragment>
                  <MUIListItem
                    sx={{
                      '& .text': {
                        textAlign: language === LANGUAGES.AR ? 'right' : 'left',
                      },
                    }}
                    key="login"
                    onClick={handleCloseNavMenu}
                    disablePadding
                  >
                    <StyledLogin withIcon />
                  </MUIListItem>
                  <MUIListItem
                    sx={{
                      '& .text': {
                        textAlign: language === LANGUAGES.AR ? 'right' : 'left',
                      },
                    }}
                    key="signup"
                    onClick={handleCloseNavMenu}
                    disablePadding
                  >
                    <StyledSignUp withIcon />
                  </MUIListItem>
                  <Divider sx={{ borderWidth: '1px' }} />
                </Fragment>
              )}
              {pageSections.map((section) => (
                <ListItem
                  key={section.key}
                  icon={section.icon}
                  text={t(`menu.${section.key}`)}
                  onClick={() => navigateToSection(section.key)}
                />
              ))}
              <Divider sx={{ borderWidth: '1px' }} />
              <ListItem
                key={ROUTES.PUBLIC_ROUTE_UPLOAD_PAYMENT_PROOF.path}
                icon={<PaymentIcon />}
                text={t('menu.payment')}
                onClick={handleNavigateUploadProofPayment}
              />
            </List>
          </Box>
        </Drawer>
      </AppBar>
      <Outlet />
    </Fragment>
  );
}
