import { LANGUAGES } from '../translations';

export enum USER_TYPE {
  ADMIN = 'ADMIN',
  OPERATOR = 'OPERATOR',
  CUSTOMER = 'CUSTOMER',
}

export type UserType = USER_TYPE.ADMIN | USER_TYPE.OPERATOR | USER_TYPE.CUSTOMER

interface BaseUser {
  id: string;
  firstName: string;
  lastName: string;
  phone: string;
  roles: UserType[];
  password?: string;
}

export interface Admin extends BaseUser {
  username: string
  email?: string;
  deleted: number;
}

export interface Operator extends Admin {
  location: string
}

export interface Customer extends BaseUser {
  email: string;
  points: number;
  hasFirstTrip: boolean;
  verified?: boolean;
  authMethod?: 'FB';
  preferredLanguage: LANGUAGES;
}

export type User = Admin & Operator
