import { useMutation } from '@apollo/client';
import { EDIT_USER_MUTATION } from '@bus-tickets-app/utils-apollo';
import { Customer } from '@bus-tickets-app/utils-constants';
import { useEffect, useState } from 'react';

import { EDIT_PROFILE_USER_FIELDS } from './types';
import { useCurrentCustomerContext } from '../context';

export default function useEditProfile(
  t: any,
  isOpen: boolean,
  close: () => void,
  isValidPhoneNumber: (phone: string) => boolean,
) {
  const { currentCustomer, setCurrentCustomer } = useCurrentCustomerContext();
  const [user, setUser] = useState<Customer>(currentCustomer!);
  const [validationError, setValidationError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [userHasBeenUpdated, setUserHasBeenUpdated] = useState(false);

  const onCloseNotification = () => setUserHasBeenUpdated(false);

  const [editProfileMutation] = useMutation<
    { editManagebleUser: Customer },
    { userId: string; input: Customer }
  >(EDIT_USER_MUTATION, {
    onCompleted: ({ editManagebleUser }) => {
      const updatedCustomer: Customer = {
        id: editManagebleUser.id,
        firstName: editManagebleUser.firstName,
        lastName: editManagebleUser.lastName,
        email: editManagebleUser.email,
        phone: editManagebleUser.phone,
        roles: editManagebleUser.roles,
        points: editManagebleUser.points,
        hasFirstTrip: editManagebleUser.hasFirstTrip,
        preferredLanguage: editManagebleUser.preferredLanguage,
      };
      setCurrentCustomer(updatedCustomer);
      setUser(updatedCustomer);
      setIsLoading(false);
      setUserHasBeenUpdated(true);
      close();
    },
    onError: (error: any) => {
      setValidationError(t(`apolloErrors.${error.message}`)!);
      setIsLoading(false);
    },
  });

  useEffect(() => {
    setUser(currentCustomer!);
    setValidationError('');
    if (isOpen) {
      setUserHasBeenUpdated(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  const onChange = (key: string, value: any) => {
    if (validationError) {
      setValidationError('');
    }
    const updatedUser = {
      ...user,
      [key]: value,
    };
    setUser(updatedUser);
  };

  const editProfile = () => {
    const missingField = EDIT_PROFILE_USER_FIELDS.find(
      (field) => field.required && user[field.key].length === 0,
    );
    if (missingField) {
      setValidationError(
        t('validations.missingField', {
          field: t(`authentication.${missingField.key}`),
        })!,
      );
      return;
    }
    const invalidField = EDIT_PROFILE_USER_FIELDS.find(
      (field) =>
        user[field.key] &&
        field.validation &&
        !new RegExp(field.validation).test(user[field.key]!),
    );
    if (invalidField) {
      setValidationError(
        t('validations.invalidField', {
          field: t(`authentication.${invalidField.key}`),
        })!,
      );
      return;
    }
    if (!isValidPhoneNumber(user.phone)) {
      setValidationError(t('validations.invalidPhone')!);
      return;
    }

    setIsLoading(true);
    editProfileMutation({
      variables: {
        userId: user.id,
        input: {
          ...user,
        },
      },
    });
  };

  return {
    user,
    validationError,
    isLoading,
    userHasBeenUpdated,
    editProfile,
    onChange,
    onCloseNotification,
  };
}
