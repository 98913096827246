import {
  PAYMENT_RESULT,
  USER_TYPE,
  UserType,
} from '@bus-tickets-app/utils-constants';

export const ROUTES = {
  /** customer */
  DEFAULT_ROUTE: {
    path: '/',
    requireAccess: null,
  },
  LOGIN_ROUTE: {
    path: '/login',
    requireAccess: null,
  },
  PASSWORD_REST: {
    path: '/passwordReset',
    requireAccess: null,
  },
  SEARCH_BOOKING: {
    path: '/search-ticket',
    requireAccess: null,
  },
  BOOK_TICKET: {
    path: '/book-ticket',
    requireAccess: null,
  },
  PUBLIC_ROUTE_SCHEDULE: {
    path: '/schedule',
    requireAccess: null,
  },
  MY_BOOKINGS: {
    path: '/my-bookings',
    requireAccess: [USER_TYPE.CUSTOMER],
  },
  PRINT_TICKETS: {
    path: '/print-tickets',
    requireAccess: null,
  },
  PUBLIC_ROUTE_POLICY: {
    path: '/policy',
    requireAccess: null,
  },
  PUBLIC_ROUTE_DATA_DELETION: {
    path: '/data-deletion',
    requireAccess: null,
  },
  PUBLIC_ROUTE_UPLOAD_PAYMENT_PROOF: {
    path: '/upload-payment-proof',
    requireAccess: null,
  },
  /** logistics */
  ADMIN_PANEL_ROUTE: {
    path: '/admin',
    requireAccess: [USER_TYPE.ADMIN],
  },
  ADMIN_PANEL_LOGIN_ROUTE: {
    path: '/admin/login',
    requireAccess: null,
  },
  ADMIN_PANEL_ROUTE_CONFIGURE_BUSES: {
    path: '/admin/buses',
    requireAccess: [USER_TYPE.ADMIN],
  },
  ADMIN_PANEL_ROUTE_CONFIGURE_CITIES: {
    path: '/admin/cities',
    requireAccess: [USER_TYPE.ADMIN],
  },
  ADMIN_PANEL_ROUTE_CONFIGURE_LOCATIONS: {
    path: '/admin/locations',
    requireAccess: [USER_TYPE.ADMIN],
  },
  ADMIN_PANEL_ROUTE_CONFIGURE_TRIPS: {
    path: '/admin/trips',
    requireAccess: [USER_TYPE.ADMIN],
  },
  ADMIN_PANEL_ROUTE_DISABLED_TRIP_DAYS: {
    path: '/admin/disabled-trip-days',
    requireAccess: [USER_TYPE.ADMIN],
  },
  ADMIN_PANEL_ROUTE_DISCOUNTS: {
    path: '/admin/discounts',
    requireAccess: [USER_TYPE.ADMIN],
  },
  ADMIN_PANEL_ROUTE_CONFIGURE_REPORTS: {
    path: '/admin/reports',
    requireAccess: [USER_TYPE.ADMIN],
  },
  ADMIN_PANEL_ROUTE_CONTACTS: {
    path: '/admin/contacts',
    requireAccess: [USER_TYPE.ADMIN],
  },
  OPERATOR_PANEL_ROUTE_TICKETS: {
    path: '/operator/tickets',
    requireAccess: [USER_TYPE.OPERATOR, USER_TYPE.ADMIN],
  },
  OPERATOR_PANEL_ROUTE_SCHEDULE: {
    path: '/operator/schedule',
    requireAccess: [USER_TYPE.OPERATOR, USER_TYPE.ADMIN],
  },
  OPERATOR_PANEL_ROUTE_BOOKING: {
    path: '/operator/booking',
    requireAccess: [USER_TYPE.OPERATOR, USER_TYPE.ADMIN],
  },
  OPERATOR_PANEL_ROUTE_BOOKINGS: {
    path: '/operator/bookings',
    requireAccess: [USER_TYPE.OPERATOR, USER_TYPE.ADMIN],
  },
  OPERATOR_PANEL_ROUTE_DRIVER_TRIP: {
    path: '/operator/driver-trip',
    requireAccess: [USER_TYPE.OPERATOR, USER_TYPE.ADMIN],
  },
  VALIDATE_TICKET_QR: {
    path: '/scan',
    requireAccess: null,
  },
  PAYMENT_RESULT_ROUTE: {
    path: PAYMENT_RESULT.ROUTE,
    requireAccess: null,
  },
} as const;

export const isAllowedToAccess = (role: USER_TYPE, routePath: string) => {
  const routeKey = Object.keys(ROUTES).find(
    (routeKey) => ROUTES[routeKey as keyof typeof ROUTES].path === routePath,
  ) as keyof typeof ROUTES;
  if (!routeKey) {
    return false;
  }
  const route = ROUTES[routeKey];
  if (!route.requireAccess) {
    return true;
  }
  return !!(route.requireAccess as unknown as UserType[]).find(
    (routeRole) => routeRole === role,
  );
};

export const routeRequiresPermissions = (routePath: string) => {
  const routeKey = Object.keys(ROUTES).find(
    (routeKey) => ROUTES[routeKey as keyof typeof ROUTES].path === routePath,
  ) as keyof typeof ROUTES;
  if (!routeKey) {
    return false;
  }
  const route = ROUTES[routeKey];
  if (!route.requireAccess) {
    return false;
  }
  return true;
};
