export const DATA_TESTID_FORGOT_PASSWORD_TOAST = 'ForgotPasswordToast' as const;

export const DATA_TESTID_EDIT_PROFILE_TOAST = 'EditProfileToast' as const;

export const DATA_TESTID_REQUEST_PASSWORD_LINK = 'ForgotPasswordLink' as const;
export const DATA_TESTID_REQUEST_PASSWORD_MODAL = 'ForgotPasswordModal' as const;
export const DATA_TESTID_REQUEST_PASSWORD_INPUT = 'ForgotPasswordInput' as const;
export const DATA_TESTID_REQUEST_PASSWORD_CANCEL = 'ForgotPasswordCancel' as const;
export const DATA_TESTID_REQUEST_PASSWORD_SUBMIT = 'ForgotPasswordSubmit' as const;
export const DATA_TESTID_REQUEST_PASSWORD_ERROR = 'ForgotPasswordError' as const;
export const DATA_TESTID_REQUEST_PASSWORD_SUCCESS = 'ForgotPasswordSuccess' as const;

export const DATA_TESTID_EXTERNAL_AUTH = 'ExternalAuth' as const;

export const DATA_TESTID_SIGN_UP_BUTTON = 'SignUpButton' as const;
export const DATA_TESTID_SIGN_UP_MODAL = 'Modal' as const;
export const DATA_TESTID_SIGN_UP_CANCEL = 'CancelButton' as const;
export const DATA_TESTID_SIGN_UP_SUBMIT = 'SubmitButon' as const;
export const DATA_TESTID_SIGN_UP_ERROR = 'Error' as const;
export const DATA_TESTID_SIGN_UP_SUCCESS = 'Success' as const;


export const DATA_TESTID_VERIFY_EMAIL_PASSWORD_TOAST = 'VerifyEmailPasswordToast' as const;
export const DATA_TESTID_VERIFY_EMAIL_STATUS_TOAST = 'VerifyEmailStatusToast' as const;

export const DATA_TESTID_LOADING = 'Loading' as const;

export const DATA_TESTID_DISABLED_TRIP_DAYS_REQUEST_SUCCESS = 'RequestSuccess' as const;

export const DATA_TESTID_CHANGE_PASSWORD_MODAL = 'Modal' as const;
export const DATA_TESTID_CHANGE_PASSWORD_CANCEL = 'CancelButton' as const;
export const DATA_TESTID_CHANGE_PASSWORD_SUBMIT = 'SubmitButon' as const;
export const DATA_TESTID_CHANGE_PASSWORD_ERROR = 'Error' as const;
export const DATA_TESTID_CHANGE_PASSWORD_SUCCESS = 'Success' as const;
export const DATA_TESTID_CHANGE_PASSWORD_FIELD_PASSWORD = 'Password' as const;
export const DATA_TESTID_CHANGE_PASSWORD_FIELD_CONFIRM_PASSWORD = 'ConfirmPassword' as const;
export const DATA_TESTID_CHANGE_PASSWORD_FIELD_CURRENT_PASSWORD = 'CurrrentPassword' as const;
