import dayjs from 'dayjs';
import { EXPIRE_TIME, TIME_STATUS } from './time';
import { Price, TripPoint } from '../models';

export const getTripTimeStatus = (
  tripDateFormat: string,
  tripHour: string,
  expirationTime: number,
) => {
  const currentDate = new Date();
  const currentDateTimestamp = currentDate.getTime();

  const [hoursString, minutesString, meridian] = tripHour.split(/[\s:]+/);
  const meridianToLowerString = meridian.toLowerCase() as 'am' | 'pm';
  let hours = parseInt(hoursString) + (meridianToLowerString === 'pm' ? 12 : 0);
  if (meridianToLowerString === 'am' && hours === 12) {
    hours = 0;
  }

  const minutes = parseInt(minutesString);

  const newDate = dayjs(tripDateFormat).toDate();

  newDate.setHours(hours, minutes, 0, 0);

  const tripDate = new Date(
    newDate.getTime(),
  );

  const tripDateTime = tripDate.getTime();

  if (tripDateTime > currentDateTimestamp) {
    if (tripDateTime - currentDateTimestamp < expirationTime) {
      return TIME_STATUS.LAST_MINUTE;
    }
    return TIME_STATUS.FUTURE;
  }
  return TIME_STATUS.PAST;
};

export const getTripStatusOnLastBooking = (
  tripDateFormat: string,
  tripHour: string,
) =>
  getTripTimeStatus(
    tripDateFormat,
    tripHour,
    EXPIRE_TIME.LAST_MINUTE_BOOKING_TO_PAY,
  );

export const getTripStatusOnCancellationPolicy = (
  tripDateFormat: string,
  tripHour: string,
) =>
  getTripTimeStatus(tripDateFormat, tripHour, EXPIRE_TIME.CANCELATION_POLICY);

export const getTripSectorCost = ({
  prices,
  points,
  noTripsFound,
  unavailableDay,
  startLocationId,
  endLocationId,
  date
}: {
  prices: Price[],
  points: TripPoint[],
  noTripsFound: boolean,
  unavailableDay: boolean,
  startLocationId: string,
  endLocationId: string,
  date: string
}) => {
  const tripSector = prices.find(
    (price) =>
      price.start === startLocationId && price.end === endLocationId,
  );
  if (!tripSector) {
    return { price: undefined, disableTrip: false };
  }
  const price = tripSector.cost;

  const tripTimeStatus =
    noTripsFound || unavailableDay
      ? TIME_STATUS.FUTURE
      : getTripStatusOnLastBooking(
          date,
          points.find((point) => point.location === startLocationId)!
            .hour,
        );

  const disableTrip = tripTimeStatus === TIME_STATUS.PAST;

  return { price, disableTrip };
}