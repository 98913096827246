import { useCurrentCustomer } from '@bus-tickets-app/utils-apollo';
import {
  BookTicketInformationResponse,
  calculateFidelityDiscountSum,
  dedupDiscounts,
  Discount,
  DISCOUNT_TYPE,
  PeriodDiscount,
} from '@bus-tickets-app/utils-constants';
import { useMemo } from 'react';

export default function useDiscounts(
  ticketInformation: BookTicketInformationResponse | null,
) {
  const { user } = useCurrentCustomer();

  const discounts = useMemo(
    () =>
      dedupDiscounts(
        ticketInformation?.directTicket.discountsApplied,
        ticketInformation?.returnTicket?.discountsApplied,
      ),
    [ticketInformation],
  );
  const points = user ? user.points || 0 : 0;

  const firstTripDiscount: Discount | undefined = useMemo(
    () =>
      discounts.find((discount) => discount.type === DISCOUNT_TYPE.FIRST_TRIP),
    [discounts],
  );
  const periodDiscounts: PeriodDiscount[] = useMemo(
    () =>
      discounts.filter(
        (discount) => discount.type === DISCOUNT_TYPE.PERIOD,
      ) as PeriodDiscount[],
    [discounts],
  );
  const fidelityDiscount = useMemo(
    () =>
      discounts.find(
        (discount) => discount.type === DISCOUNT_TYPE.FIDELITY_POINTS,
      ),
    [discounts],
  );

  const appliedDiscounts: Discount[] = useMemo(() => {
    let discounts: Discount[] = [];
    if (firstTripDiscount) {
      discounts.push(firstTripDiscount);
    }
    if (periodDiscounts) {
      discounts = discounts.concat(periodDiscounts);
    }
    return discounts;
  }, [firstTripDiscount, periodDiscounts]);

  const fidelityDiscountAmmount = fidelityDiscount
    ? calculateFidelityDiscountSum(points, fidelityDiscount.percentage)
    : 0;

  return {
    appliedDiscounts,
    fidelityDiscountAmmount,
    firstTripDiscount,
    periodDiscounts,
    points,
    fidelityDiscount,
  };
}
