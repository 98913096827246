import { useContext } from 'react';

import VerifyEmailContext from './VerifyEmailContext';
import VerifyEmailContextProps from './types';

export default function useVerifyEmailContext(): VerifyEmailContextProps {
  const verifyEmailContext = useContext(VerifyEmailContext);

  if (!verifyEmailContext) {
    throw new Error(
      'useVerifyEmailContextProps must be inside a VerifyEmailContext provider',
    );
  }

  return verifyEmailContext;
}
