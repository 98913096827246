import {
  getCitiesDictionary,
  useGetCities,
} from '@bus-tickets-app/utils-apollo';
import { colorsPalette, LANGUAGES } from '@bus-tickets-app/utils-constants';
import { useTripRoad } from '@bus-tickets-app/utils-ui';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import DirectionsBusIcon from '@mui/icons-material/DirectionsBus';
import { Alert, Box, Card, Typography } from '@mui/material';
import { t } from 'i18next';
import { Fragment, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import ArrowBus from '../../assets/arrow-bus.png';
import { BusFacilities } from '../../utils/busFacilities';
import DisplayAvailableDays from '../common/displayAvailableDays/DisplayAvailableDays';
import Loading from '../common/loading/Loading';
import TripRoadProps from './types';

export default function TripRoad({
  locations,
  trip: { points: tripPoints, availableDays },
  className,
  disabled,
  busName,
  busFacilities,
  unavailableDays = [],
  onClick,
}: TripRoadProps) {
  const { i18n } = useTranslation();
  const language = i18n.language as LANGUAGES;

  const { cities, loading: citiesAreLoading } = useGetCities();
  const citiesDictionary = useMemo(() => getCitiesDictionary(cities), [cities]);

  const tripRoadData = useTripRoad(
    locations,
    citiesDictionary,
    language,
    tripPoints,
    unavailableDays,
  );

  if (citiesAreLoading || !tripRoadData) {
    return <Loading />;
  }

  const {
    startLocation,
    startLocationCityName,
    endLocation,
    endLocationCityName,
    circuit,
    disabledDaysMessage,
  } = tripRoadData;

  return (
    <Box>
      <Card
        aria-disabled={disabled}
        sx={{
          opacity: disabled ? 0.6 : 1,
          p: 2,
          backgroundColor: `${colorsPalette.orange}30`,
          cursor: onClick ? 'pointer' : 'auto',
          height: 'fit-content',
        }}
        elevation={4}
        className={className}
        onClick={onClick}
      >
        <Box
          display="grid"
          width="100%"
          gridTemplateColumns="1fr 86px 1fr"
          gridTemplateRows="1fr auto"
          rowGap={1}
        >
          <Box
            display="flex"
            alignItems="center"
            gap={1}
            gridColumn="span 3"
            overflow="auto"
          >
            {circuit.map((cityName, index) =>
              index === 0 ? (
                <Typography key={index} fontWeight="bold" color="primary">
                  {cityName}
                </Typography>
              ) : (
                <Fragment key={index}>
                  <ArrowForwardIcon color="primary" fontSize="small" />
                  <Typography fontWeight="bold" color="primary">
                    {cityName}
                  </Typography>
                </Fragment>
              ),
            )}
          </Box>

          {busName && (
            <Box gridColumn="span 3" display="flex" alignItems="center">
              <DirectionsBusIcon
                color="primary"
                style={{ marginRight: '8px' }}
              />
              <Typography variant="caption">{busName}</Typography>
            </Box>
          )}

          <Typography variant="body1" fontWeight="bold" textAlign="center">
            {startLocationCityName}
          </Typography>
          <img
            src={ArrowBus}
            alt="to"
            style={{
              transform: language === LANGUAGES.AR ? 'scaleX(-1)' : 'scaleX(1)',
            }}
          />
          <Typography variant="body1" fontWeight="bold" textAlign="center">
            {endLocationCityName}
          </Typography>

          <Typography
            variant="subtitle2"
            overflow="hidden"
            textOverflow="ellipsis"
            whiteSpace="nowrap"
            textAlign="center"
          >
            {startLocation[language].address}
          </Typography>
          <span />
          <Typography
            variant="subtitle2"
            textOverflow="ellipsis"
            whiteSpace="nowrap"
            textAlign="center"
            overflow="hidden"
          >
            {endLocation[language].address}
          </Typography>

          <Typography variant="subtitle2" textAlign="center">
            {tripPoints[0].hour}
          </Typography>
          <Box
            display="flex"
            gap={0.5}
            alignItems="center"
            justifyContent="center"
          >
            {busFacilities.map((facilityId) => {
              const Facility = BusFacilities.find(
                (facility) => facility.id === facilityId,
              );
              if (!Facility) {
                return null;
              }
              return (
                <Facility.Icon
                  key={facilityId}
                  fontSize="small"
                  color={Facility.color}
                  sx={{
                    width: '0.8em !important',
                    height: '0.8em !important',
                  }}
                />
              );
            })}
          </Box>
          <Typography variant="subtitle2" textAlign="center">
            {tripPoints[tripPoints.length - 1].hour}
          </Typography>

          <DisplayAvailableDays
            gridColumn="span 3"
            availableDays={availableDays}
          />
        </Box>
      </Card>
      {unavailableDays.length ? (
        <Box mt={1}>
          <Alert
            severity="error"
            sx={{
              '& .MuiAlert-message': {
                display: 'flex',
              },
            }}
          >
            <Typography fontSize="12px" pt="2px" pr="2px">
              {t('disabledTripDays.message', {
                days: disabledDaysMessage,
              })}
            </Typography>
          </Alert>
        </Box>
      ) : null}
    </Box>
  );
}
