import { colorsPalette } from '@bus-tickets-app/utils-constants';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import SouthIcon from '@mui/icons-material/South';
import { Button } from '@mui/material';
import styled, { css } from 'styled-components';

export const TripButton = styled(Button)<{
  $type: 'ONE_WAY' | 'ROUND_TRIP';
  size: 'small' | 'large';
}>`
  ${({ $type, size }) =>
    $type === 'ROUND_TRIP' &&
    css`
      background-color: ${colorsPalette.orange} !important;
      border-color: ${colorsPalette.orange} !important;
      height: ${size === 'small' ? '40px' : '60px'};
    `}
`;

export const ReturnTripIcon = styled(SouthIcon)<{ $size: 'small' | 'large' }>`
  font-size: ${({ $size }) =>
    $size === 'small' ? '28px !important' : '38px !important'};
`;

export const OneWayTripIcon = styled(AutorenewIcon)<{
  $size: 'small' | 'large';
}>`
  font-size: ${({ $size }) =>
    $size === 'small' ? '32px !important' : '42px !important'};
`;
