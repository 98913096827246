import { LANGUAGES } from '@bus-tickets-app/utils-constants';
import { useTranslation } from 'react-i18next';

import ArImage from '../../assets/ar-name+logo-colored.png';
import EnImage from '../../assets/en-name+logo-colored.png';

export default function useNameLogoColored() {
  const { i18n } = useTranslation();
  if (i18n.language === LANGUAGES.EN) {
    return EnImage;
  }
  return ArImage;
}
