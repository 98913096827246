import { SearchTicket } from '@bus-tickets-app/utils-constants';
import { useLocation, useNavigate } from 'react-router-dom';

import useSelectedMenuItemContext from '../../../contexts/selectedMenuItem/useSelectedMenuItem';
import computeSearchBooking from '../../../utils/computeSearchBooking';
import { ROUTES } from '../../navigation/routes';

export default function useNavigateToSearchBooking() {
  const navigate = useNavigate();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const { selectedMenuItem, setSelectedMenuItem, setClickedMenuItem } =
    useSelectedMenuItemContext();

  const navigateToSearchBooking = (
    searchTicket: Partial<SearchTicket> & { cityId?: string },
    callback?: () => void,
  ) => {
    if (selectedMenuItem) {
      setSelectedMenuItem(null);
      setClickedMenuItem(null);
    }

    const searchParams = computeSearchBooking(searchTicket, params);

    if (callback) {
      callback();
    }
    navigate({
      pathname: ROUTES.SEARCH_BOOKING.path,
      search: searchParams,
    });
  };

  return navigateToSearchBooking;
}
