import {
  useCurrentCustomer,
  useUpdatePreferedLanguage,
} from '@bus-tickets-app/utils-apollo';
import {
  colorsPalette,
  LANGUAGES,
  LOCAL_STORAGE_LANGUAGE,
  USER_TYPE,
} from '@bus-tickets-app/utils-constants';
import LanguageIcon from '@mui/icons-material/Language';
import { IconButton, MenuItem, type SelectChangeEvent } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { getDefaultLanguage } from '../../../utils/language';
import IrakFlag from './irak-flag.png';
import { StyledSelect } from './styles';
import USFlag from './us-flag.png';

export default function SelectLanguage() {
  const [language, setLanguage] = useState<LANGUAGES>(
    getDefaultLanguage() as LANGUAGES,
  );
  const { i18n } = useTranslation();

  const onUpdatePreferedLanguage = useUpdatePreferedLanguage();

  const { user } = useCurrentCustomer();

  const isCustomer = useMemo(() => {
    return user?.roles[0] === USER_TYPE.CUSTOMER;
  }, [user]);

  useEffect(() => {
    localStorage.setItem(LOCAL_STORAGE_LANGUAGE, language);
    i18n.changeLanguage(language);
    if (user && isCustomer) {
      onUpdatePreferedLanguage(language);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language]);

  const changeLanguage = (event: SelectChangeEvent<unknown>) => {
    setLanguage(event.target.value as LANGUAGES);
  };

  return (
    <StyledSelect
      MenuProps={{ MenuListProps: { style: { padding: 0 } } }}
      value={language}
      onChange={changeLanguage}
      renderValue={() => (
        <IconButton
          size="large"
          sx={{
            color: {
              xs: colorsPalette.white,
              md: colorsPalette.blue,
            },
          }}
        >
          <LanguageIcon />
        </IconButton>
      )}
    >
      <MenuItem key={LANGUAGES.AR} value={LANGUAGES.AR}>
        <img
          src={IrakFlag}
          alt="irak"
          width="25px"
          style={{ borderRadius: '50%', margin: '4px' }}
        />
      </MenuItem>
      <MenuItem key={LANGUAGES.EN} value={LANGUAGES.EN}>
        <img
          src={USFlag}
          alt="us"
          width="25px"
          style={{ borderRadius: '50%', margin: '4px' }}
        />
      </MenuItem>
    </StyledSelect>
  );
}
