import { useGetSearchTicket } from '@bus-tickets-app/utils-apollo';
import {
  APOLLO_ERRORS,
  SearchTicket,
  SearchTicketResponse,
} from '@bus-tickets-app/utils-constants';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import useInitialTicket from '../../searchBooking/useInitialTicket';
import useNavigateToSearchBooking from '../landingScreen/useNavigateToSearchBooking';

export default function useSearchTicketScreen() {
  const setApolloError = useState<null | APOLLO_ERRORS>(null)[1];
  const navigateToSearchBooking = useNavigateToSearchBooking();
  const [searchTicketsResult, setSearchTicketsResult] =
    useState<SearchTicketResponse | null>(null);
  const location = useLocation();
  const params = new URLSearchParams(location.search);

  const [initialTicket] = useInitialTicket();

  const [searchTicket, setSearchTicket] = useState<SearchTicket>(initialTicket);

  useEffect(() => {
    setSearchTicket(initialTicket);
    if (
      initialTicket.startLocationId &&
      initialTicket.endLocationId &&
      initialTicket.numberOfTickets &&
      initialTicket.date
    ) {
      getSearchTicketQuery(initialTicket);
    }
    window.scrollTo({ top: 0 });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialTicket]);

  const getSearchTicketQuery = useGetSearchTicket(
    (searchTicket: SearchTicketResponse) => {
      setSearchTicketsResult(searchTicket);
    },
    setApolloError,
  );

  useEffect(() => {
    if (
      searchTicket.startLocationId &&
      searchTicket.endLocationId &&
      searchTicket.date &&
      searchTicket.numberOfTickets
    ) {
      setApolloError(null);
      getSearchTicketQuery(searchTicket);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSearchTicket = (searchTicket: SearchTicket, callback: () => void) => {
    setSearchTicketsResult(null);
    setSearchTicket(searchTicket);
    params.delete('cityId');
    params.set('startLocationId', searchTicket.startLocationId);
    params.set('endLocationId', searchTicket.endLocationId);
    params.set('date', searchTicket.date);
    params.set('numberOfTickets', `${searchTicket.numberOfTickets}`);
    if (searchTicket.returnDate) {
      params.set('returnDate', searchTicket.returnDate);
    }
    navigateToSearchBooking(searchTicket);
    getSearchTicketQuery(searchTicket, callback);
  };

  return { searchTicket, searchTicketsResult, onSearchTicket };
}
