import { useQuery } from '@apollo/client';
import { DisabledTripDays } from '@bus-tickets-app/utils-constants';

import { GET_DISABLED_TRIPS_DAYS_QUERY } from '../resolvers/disabledTripDays';

export default function useGetDisabledTripsDays() {
  const { data } = useQuery<{
    getDisabledTripsDays: DisabledTripDays[];
  }>(GET_DISABLED_TRIPS_DAYS_QUERY, {
    fetchPolicy: 'cache-and-network',
  });
  return data?.getDisabledTripsDays || [];
}
