import { gql } from '@apollo/client';

export const TRIP_SUBSCRIPTION = gql`
  subscription TripSubscription($input: TripSubscriptionInput!) {
    tripSubscription(input: $input) {
      status
      userId
      availableSeats
    }
  }
`;

export const PAYMENT_SUBSCRIPTION = gql`
  subscription PaymentSubscription($input: PaymentSuscriptionInput!) {
    paymentSubscription(input: $input) {
      status
    }
  }
`;
