import { gql } from '@apollo/client';

import { TICKET_DATA_QUERY } from './ticket';

export const GET_PAYMENT_STATUS = gql`
  query GetPaymentStatus($input: GetPaymentStatusInput) {
    getPaymentStatus(input: $input) {
      status
      tickets {
        directTickets ${TICKET_DATA_QUERY}
        returnTickets ${TICKET_DATA_QUERY}
      }
    }
  }
`;
