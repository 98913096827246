import { useLazyQuery } from '@apollo/client';
import { LOGOUT_USER_QUERY } from '@bus-tickets-app/utils-apollo';
import { LogoutUserResponse } from '@bus-tickets-app/utils-constants';
import { useState } from 'react';

import useNavigateToLogin from '../components/navigation/useNavigateToLogin';
import { removeAuthToken } from '../contexts/auth';

export default function useLogout() {
  const [logoutQuery] = useLazyQuery<LogoutUserResponse>(LOGOUT_USER_QUERY);
  const navigateToLogin = useNavigateToLogin();
  const [isLogoutInProgress, setIsLogoutInProgress] = useState(false);

  const logout = () => {
    setIsLogoutInProgress(true);
    logoutQuery()
      .then()
      .catch()
      .finally(() => {
        removeAuthToken();
        setIsLogoutInProgress(false);
        navigateToLogin();
        window.location.reload();
      });
  };

  return {
    isLogoutInProgress,
    logout,
  };
}
