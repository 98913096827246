/* eslint-disable @typescript-eslint/promise-function-async */
import { useGetLocations } from '@bus-tickets-app/utils-apollo';
import { Box } from '@mui/material';
import { useEffect, useRef } from 'react';

import BusFront from '../../../assets/bus-front.png';
import useIsLandscape from '../../../hooks/useIsLandscape';
import useIsSmallScreen from '../../../hooks/useIsSmallScreen';
import SearchTicketsResult from '../../common/searchTicketsResult';
import SearchBooking from '../../searchBooking';
import { StyledViewTrips } from './styles';
import useSearchTicketScreen from './useSearchTicketScreen';

export default function SearchTicketScreen() {
  const { searchTicket, searchTicketsResult, onSearchTicket } =
    useSearchTicketScreen();
  const { locations } = useGetLocations();
  const containerRef = useRef<HTMLDivElement>(null);
  const resultsRef = useRef<HTMLDivElement | null>();

  const isLandscape = useIsLandscape();
  const isSmallScreen = useIsSmallScreen();

  useEffect(() => {
    if (searchTicketsResult && containerRef?.current && resultsRef?.current) {
      containerRef.current.scroll({
        top: resultsRef.current.offsetTop - window.innerHeight / 2,
        behavior: 'smooth',
      });
    }
  }, [searchTicketsResult]);

  return (
    <Box ref={containerRef} overflow="scroll" height="100vh">
      <Box
        height="100vh"
        width="fill-available"
        my={{
          xs: 9,
          md: isSmallScreen ? 9 : 12,
        }}
        ref={containerRef}
      >
        <Box
          position="relative"
          display="flex"
          alignItems={{
            xs: isLandscape ? 'center' : 'flex-end',
            md: 'center',
          }}
          justifyContent="center"
          flexDirection="column"
          height={{
            xs: isLandscape ? 'calc(100vh - 64px - 8px)' : 'unset',
            md: isSmallScreen ? 'calc(100vh - 64px - 8px)' : '500px',
          }}
          px={2}
          py={{
            xs: isLandscape ? 'unset' : '16px',
            md: isSmallScreen ? 'unset' : '16px',
          }}
          pl={{
            xs: isLandscape ? '50%' : 'unset',
            md: isSmallScreen ? 4 : '40%',
          }}
          sx={{ direction: 'ltr' }}
        >
          <Box
            position="absolute"
            left={0}
            top={0}
            right={0}
            bottom={0}
            sx={{
              backgroundImage: `url(${BusFront})`,
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'contain',
              backgroundPosition: {
                xs: isLandscape ? '15% center' : '-40% center',
                md: isSmallScreen ? '-70% center' : '22% center',
              },
            }}
          />
          <Box
            display="grid"
            gap={{
              xs: isLandscape ? 0.8 : 1,
              md: isSmallScreen ? 0.8 : 3,
            }}
            width={{
              md: '300px',
            }}
            maxWidth={{
              xs: '240px',
              md: '300px',
            }}
          >
            <SearchBooking onSearchTicket={onSearchTicket} />
          </Box>
        </Box>
        <Box ref={resultsRef} pb={4}>
          {searchTicketsResult && (
            <SearchTicketsResult
              data={searchTicketsResult}
              locations={locations}
              searchTicket={searchTicket}
              isCustomer
            />
          )}
          {searchTicketsResult && !searchTicketsResult.trips.length && (
            <StyledViewTrips />
          )}
        </Box>
      </Box>
    </Box>
  );
}
