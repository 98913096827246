import { useMutation } from '@apollo/client';
import { SuccessfulResponse } from '@bus-tickets-app/utils-constants';
import { DELETE_CUSTOMER_DATA_MUTATION } from '../resolvers/customer';
import { useState } from 'react';

export default function useDeleteCustomerData() {
  const [isLoading, setLoading] = useState(false);
  const [apolloError, setApolloError] = useState('');

  const [deleteCustomerDataMutation] = useMutation<SuccessfulResponse>(
    DELETE_CUSTOMER_DATA_MUTATION,
    {
      fetchPolicy: 'network-only',
      onCompleted: () => {
        setLoading(false);
      },
      onError: (error: any) => {
        setApolloError(error.message);
        setLoading(false);
      }
    },
  );

  const deleteCustomerData = () => {
    if (apolloError) {
      setApolloError('');
    }
    setLoading(true);
    deleteCustomerDataMutation();
  }
  
  return {
    deleteCustomerData,
    isLoading,
    apolloError,
  };
}
