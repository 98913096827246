import { colorsPalette } from '@bus-tickets-app/utils-constants';
import { IconButton } from '@mui/material';
import styled from 'styled-components';

import ViewTrips from '../../common/viewTrips/ViewTrips';

export const StyledViewTrips = styled(ViewTrips)`
  margin-left: 5%;
  margin-right: 5%;
  margin-top: 32px;
`;

export const StyledIconButton = styled(IconButton)<{ $selected: boolean }>`
  ${({ $selected }) => `
    border: 0.6vw solid ${
      $selected ? colorsPalette.orange : 'transparent'
    } !important;
  `}
  &:hover {
    border-color: ${colorsPalette.orange} !important;
  }
`;

export const backgroundStyles = {
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  backgroundPosition: {
    md: 'left center',
    xs: '40% center',
  },
  height: '100vh',
  minHeight: {
    md: '50vw',
    xs: 'unset',
  },
};
