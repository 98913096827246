import { useQuery } from '@apollo/client';
import { Location } from '@bus-tickets-app/utils-constants';

import { GET_LOCATIONS_QUERY } from '../resolvers';

export default function useGetLocations() {
  const {
    data,
    loading,
    error,
    refetch,
  } = useQuery<{ getLocationConfigurations: Location[] }>(GET_LOCATIONS_QUERY, {
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
  });
  const locations = data?.getLocationConfigurations || [];

  return {
    locations,
    loading,
    error,
    refetch,
  };
}
