import { useQuery } from '@apollo/client';
import {
  BusForTrip,
  LocationForTrip,
  Trip,
} from '@bus-tickets-app/utils-constants';

import { GET_TRIP_CONFIGURATIONS_QUERY } from '../resolvers';

export default function useGetTrips() {
  const { data, refetch } = useQuery<{
    getTripConfigurations: Trip[];
    getBusConfigurations: BusForTrip[];
    getLocationConfigurations: LocationForTrip[];
  }>(GET_TRIP_CONFIGURATIONS_QUERY);
  const trips = data?.getTripConfigurations || [];
  const buses = data?.getBusConfigurations || [];
  const locations =
    data?.getLocationConfigurations || [];

  return {
    trips,
    buses,
    locations,
    refetch,
  };
}
