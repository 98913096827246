import { DATA_TESTID_EXTERNAL_AUTH } from '@bus-tickets-app/utils-ui';
import FacebookIcon from '@mui/icons-material/Facebook';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
} from '@mui/material';
import { matchIsValidTel, MuiTelInput } from 'mui-tel-input';
import { ChangeEventHandler } from 'react';
import FacebookLogin from 'react-facebook-login';
import { useTranslation } from 'react-i18next';

import useLogoImage from '../../../hooks/images/useNameColoredImage';
import Loading from '../../common/loading';
import ValidationError from '../../validationError';
import useExternalAuth from './useExternalAuth';

export default function ExternalAuth() {
  const { t } = useTranslation();
  const {
    additionalData,
    loading,
    validationError,
    handleFacebookResponse,
    resetAdditionalData,
    onChangeInput,
    onSubmitAdditionalData,
  } = useExternalAuth(matchIsValidTel);
  const LogoImage = useLogoImage();

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      data-testid={DATA_TESTID_EXTERNAL_AUTH}
    >
      <Box display="flex" alignItems="center" justifyContent="center">
        <FacebookLogin
          appId="649749703848032"
          autoLoad={false}
          disableMobileRedirect={true}
          callback={handleFacebookResponse}
          textButton=""
          containerStyle={{}}
          buttonStyle={{
            backgroundColor: 'transparent',
            border: 'none',
            padding: 0,
          }}
          icon={
            <Button
              variant="contained"
              endIcon={<FacebookIcon fontSize="large" />}
              sx={{ gap: 1 }}
            >
              {t('authentication.loginWith')}
            </Button>
          }
          fields="email"
          scope="public_profile,email"
        />
      </Box>
      <ValidationError sx={{ mt: 2 }} validationError={validationError} />
      {loading && <Loading />}
      <Dialog
        PaperProps={{
          sx: {
            width: { xs: 'fill-available', md: '450px' },
            py: 3,
            px: { xs: 2, md: 4 },
          },
        }}
        open={!!additionalData}
      >
        <DialogTitle
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: { xs: 'center', md: 'space-between' },
          }}
        >
          <img src={LogoImage} alt="yalla-bus" width="200px" />
        </DialogTitle>
        <DialogContent
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
            mt: 1,
            pt: '8px !important',
          }}
        >
          <Typography variant="subtitle1">
            {t('authentication.additionalDetails')}
          </Typography>
          {(['email', 'firstName', 'lastName'] as const).map((field) => (
            <TextField
              name={field}
              id={field}
              key={field}
              required
              label={t(`authentication.${field}`)}
              variant="outlined"
              value={additionalData ? additionalData[field] : ''}
              onChange={
                onChangeInput as unknown as ChangeEventHandler<
                  HTMLTextAreaElement | HTMLInputElement
                >
              }
            />
          ))}
          <MuiTelInput
            key="phone"
            required
            label={t('authentication.phone')}
            variant="outlined"
            value={additionalData?.phone || undefined}
            onChange={(value: string) =>
              onChangeInput({
                target: {
                  value,
                  name: 'phone',
                },
              } as unknown as Event & { target: HTMLTextAreaElement })
            }
          />
          <ValidationError validationError={validationError} />
        </DialogContent>
        <DialogActions sx={{ px: 3 }}>
          <Box display="flex" gap={2}>
            <Button
              variant="text"
              onClick={resetAdditionalData}
              disabled={loading}
            >
              {t('cancel')}
            </Button>
            <LoadingButton
              variant="contained"
              onClick={onSubmitAdditionalData}
              loading={loading}
            >
              {t('submit')}
            </LoadingButton>
          </Box>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
