import styled, { keyframes } from 'styled-components';

const enterAnimation = keyframes`
  0% {
    transform: translate(-50%, 0%) scale(-1, 1);
  }

  100% {
    transform: translate(100vw, 0%) scale(-1, 1);
  }
`;

export const AnimatedImage = styled.img`
  transform: scale(-1, 1);
  animation: ${enterAnimation} 4s linear;
  animation-iteration-count: infinite;
`;
