import { gql } from '@apollo/client';
import { LANGUAGES } from '@bus-tickets-app/utils-constants';

const CITY_QUERY = `
  id
  imageLink
  active
  ${LANGUAGES.AR} {
    name
    description
  }
  ${LANGUAGES.EN} {
    name
    description
  }
`;

export const CREATE_CITY_MUTATION = gql`
  mutation CreateCityConfiguration($input: CityConfigurationDataInput!) {
    createCityConfiguration(input: $input) {
      ${CITY_QUERY}
    }
  }
`;

export const EDIT_CITY_MUTATION = gql`
  mutation EditCityConfiguration(
    $cityId: String!
    $input: CityConfigurationDataInput!
  ) {
    editCityConfiguration(cityId: $cityId, input: $input) {
      ${CITY_QUERY}
    }
  }
`;

export const DELETE_CITY_MUTATION = gql`
  mutation DeleteCityConfiguration($cityId: String!) {
    deleteCityConfiguration(cityId: $cityId) {
      id
    }
  }
`;

export const GET_CITIES_QUERY = gql`
  query GetCityConfigurations {
    getCityConfigurations {
      ${CITY_QUERY}
    }
  }
`;