import { getCitiesDictionary, useGetCities } from '@bus-tickets-app/utils-apollo';
import { DISCOUNT_TYPE, LANGUAGES, PeriodDiscount, calculateDiscount } from '@bus-tickets-app/utils-constants';
import { useMemo } from 'react';
import { ShowTripBookingProps } from '../types';

export default function useShowTripBooking(
  language: LANGUAGES,
  tripBookingProps: null | Omit<ShowTripBookingProps, 'numberOfTickets' | 'tripBookingProps'>,
) {
  const { cities, loading } = useGetCities();
  const citiesDictionary = useMemo(() => getCitiesDictionary(cities), [cities]);

  if (loading || !tripBookingProps) {
    return null;
  }

  const {
    startLocation,
    endLocation,
    directTicket,
    returnTicket,
    fidelityDiscount,
    promoDetailCode,
  } = tripBookingProps;


  const startCity = citiesDictionary[startLocation.city];
  const endCity = citiesDictionary[endLocation.city];

  if (!startCity || !endCity) {
    return null;
  }
  const startCityName = startCity[language].name;
  const endCityName = endCity[language].name;

  const directTicketDiscountsApplied = directTicket.discountsApplied || [];
  const returnTicketDiscountsApplied = returnTicket?.discountsApplied || [];

  const firstTripDiscount = directTicketDiscountsApplied.find(
    (discount) => discount.type === DISCOUNT_TYPE.FIRST_TRIP,
  );
  const directTicketPeriodDiscounts = directTicketDiscountsApplied.filter(
    (discount) => discount.type === DISCOUNT_TYPE.PERIOD,
  ) as PeriodDiscount[];
  const returnTicketPeriodDiscounts = returnTicketDiscountsApplied.filter(
    (discount) => discount.type === DISCOUNT_TYPE.PERIOD,
  ) as PeriodDiscount[];

  const returnTripDiscount = returnTicketDiscountsApplied.find(
    (discount) => discount.type === DISCOUNT_TYPE.ROUND_TRIP,
  );

  const directTicketSumAfterDiscount = calculateDiscount(
    directTicket.totalPrice,
    promoDetailCode
      ? [
          ...directTicketDiscountsApplied,
          {
            ...promoDetailCode?.discount,
          },
        ]
      : directTicketDiscountsApplied,
    (fidelityDiscount?.points || 0) / (returnTicket ? 2 : 1),
  );

  const returnTicketSumAfterDiscount = returnTicket ? calculateDiscount(
    returnTicket.totalPrice,
    promoDetailCode
      ? [
          ...returnTicketDiscountsApplied,
          {
            ...promoDetailCode?.discount,
          },
        ]
      : returnTicketDiscountsApplied,
    (fidelityDiscount?.points || 0) / 2,
  ) : 0;

  const sumAfterDiscount = directTicketSumAfterDiscount + returnTicketSumAfterDiscount;

  const appliedDiscountOnDirectTicket =
    (
      !!directTicketDiscountsApplied.length
      || fidelityDiscount
      || promoDetailCode
    ) && directTicket.totalPrice !== directTicketSumAfterDiscount;

  const appliedDiscountOnReturnTicket = 
    (
      !!returnTicketDiscountsApplied.length
      || fidelityDiscount
      || promoDetailCode
    ) && returnTicket && returnTicket.totalPrice !== returnTicketSumAfterDiscount;

  return {
    startCityName,
    endCityName,
    appliedDiscountOnDirectTicket,
    appliedDiscountOnReturnTicket,
    firstTripDiscount,
    directTicketPeriodDiscounts,
    returnTicketPeriodDiscounts,
    returnTripDiscount,
    sumAfterDiscount,
  }
}