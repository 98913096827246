import { TypePolicies, StoreObject } from '@apollo/client';

export const inMemoryCachePolicies: TypePolicies = {
  LocationConfigurationDataSimple: {
    keyFields: ['address'],
  },
  TripData: {
    fields: {
      prices: {
        merge(existing: StoreObject[], incoming: StoreObject[], { readField, mergeObjects }) {
          const merged: any[] = existing ? existing.slice(0) : [];
          const priceToIndex: Record<string, number> = Object.create(null);
          if (existing) {
            existing.forEach((price, index) => {
              const key = `${readField<string>("start", price)}-${readField<string>("end", price)}`;
              priceToIndex[key] = index;
            });
          }
          incoming.forEach(price => {
            const key = `${readField<string>("start", price)}-${readField<string>("end", price)}`;
            const index = priceToIndex[key];
            if (typeof index === "number") {
              // Merge the new author data with the existing author data.
              merged[index] = mergeObjects(merged[index], price);
            } else {
              // First time we've seen this author in this array.
              priceToIndex[key] = merged.length;
              merged.push(price);
            }
          });
          return merged;
        },
      },
      points: {
        merge(existing: StoreObject[], incoming: StoreObject[], { readField, mergeObjects }) {
          const merged: any[] = existing ? existing.slice(0) : [];
          const pointToIndex: Record<string, number> = Object.create(null);
          if (existing) {
            existing.forEach((point, index) => {
              pointToIndex[readField<string>("location", point)!] = index;
            });
          }
          incoming.forEach(point => {
            const name = readField<string>("location", point);
            const index = pointToIndex[name!];
            if (typeof index === "number") {
              // Merge the new author data with the existing author data.
              merged[index] = mergeObjects(merged[index], point);
            } else {
              // First time we've seen this author in this array.
              pointToIndex[name!] = merged.length;
              merged.push(point);
            }
          });
          return merged;
        },
      },
    }
  },
  IdResponse: {
    keyFields: false,
  }
};
