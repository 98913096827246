import { TICKET_STATUS } from '../models'

export enum TRANSACTION_STATUS {
  PENDING = 'PENDING',
  ACCEPTED = 'ACCEPTED',
  EXPIRED = 'EXPIRED',
  FAILED = 'FAILED'
}

export enum ORDER_STATUS {
  ORDER_REGISTERED_BUT_NOT_PAID = 0,
  PREAUTHORIZATION_AMOUNT_WAS_PUT_ON_HOLD = 1,
  AMOUNT_WAS_DEPOSIT_SUCCESSFULLY = 2,
  AUTHORIZATION_HAS_BEEN_REVERSED = 3,
  TRANSACTION_HAS_BEEN_REFUNDED = 4,
  AUTHORIZATION_HAS_BEEN_INITIATED = 5,
  AUTHORIZATION_IS_DECLIENED = 6
}

export const mapOrderToTransactionStatus = (orderStatus?: ORDER_STATUS) => {
  switch (orderStatus) {
    case ORDER_STATUS.ORDER_REGISTERED_BUT_NOT_PAID:
    case ORDER_STATUS.PREAUTHORIZATION_AMOUNT_WAS_PUT_ON_HOLD:
    case ORDER_STATUS.AUTHORIZATION_HAS_BEEN_INITIATED:
      return TRANSACTION_STATUS.PENDING
    case ORDER_STATUS.AMOUNT_WAS_DEPOSIT_SUCCESSFULLY:
      return TRANSACTION_STATUS.ACCEPTED
    case ORDER_STATUS.AUTHORIZATION_HAS_BEEN_REVERSED:
    case ORDER_STATUS.TRANSACTION_HAS_BEEN_REFUNDED:
    case ORDER_STATUS.AUTHORIZATION_IS_DECLIENED:
      return TRANSACTION_STATUS.FAILED
    default:
      return TRANSACTION_STATUS.PENDING
  }
}

export const mapTransactionStatusToTicketStatus = (transactionStatus?: TRANSACTION_STATUS) => {
  switch (transactionStatus) {
    case TRANSACTION_STATUS.PENDING:
      return TICKET_STATUS.PENDING;
    case TRANSACTION_STATUS.ACCEPTED:
      return TICKET_STATUS.BOOKED;
    case TRANSACTION_STATUS.EXPIRED:
      return TICKET_STATUS.PAYMENT_NOT_PROVIDED;
    case TRANSACTION_STATUS.FAILED:
    default:
      return TICKET_STATUS.FAILED_ONLINE_PAYMENT;
    
  }
}

export type InitPaymentResponse = {
  orderId?: string
  formUrl?: string
  errorCode?: number
  errorMessage?: string
}

export enum PAYMENT_SOURCE {
  TABADUL = 'TABADUL',
  EXTERNAL = 'EXTERNAL'
}

export const PAYMENT_CALLBACK = {
  ROUTE: '/payment-callback',
  PARAMS: {
    PAYMENT_SOURCE: 'paymentSource',
    ORDER_ID: 'orderId',
  },
} as const

export const PAYMENT_RESULT = {
  ROUTE: '/payment-result',
  PARAMS: {
    ORDER_ID: 'orderId',
    LANGUAGE: 'language',
  },
} as const

export const UPLOAD_PAYMENT_PROOF = {
  ROUTE: '/upload-payment-proof',
  PARAMS: {
    BOOKING_NAME: 'booking-name',
    FILE: 'file',
  },
} as const

const REGEX_ALOW_ONLY_ALPHA_NUMERIC = /[^a-z0-9]/gi;

export const generateAWSKey = (bookingName: string, clientName: string, clientPhone: string) => {
  return bookingName + '-' + clientName.replace(REGEX_ALOW_ONLY_ALPHA_NUMERIC, '') + '-' + clientPhone.replace(REGEX_ALOW_ONLY_ALPHA_NUMERIC, '')
}

export type ALLOWED_PROOF_IMAGE_FORMAT_TYPE = "image/jpeg" | "image/png" | "image/jpg"

export const ALLOWED_PROOF_IMAGE_FORMAT = ["image/jpeg", "image/png", "image/jpg"]

export const UPLOAD_PAYMENT_PROOF_INTERVAL = 5 * 60 * 1000 // 5 minutes