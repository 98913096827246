import { gql } from '@apollo/client';

const QUERY = `{
  trip
  days
}`;

export const GET_DISABLED_TRIPS_DAYS_QUERY = gql`
  query GetDisabledTripsDays {
    getDisabledTripsDays ${QUERY}
  }
`;

export const GET_DISABLED_TRIP_DAYS_FOR_ALL_TRIPS_QUERY = gql`
  query GetDisabledTripDaysForAllTrips {
    getDisabledTripDaysForAllTrips
  }
`;

export const GET_DISABLED_TRIP_DAYS_FOR_TRIP_QUERY = gql`
  query GetDisabledTripDaysForTrip {
    getDisabledTripDaysForTrip ${QUERY}
  }
`;

export const CONFIGURE_DISABLED_TRIPS_DAYS_MUTATION = gql`
  mutation ConfigureDisabledTripsDays($input: [DisabledTripDaysInput]!) {
    configureDisabledTripsDays(input: $input) {
      error
    }
  }
`;
