import { useQuery } from '@apollo/client';
import {
  GET_FIDELITY_POINTS_DISCOUNT,
  useCurrentCustomer,
} from '@bus-tickets-app/utils-apollo';
import { calculateFidelityDiscountSum } from '@bus-tickets-app/utils-constants';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import { Fab } from '@mui/material';
import { Fragment } from 'react';
import { Outlet, useLocation } from 'react-router-dom';

import { ROUTES } from '../navigation/routes';

export default function RewardPoints() {
  const { user } = useCurrentCustomer();
  const points = user?.points;
  const location = useLocation();
  const { data } = useQuery(GET_FIDELITY_POINTS_DISCOUNT);

  const displayRewardingPoints =
    points && location.pathname !== ROUTES.BOOK_TICKET.path;

  const percentage = data ? data.getFidelityPointsDiscount : 0;
  const ammount =
    displayRewardingPoints && percentage
      ? calculateFidelityDiscountSum(points, percentage)
      : 0;
  return (
    <Fragment>
      {ammount && (
        <Fab
          variant="circular"
          style={{
            cursor: 'auto',
            borderRadius: '50px',
            position: 'absolute',
            bottom: '16px',
            right: '32px',
            zIndex: 2,
            textTransform: 'lowercase',
            opacity: '0.95',
            width: 'auto',
            padding: '0 8px',
          }}
          color="primary"
        >
          <EmojiEventsIcon style={{ marginLeft: '4px' }} />
          <span style={{ marginRight: '4px' }}>{ammount}</span>
        </Fab>
      )}
      <Outlet />
    </Fragment>
  );
}
