import { gql } from '@apollo/client';

export const UPDATE_PREFERED_LANGUAGE_MUTATION = gql`
  mutation UpdatePreferedLanguage($language: String!) {
    updatePreferedLanguage(language: $language) {
      error
    }
  }
`;

export const DELETE_CUSTOMER_DATA_MUTATION = gql`
  mutation DeleteCustomerData {
    deleteCustomerData {
      error
    }
  }
`;