import { useMutation } from '@apollo/client';
import { EXTERNAL_AUTH_MUTATION } from '@bus-tickets-app/utils-apollo';
import {
  APOLLO_ERRORS,
  DEFAULT_PHONE_PREFIX,
  EMAIL_MATCH,
  ExternalAuthRequest,
  ExternalAuthResponse,
} from '@bus-tickets-app/utils-constants';
import * as Sentry from '@sentry/browser';
import { useState } from 'react';
import { ReactFacebookLoginInfo } from 'react-facebook-login';
import { useTranslation } from 'react-i18next';

import { setAuthToken } from '../../../contexts/auth';
import { ExternalAuthInput } from './types';

const defaultAdditionalData: ExternalAuthInput = {
  firstName: '',
  lastName: '',
  phone: DEFAULT_PHONE_PREFIX,
  email: '',
  userId: '',
  authMethod: 'FB',
};

export default function useExternalAuth(
  matchIsValidTel: (text: string) => boolean,
) {
  const { t } = useTranslation();
  const [validationError, setValidationError] = useState('');
  const [loading, setLoading] = useState(false);
  const [additionalData, setAdditionalData] = useState<
    undefined | ExternalAuthInput
  >(undefined);
  const [externalResponse, setExternalResponse] = useState<
    Pick<ExternalAuthInput, 'email' | 'userId' | 'authMethod'> | undefined
  >(undefined);

  const resetAdditionalData = () => setAdditionalData(undefined);

  const [externalAuthMutation] = useMutation<
    ExternalAuthResponse,
    ExternalAuthRequest
  >(EXTERNAL_AUTH_MUTATION, {
    onCompleted: ({ externalAuth: { accessToken } }) => {
      setLoading(false);
      if (accessToken) {
        setAuthToken(accessToken);
        return;
      }
      setAdditionalData({
        ...defaultAdditionalData,
        email: externalResponse!.email,
        userId: externalResponse!.userId,
        authMethod: externalResponse!.authMethod,
      });
      setExternalResponse(undefined);
    },
    onError: (error) => {
      setLoading(false);
      setValidationError(`apolloErrors.${error.message}`);
    },
  });

  const handleFacebookResponse = async (response: ReactFacebookLoginInfo) => {
    if (validationError) {
      setValidationError('');
    }

    if (!response.userID) {
      Sentry.captureMessage('facebook response: ' + JSON.stringify(response));
      setValidationError(t(`apolloErrors.${APOLLO_ERRORS.UNKNOWN_ERROR}`));
      return;
    }

    setLoading(true);
    const userID = response.userID;
    const email = response.email || '';

    setExternalResponse({
      email,
      userId: userID,
      authMethod: 'FB',
    });
    externalAuthMutation({
      variables: {
        input: {
          email,
          userId: userID,
          authMethod: 'FB',
        },
      },
    });
  };

  const onChangeInput = (event: Event & { target: HTMLTextAreaElement }) => {
    if (validationError) {
      setValidationError('');
    }
    const data = {
      ...additionalData!,
      [event.target.name]: event.target.value,
    };
    setAdditionalData(data);
  };

  const onSubmitAdditionalData = () => {
    if (!additionalData) {
      return;
    }

    const missingKey = (
      ['email', 'firstName', 'lastName', 'phone'] as const
    ).find((key) => !additionalData?.[key]);

    if (missingKey) {
      setValidationError(
        t('validations.missingField', {
          field: t(`authentication.${missingKey}`),
        })!,
      );
      return;
    }

    if (!new RegExp(EMAIL_MATCH).test(additionalData.email)) {
      setValidationError(t(`apolloErrors.${APOLLO_ERRORS.INVALID_EMAIL}`)!);
      return;
    }

    if (!matchIsValidTel(additionalData.phone!)) {
      setValidationError(
        t('validations.invalidField', { field: t('manageUsers.user.phone') })!,
      );
      return;
    }

    externalAuthMutation({
      variables: {
        input: additionalData!,
      },
    });
  };

  return {
    validationError,
    loading,
    additionalData,
    setValidationError,
    handleFacebookResponse,
    resetAdditionalData,
    onChangeInput,
    onSubmitAdditionalData,
  };
}
