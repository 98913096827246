import { useMutation } from '@apollo/client';
import { SIGN_UP_CUSTOMER } from '@bus-tickets-app/utils-apollo';
import {
  APOLLO_ERRORS,
  CustomerSignUpRequest,
  CustomerSignUpResponse,
  DEFAULT_PHONE_PREFIX,
  LANGUAGES,
  PASSWORD_MIN_LENGTH,
  PASSWORD_SHOULD_MATCH,
} from '@bus-tickets-app/utils-constants';
import { useEffect, useState } from 'react';

import { SignUpUser, SIGN_UP_FIELS } from './types';

const defaultUser: SignUpUser = {
  firstName: '',
  lastName: '',
  phone: DEFAULT_PHONE_PREFIX,
  email: '',
  password: '',
  passwordConfirm: '',
  points: 0,
  hasFirstTrip: false,
  preferredLanguage: LANGUAGES.AR,
};

export default function useSignUp(
  t: any,
  language: LANGUAGES,
  onReceiveVerifyEmail: () => void,
  matchIsValidTel?: (text: string) => boolean,
) {
  const [user, setUser] = useState<SignUpUser>(defaultUser);
  const [isModalOpen, setOpenModal] = useState(false);
  const [validationError, setValidationError] = useState('');
  const [isLoading, setLoading] = useState(false);

  const [signUpCustomerMutation] = useMutation<
    CustomerSignUpResponse,
    CustomerSignUpRequest
  >(SIGN_UP_CUSTOMER, {
    onCompleted: ({ signUpUser: { error } }) => {
      setLoading(false);
      if (error) {
        setValidationError(t(`apolloErrors.${APOLLO_ERRORS.UNKNOWN_ERROR}`)!);
        return;
      }
      closeModal();
      onReceiveVerifyEmail();
      return;
    },
    onError: (error: any) => {
      setLoading(false);
      setValidationError(t(`apolloErrors.${error.message}`)!);
    },
  });

  useEffect(() => {
    setUser(defaultUser);
    setValidationError('');
  }, [isModalOpen]);

  const openModal = () => setOpenModal(true);
  const closeModal = () => setOpenModal(false);

  const onChangeUser = (key: string, value: string) => {
    if (validationError) {
      setValidationError('');
    }
    if (key === 'email') {
      const updatedUser = {
        ...user,
        [key]: value.trim(),
      };
      setUser(updatedUser);
      return;
    }
    const updatedUser = {
      ...user,
      [key]: value,
    };
    setUser(updatedUser);
  };

  const signUp = () => {
    const missingField = SIGN_UP_FIELS.find(
      (field) => field.required && user[field.key].length === 0,
    );
    if (missingField) {
      setValidationError(
        t('validations.missingField', {
          field: t(`authentication.${missingField.key}`),
        })!,
      );
      return;
    }

    const invalidField = SIGN_UP_FIELS.find(
      (field) =>
        user[field.key] &&
        field.validation &&
        !new RegExp(field.validation).test(user[field.key]!),
    );
    if (invalidField) {
      setValidationError(
        t('validations.invalidField', {
          field: t(`authentication.${invalidField.key}`),
        })!,
      );
      return;
    }
  
    if (matchIsValidTel && !matchIsValidTel(user.phone)) {
      setValidationError(t('validations.invalidPhone')!);
      return;
    }
    
    if (!new RegExp(PASSWORD_SHOULD_MATCH).test(user.password)) {
      setValidationError(t(`apolloErrors.${APOLLO_ERRORS.INVALID_PASSWORD}`)!);
      return;
    }

    if (user.password.length < PASSWORD_MIN_LENGTH) {
      setValidationError(
        t(`apolloErrors.${APOLLO_ERRORS.INVALID_PASSWORD_LENGTH}`)!,
      );
      return;
    }

    if (user.password !== user.passwordConfirm) {
      setValidationError(
        t(`apolloErrors.${APOLLO_ERRORS.INVALID_PASSWORD_CONFIRMATION}`)!,
      );
      return;
    }
    setLoading(true);
    signUpCustomerMutation({
      variables: {
        input: {
          ...user,
          preferredLanguage: language,
        },
      },
    });
  };

  return {
    isModalOpen,
    user,
    validationError,
    isLoading,
    openModal,
    closeModal,
    signUp,
    onChangeUser,
  };
}
