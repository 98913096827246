import { getTripStatusOnLastBooking, APOLLO_ERRORS, BookTicketInformation, BookTicketInformationResponse, DATE_FORMAT_SERVER, Discount, TICKET_STATUS, TIME_STATUS, EXPIRE_TIME } from '@bus-tickets-app/utils-constants';
import dayjs from 'dayjs';

export const getBookTicketDetails = ({
  ticket,
  ticketInformation,
  apolloError,
  availableSeatsOnTrip,
  availableSeatsOnReturnTrip,
  paymentLink,
  firstTripDiscount,
}: {
  ticket: BookTicketInformation,
  ticketInformation: BookTicketInformationResponse,
  apolloError: APOLLO_ERRORS | null,
  availableSeatsOnTrip: number,
  availableSeatsOnReturnTrip: number,
  paymentLink: string | null,
  firstTripDiscount: Discount | undefined
}) => {
  const tripTimeStatus = getTripStatusOnLastBooking(
    ticket.directTrip.date,
    ticketInformation.directTicket.startTripTime,
  );
  const isLastMinuteBookings = tripTimeStatus === TIME_STATUS.LAST_MINUTE;
  const isPastBooking =
    tripTimeStatus === TIME_STATUS.PAST ||
    apolloError === APOLLO_ERRORS.TRIP_PASSED;

  const areSeatsAvailable =
    availableSeatsOnTrip >= ticket.numberOfTickets &&
    (ticket.returnTrip
      ? availableSeatsOnReturnTrip >= ticket.numberOfTickets
      : true);
  const nrOfAvailableSeats = ticket.returnTrip
    ? Math.min(availableSeatsOnTrip, availableSeatsOnReturnTrip)
    : availableSeatsOnTrip;

  const showPayButtons = !paymentLink && areSeatsAvailable;
  const showRemainingSeats = showPayButtons && nrOfAvailableSeats < 10;
  const showFirstPeriodDiscount = !paymentLink && !!firstTripDiscount;

  return {
    isLastMinuteBookings,
    isPastBooking,
    showRemainingSeats,
    showFirstPeriodDiscount,
    areSeatsAvailable,
    showPayButtons,
    nrOfAvailableSeats,
  }
};

export const isInvalidTicket = (
  date: string,
  startTime: string, // 'format hh:mm A'
  status: TICKET_STATUS
) => {
  if (status === TICKET_STATUS.FAILED_ONLINE_PAYMENT) {
    return true;
  }
  if (status === TICKET_STATUS.PAYMENT_NOT_PROVIDED) {
    return true;
  }

  const currentDate = dayjs(new Date()).format(DATE_FORMAT_SERVER);
  
  if (date === currentDate) {
    const currentTime =  dayjs(new Date()).format('hh:mm A');

    const ticketParams = startTime.split(/[\s:]+/)
    const currentParams = currentTime.split(/[\s:]+/)

    let ticketHour = Number.parseInt(ticketParams[0]);
    if (ticketParams[2] === 'PM') {
      ticketHour += 12;
    }

    let currentHour = Number.parseInt(currentParams[0]);
    if (currentParams[2] === 'PM' && currentHour !== 12) {
      currentHour += 12;
    }

    if (ticketHour < currentHour) {
      return true;
    }

    const ticketMinutes = Number.parseInt(ticketParams[1]);
    const currentMinutes = Number.parseInt(currentParams[1]);

    if (60 * ticketHour + ticketMinutes - 60 * currentHour + currentMinutes < EXPIRE_TIME.LAST_MINUTE_BOOKING_TO_PURCHASE / 1000 / 60) {
      return true;
    }
    return false;
  }

  return date < currentDate;
}
