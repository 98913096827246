export enum EXPIRE_TIME {
  BOOKING_TO_PAY = 1000 * 60 * 60, // 60 minutes
  LAST_MINUTE_BOOKING_TO_PAY = 1000 * 60 * 30, // 30 minutes
  LAST_MINUTE_BOOKING_TO_PURCHASE = 1000 * 60 * 60 * 2, // 1 hours
  CANCELATION_POLICY = 1000 * 60 * 60 * 2, // 2 hours
}

export enum TIME_STATUS {
  PAST = 'past',
  FUTURE = 'future',
  LAST_MINUTE = 'last-minute',
}
