import { SearchTicket } from '@bus-tickets-app/utils-constants';
import { defaultSearchTicket } from '@bus-tickets-app/utils-ui';
import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import useSearchParamsTripDetails from '../../hooks/useSearchParamsTrip';

export default function useInitialTicket(): [SearchTicket, string | null] {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const cityId = params.get('cityId');

  const urlParamsTripDetails = useSearchParamsTripDetails();

  const defaultStartLocationId =
    urlParamsTripDetails.startLocationId || defaultSearchTicket.startLocationId;
  const defaultEndLocationId =
    urlParamsTripDetails.endLocationId || defaultSearchTicket.endLocationId;
  const defaultDate =
    urlParamsTripDetails.directTrip.date || defaultSearchTicket.date;
  const defaultReturnDate =
    urlParamsTripDetails.returnTrip?.date || defaultSearchTicket.returnDate;
  const defaultNumberOfTickets =
    urlParamsTripDetails.numberOfTickets || defaultSearchTicket.numberOfTickets;

  const searchTicket: SearchTicket = useMemo(
    () => ({
      startLocationId: defaultStartLocationId,
      endLocationId: defaultEndLocationId,
      date: defaultDate,
      numberOfTickets: defaultNumberOfTickets,
      returnDate: defaultReturnDate,
    }),
    [
      defaultStartLocationId,
      defaultEndLocationId,
      defaultDate,
      defaultNumberOfTickets,
      defaultReturnDate,
    ],
  );
  return [searchTicket, cityId];
}
