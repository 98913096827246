import * as React from 'react';

interface State {
  inView: boolean;
  entry?: IntersectionObserverEntry;
}

export type ObserverInstanceCallback = (
  inView: boolean,
  entry: IntersectionObserverEntry,
) => void;

export default function useObservable(options: IntersectionObserverInit = {}) {
  const [ref, setRef] = React.useState<Element | null>(null);
  const [state, setState] = React.useState<State>({
    inView: false,
    entry: undefined,
  });

  const { root, rootMargin, threshold } = options;

  React.useEffect(
    () => {
      if (!ref) {
        return;
      }

      const elements = new Map<Element, ObserverInstanceCallback>();

      let thresholds: number[] | readonly number[] = [];

      const observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          const inView =
            entry.isIntersecting &&
            thresholds.some(
              (threshold) => entry.intersectionRatio >= threshold,
            );

          // support IntersectionObserver v2
          if (
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-expect-error
            options.trackVisibility &&
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-expect-error
            typeof entry.isVisible === 'undefined'
          ) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-expect-error
            entry.isVisible = inView;
          }

          const callback = elements.get(entry.target);
          if (callback) {
            callback(inView, entry);
          }
        });
      }, options);

      thresholds =
        observer.thresholds ||
        (Array.isArray(options.threshold)
          ? options.threshold
          : [options.threshold || 0]);

      elements.set(ref, (inView, entry) => {
        setState({
          inView,
          entry,
        });
      });
      observer.observe(ref);

      return function () {
        if (!elements.get(ref)) {
          elements.delete(ref);
          observer.unobserve(ref);
        }

        if (elements.size === 0) {
          // No more elements are being observer by this instance, so destroy it
          observer.disconnect();
          // observerMap.current.delete(id);
        }
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      // eslint-disable-next-line react-hooks/exhaustive-deps
      Array.isArray(threshold) ? threshold.toString() : threshold,
      ref,
      root,
      rootMargin,
    ],
  );

  const entryTarget = state.entry?.target;
  const previousEntryTarget = React.useRef<Element>();
  if (!ref && entryTarget && previousEntryTarget.current !== entryTarget) {
    previousEntryTarget.current = entryTarget;
    setState({
      inView: false,
      entry: undefined,
    });
  }

  return {
    ref: setRef,
    inView: state.inView,
    entry: state.entry,
  };
}
