import { useQuery } from '@apollo/client';
import { Discount } from '@bus-tickets-app/utils-constants';

import { GET_ALL_DISCOUNTS_QUERY } from '../resolvers';

export default function useGetAllDiscounts(keys: Array<keyof Discount>) {
  const {
    data: getAllDiscountsQueryData,
    loading,
    refetch,
  } = useQuery<{ getAllDiscounts: Discount[] }>(
    GET_ALL_DISCOUNTS_QUERY(`{ ${keys.join()} }`),
    {
      fetchPolicy: 'cache-first',
    },
  );
  const discounts = getAllDiscountsQueryData?.getAllDiscounts || [];

  return {
    discounts,
    loading,
    refetch,
  };
}
