import { LANGUAGES, translations } from '@bus-tickets-app/utils-constants';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import { getDefaultLanguage } from './utils/language';

void i18n.use(initReactI18next).init({
  compatibilityJSON: 'v3',
  fallbackLng: LANGUAGES.AR,
  lng: getDefaultLanguage(),
  resources: translations,
});

export default i18n;
