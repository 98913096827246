import { useCurrentCustomer } from '@bus-tickets-app/utils-apollo';
import { Customer as CustomerType } from '@bus-tickets-app/utils-constants';
import {
  CurrentCustomerContext,
  VerifyEmailContext,
} from '@bus-tickets-app/utils-ui';
import { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';

import VerifyEmail from '../../auth/verifyEmail';
import Loading from '../../common/loading/Loading';

export default function CustomerProvider() {
  const [currentCustomer, setCurrentCustomer] = useState<CustomerType | null>(
    null,
  );
  const { user, loading } = useCurrentCustomer();
  const [needToVerifyEmail, setNeedToVerifyEmail] = useState<boolean | null>(
    null,
  );

  useEffect(() => {
    if (user) {
      const {
        id,
        firstName,
        lastName,
        email,
        phone,
        roles,
        points = 0,
        preferredLanguage,
      } = user;
      setCurrentCustomer({
        id,
        firstName,
        lastName,
        email,
        phone,
        roles,
        points,
        hasFirstTrip: false,
        preferredLanguage,
      });
      return;
    }
    setCurrentCustomer(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  return (
    <CurrentCustomerContext.Provider
      value={{ currentCustomer, setCurrentCustomer }}
    >
      <VerifyEmailContext.Provider
        value={{ needToVerifyEmail, setNeedToVerifyEmail }}
      >
        {loading ? <Loading primaryBackground /> : <Outlet />}
        <VerifyEmail />
      </VerifyEmailContext.Provider>
    </CurrentCustomerContext.Provider>
  );
}
