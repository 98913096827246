export enum APOLLO_ERRORS {
  LOGIN_REQUIRED = 'User is not login. Please refresh and login again.',
  INVALID_EMAIL = 'Please provide a valid email.',
  INVALID_PASSWORD_LENGTH = 'Password should not be less than 8 characters.',
  INVALID_PASSWORD = 'Password invalid.',
  INVALID_PASSWORD_CONFIRMATION = 'Please confirm your password.',
  INVALID_MATCH_PASSWORD = 'Passwords do not match.',
  CURRENT_PASSWORD_INCORRECT = 'Current password does not match this user password.',
  NO_RESET_PASSWORD_TOKEN_FOUND =  'No reset password token found.',
  EMAIL_NOT_VERIFIED = 'Email not verified.',

  CLIENT_NAME_AND_PHONE_ARE_REQUIRED = 'Client name and phone are required.',
  REQUIRE_ADMIN_PERMISSIONS = 'User does not have ADMIN permissions.',
  REQUIRE_OPERATOR_PERMISSIONS = 'User does not have OPERATOR permissions.',
  PERFORM_ACTION_ON_ADMIN_DENIED = 'You cannot perform this action on an ADMIN user.',
  USERNAME_ALREADY_EXIST = 'Username already exists.',
  EMAIL_ALREADY_EXIST = 'Email already exists.',
  INVALID_EMAIL_OR_PASSWORD = 'Invalid email or password.',
  INVALID_USERNAME_OR_PASSWORD = 'Invalid username or password.',
  FAILED_TO_SEND_VERIFICATION_EMAIL = 'Failed to send verification email.',
  FAILED_TO_ACTIVATE_ACCOUNT = 'Failed to activate account.',

  SESSION_EXPIRED = 'User session has expired.',
  REFRESH_ACCESS_TOKEN = 'Could not refresh access token.',
  NO_USER_FOUND = 'User does not exist.',
  NO_BUS_FOUND = 'Bus does not exist.',
  NO_CITY_FOUND = 'City does not exist.',  
  NO_LOCATION_FOUND = 'Location does not exist.',
  NO_RETURN_DATE = 'No return date specified.',
  NO_RETURN_TRIP = 'No return trip specified.',
  NO_TRIP_FOUND = 'Trip does not exist.',
  NO_TICKET_FOUND = 'Ticket does not exist.',
  TICKET_HAS_NO_BEEN_PAYED = 'Ticket has not been payed.',
  TICKET_DATE_EXPIRED = 'Ticket date has expired.',
  TRIP_PASSED = 'The departure time for the trip has passed.',
  NO_DISCOUNT_FOUND = 'No discount found.',
  CANNOT_EDIT_DELETED_DISCOUNT = 'Cannot edit a deleted discount.',
  PROMO_CODE_NOT_FOUND = 'Promo code not found.',
  PROMO_CODE_REQUIRE_AUTH = 'Promo code requires authentication.',
  PROMO_CODE_EXCEED_USE_TIME = 'Promo code has exceeded its use time.',

  INVALID_PAYMENT_RESULT_PARAMS = 'Invalid payment return callback params.',
  CANNOT_FIND_PAYMENT_ASSOCIATED_WITH_THIS_ORDER = 'Cannot find payment associated with this order.',
  CANNOT_GET_PAYMENT_STATUS = 'Cannot get payment status.',
  WAITING_FOR_PAYMENT = 'Waiting for payment.',
  PAYMENT_SUCCESSFULLY_REGISTERED = 'Payment successfully registered.',
  PAYMENT_CANCELED = 'Payment was canceled.',
  TRIP_ASSOCIATED = 'There is a trip associated with this configuration.',
  LOCATION_ASSOCIATED = 'There is a location associated with this configuration.',
  INVALID_PHONE_NUMBER = 'Invalid phone number',

  UNABLE_INITIATE_ONLINE_PAYMENT = 'Unable to initiate online payment.',
  UNABLE_TO_SEND_EMAIL = 'Failed to send the email.',
  UNABLE_TO_SEND_PHONE_MESSAGE = 'Failed to send the phone message.',

  INVALID_BOOKING_NAME = 'There are not pending bookings associated to this booking name.',
  
  CONTOR_NOT_SET = 'There is no contor set in the database',
  RECEIPT_NOT_FOUND = 'Cannot find receipt',
  
  SEATS_HAVE_BEEN_BOOKED_MEANWHILE = 'Seats have been booked meanwhile. Please select other seats.',
  SEATS_UNAVAILABLE = 'Seats have been booked meanwhile. There are no more seats available for the number of tickets selected.',
  NO_DUPLICATED_ENTRIES_ALLOWED = 'No duplicated entries are allowed.',
  UNKNOWN_ERROR = 'There was an unexpected error. Please try again or contact support.',
}
