export const LOGIN_FIELDS = [
  {
    key: 'email',
    required: true,
    validation: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
    type: undefined,
  },
  {
    key: 'password',
    required: true,
    validation: null,
    type: 'password',
  },
] as const;