import { gql } from '@apollo/client';
import { LANGUAGES } from '@bus-tickets-app/utils-constants';

const QUERY_TRIP = `
  id
  busConfigurationId
  points {
    location
    hour
  }
  prices {
    start
    end
    cost
  }
  availableDays
`;

export const GET_TRIP_CONFIGURATIONS_QUERY = gql`
  query GetTripConfigurations {
    getTripConfigurations {
      ${QUERY_TRIP}
    }

    getBusConfigurations {
      id
      configurationName
      facilities
    }

    getLocationConfigurations {
      id
      city
      ${LANGUAGES.AR} {
        address
      }
      ${LANGUAGES.EN} {
        address
      }
    }
  }
`;

export const CREATE_TRIP_MUTATION = gql`
  mutation CreateTripConfiguration($input: TripDataInput!) {
    createTripConfiguration(input: $input) {
      ${QUERY_TRIP}
    }
  }
`;

export const EDIT_TRIP_MUTATION = gql`
  mutation EditTripConfiguration($tripId: String!, $input: TripDataInput!) {
    editTripConfiguration(tripId: $tripId, input: $input) {
      ${QUERY_TRIP}
    }
  }
`;

export const DELETE_TRIP_MUTATION = gql`
  mutation DeleteTripConfiguration($tripId: String!) {
    deleteTripConfiguration(tripId: $tripId) {
      id
    }
  }
`;
