import { APOLLO_ERRORS } from '../errors/apolloErrors'
import { DAY } from '../models'

export function getWeekDay(date: Date) {
  const dayNumber = date.getDay()
  switch (dayNumber) {
    case 1:
      return DAY.MONDAY
    case 2:
      return DAY.TUESDAY
    case 3:
      return DAY.WEDNESDAY
    case 4:
      return DAY.THURSDAY
    case 5:
      return DAY.FRIDAY
    case 6:
      return DAY.SATURDAY
    case 0:
    case 7:
      return DAY.SUNDAY
  }
  throw new Error(APOLLO_ERRORS.UNKNOWN_ERROR)
}

export const DATE_FORMAT = 'DD MMM YYYY'
export const DATE_FORMAT_SERVER = 'YYYY/MM/DD'
export const DATE_FORMAT_DETAILS = 'ddd, DD MMM YYYY'
