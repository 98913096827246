import { useMemo } from 'react';

import { BookingSummaryProps } from './types';
import { LANGUAGES } from '@bus-tickets-app/utils-constants';

export default function useBookingSummary(
  language: LANGUAGES,
  {
    locations,
    startLocationId,
    endLocationId,
    startLocationCityName,
    endLocationCityName,
    trip,
    returnTrip,
    nrOfPassangers,
    date,
    returnDate
  }: BookingSummaryProps
) {
  const startLocation = locations.find(
    (location) => location.id === startLocationId,
  );
  const endLocation = locations.find(
    (location) => location.id === endLocationId,
  );

  const ammount = useMemo(() => {
    let ammount = 0;
    if (trip) {
      ammount +=
        trip.prices.find(
          (price) =>
            price.start === startLocationId && price.end === endLocationId,
        )?.cost || 0;
    }
    if (returnTrip) {
      ammount +=
        returnTrip.prices.find(
          (price) =>
            price.end === startLocationId && price.start === endLocationId,
        )?.cost || 0;
    }
    return ammount;
  }, [trip, returnTrip, startLocationId, endLocationId]);

  const totalAmount = ammount * nrOfPassangers;

  const startTripHours = useMemo(() => {
    return (
      trip?.points.find((point) => point.location === startLocationId)?.hour ||
      ''
    );
  }, [startLocationId, trip?.points]);

  const returnStartTripHours = useMemo(() => {
    return (
      returnTrip?.points.find((point) => point.location === endLocationId)
        ?.hour || ''
    );
  }, [endLocationId, returnTrip?.points]);

  const getTripInfo = (isReturnTrip: boolean) => {
    const startCityTripName = isReturnTrip
      ? endLocationCityName
      : startLocationCityName;
    const startLocationTripAddress = isReturnTrip
      ? endLocation
        ? endLocation[language].address
        : ''
      : startLocation
        ? startLocation[language].address
        : '';
    const endCityTripName = isReturnTrip
      ? startLocationCityName
      : endLocationCityName;
    const endLocationTripAddress = isReturnTrip
      ? startLocation
        ? startLocation[language].address
        : ''
      : endLocation
        ? endLocation[language].address
        : '';
    const tripDate = isReturnTrip ? returnDate! : date;
    const tripHour = isReturnTrip ? returnStartTripHours : startTripHours;

    return {
      startCityTripName,
      startLocationTripAddress,
      endCityTripName,
      endLocationTripAddress,
      tripDate,
      tripHour,
    }
  };

  return {
    totalAmount,
    getTripInfo,
  }
}