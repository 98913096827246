import { useQuery } from '@apollo/client';
import { Customer, noop } from '@bus-tickets-app/utils-constants';

import { GET_CURRENT_CUSTOMER_QUERY } from '../resolvers';

export default function useCurrentCustomer(network?: boolean): {
  user: Customer | undefined;
  loading: boolean;
  refetch: () => void;
} {
  const { data, refetch, loading } = useQuery(GET_CURRENT_CUSTOMER_QUERY, {
    fetchPolicy: network ? 'cache-and-network' : 'cache-first',
    onError: (error: any) => {
      noop();
    },
  });

  const user = (data?.getMe as Customer) || undefined;
  return { user, refetch, loading };
}
