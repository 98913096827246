import { Alert } from '@mui/material';

import ValidationErrorProps from './types';

export default function ValidationError({
  validationError,
  sx,
  ...rest
}: ValidationErrorProps) {
  if (!validationError) {
    return null;
  }
  const prettyError =
    validationError
      .toLowerCase()
      .split('.')
      .filter((sentence) => sentence)
      .map((sentence) => sentence.trim())
      .map((sentence) => sentence[0].toUpperCase() + sentence.slice(1))
      .join('. ') + '.';

  return (
    <Alert {...rest} severity="error" sx={{ width: 'fill-available', ...sx }}>
      {prettyError}
    </Alert>
  );
}
