import { LANGUAGES } from '@bus-tickets-app/utils-constants';
import {
  DATA_TESTID_SIGN_UP_BUTTON,
  DATA_TESTID_SIGN_UP_CANCEL,
  DATA_TESTID_SIGN_UP_ERROR,
  DATA_TESTID_SIGN_UP_MODAL,
  DATA_TESTID_SIGN_UP_SUBMIT,
  SIGN_UP_FIELS,
  SignUpProps,
  useSignUp,
  useVerifyEmailContext,
} from '@bus-tickets-app/utils-ui';
import InsertEmoticonIcon from '@mui/icons-material/InsertEmoticon';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
} from '@mui/material';
import { matchIsValidTel, MuiTelInput } from 'mui-tel-input';
import { ChangeEventHandler, Fragment, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import useLogoImage from '../../../hooks/images/useNameColoredImage';
import ValidationError from '../../validationError';
import RequestPasswordReset from '../clientLogin/requestPasswordReset';
import ExternalAuth from '../externalAuth';

export default function SignUp({ className, withIcon }: SignUpProps) {
  const { t, i18n } = useTranslation();
  const language = i18n.language as LANGUAGES;
  const LogoImage = useLogoImage();
  const { setNeedToVerifyEmail } = useVerifyEmailContext();

  const onReceiveVerifyEmail = () => setNeedToVerifyEmail(true);

  const {
    user,
    isModalOpen,
    validationError,
    isLoading,
    openModal,
    closeModal,
    signUp,
    onChangeUser,
  } = useSignUp(t, language, onReceiveVerifyEmail, matchIsValidTel);

  const onChangeInput = (event: Event & { target: HTMLTextAreaElement }) => {
    onChangeUser(event.target.name, event.target.value);
  };

  const style = useMemo(() => {
    if (language === LANGUAGES.AR) {
      return { ml: 4 };
    }
    return { mr: 4 };
  }, [language]);

  return (
    <Fragment>
      <Button
        className={className}
        sx={{ width: 'max-content' }}
        variant="text"
        data-testid={DATA_TESTID_SIGN_UP_BUTTON}
        onClick={openModal}
      >
        {withIcon && <InsertEmoticonIcon sx={style} />}
        <span>{t('authentication.signUp')}</span>
      </Button>
      <Dialog
        PaperProps={{
          sx: {
            width: { xs: 'fill-available', md: '450px' },
            py: 3,
            px: { xs: 2, md: 4 },
            pointerEvents: isLoading ? 'none' : 'auto',
          },
        }}
        onClose={isLoading ? undefined : closeModal}
        open={isModalOpen}
        data-testid={DATA_TESTID_SIGN_UP_MODAL}
      >
        <DialogTitle
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: { xs: 'center', md: 'space-between' },
          }}
        >
          <Typography
            variant="h5"
            sx={{ display: { xs: 'none', md: 'block' } }}
          >
            {t('authentication.signUpTitle')}
          </Typography>
          <img src={LogoImage} alt="yalla-bus" width="200px" />
        </DialogTitle>
        <DialogContent
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
            mt: 1,
            pt: '8px !important',
          }}
        >
          {SIGN_UP_FIELS.map((field) => {
            if (field.key === 'phone') {
              return (
                <MuiTelInput
                  key="phone"
                  required
                  label={t('authentication.phone')}
                  variant="outlined"
                  value={user.phone || undefined}
                  onChange={(value: string) =>
                    onChangeInput({
                      target: {
                        value,
                        name: 'phone',
                      },
                    } as unknown as Event & { target: HTMLTextAreaElement })
                  }
                  inputProps={{
                    'data-testid': `dataTestId-${field.key}`,
                  }}
                />
              );
            }
            return (
              <TextField
                name={field.key}
                id={field.key}
                key={field.key}
                type={field.type}
                required={field.required}
                label={t(`authentication.${field.key}`)}
                variant="outlined"
                value={user[field.key]}
                onChange={
                  onChangeInput as unknown as ChangeEventHandler<
                    HTMLTextAreaElement | HTMLInputElement
                  >
                }
                inputProps={{
                  'data-testid': `dataTestId-${field.key}`,
                }}
              />
            );
          })}
          <ValidationError
            data-testid={DATA_TESTID_SIGN_UP_ERROR}
            validationError={validationError}
          />
          <ExternalAuth />
          <RequestPasswordReset />
        </DialogContent>
        <DialogActions sx={{ px: 3 }}>
          <Box display="flex" gap={2}>
            <Button
              sx={{ float: 'right' }}
              variant="text"
              disabled={isLoading}
              data-testid={DATA_TESTID_SIGN_UP_CANCEL}
              onClick={closeModal}
            >
              {t('cancel')}
            </Button>
            <LoadingButton
              sx={{ float: 'right' }}
              variant="contained"
              onClick={signUp}
              loading={isLoading}
              data-testid={DATA_TESTID_SIGN_UP_SUBMIT}
            >
              {t('submit')}
            </LoadingButton>
          </Box>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
}
