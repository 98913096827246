import { useLazyQuery } from '@apollo/client';
import {
  APOLLO_ERRORS,
  DATE_FORMAT_SERVER,
  SearchTicket,
  SearchTicketResponse,
} from '@bus-tickets-app/utils-constants';
import dayjs from 'dayjs';

import { GET_SEARCH_TICKET_QUERY } from '../resolvers';

export default function useGetSearchTicket(
  onCompleted: (searchTicket: SearchTicketResponse) => void,
  onError: (error: APOLLO_ERRORS) => void,
) {
  const [getSearchTicketLazyQuery] = useLazyQuery<
    { getSearchTickets: SearchTicketResponse },
    { input: SearchTicket }
  >(GET_SEARCH_TICKET_QUERY, {
    onCompleted: ({ getSearchTickets }) => onCompleted(getSearchTickets),
    onError: (error) => {
      onError(error.message as APOLLO_ERRORS);
    },
    fetchPolicy: 'network-only',
  });

  const getSearchTicketQuery = (
    searchTicket: SearchTicket,
    callback?: () => void,
  ) => {
    getSearchTicketLazyQuery({
      variables: {
        input: {
          startLocationId: searchTicket.startLocationId,
          endLocationId: searchTicket.endLocationId,
          numberOfTickets: searchTicket.numberOfTickets,
          date: dayjs(searchTicket.date).format(DATE_FORMAT_SERVER),
          returnDate: searchTicket.returnDate
            ? dayjs(searchTicket.returnDate).format(DATE_FORMAT_SERVER)
            : undefined,
        },
      },
    })
      .then((result) => {
        if (callback) {
          callback();
        }
        if (!result.data) {
          if (result.error) {
            onError(result.error.message as APOLLO_ERRORS);
            return;
          }
          onError(APOLLO_ERRORS.UNKNOWN_ERROR);
          return;
        }
        onCompleted(result.data.getSearchTickets);
      })
      .catch(() => {
        if (callback) {
          callback();
        }
        onError(APOLLO_ERRORS.UNKNOWN_ERROR);
      });
  };

  return getSearchTicketQuery;
}
