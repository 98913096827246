import { useSubscription } from '@apollo/client';
import { TRANSACTION_STATUS } from '@bus-tickets-app/utils-constants';
import { PAYMENT_SUBSCRIPTION } from '../resolvers';

export default function usePaymentSubscription(
  transactionOrderId: string | undefined,
  callback: () => void,
) {
  useSubscription<
    { paymentSubscription: { status: TRANSACTION_STATUS } },
    { input: { transactionOrderId: string } }
  >(PAYMENT_SUBSCRIPTION, {
    variables: {
      input: {
        transactionOrderId: transactionOrderId!,
      },
    },
    skip: !transactionOrderId,
    onData: callback,
  });
}
