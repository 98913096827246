import { LANGUAGES } from './supportedLanguages'
import enTranslations from './en.json'
import arTranslations from './ar.json'

export * from './supportedLanguages'

const translations = {
  [LANGUAGES.EN]: {
    translation: enTranslations,
  },
  [LANGUAGES.AR]: {
    translation: arTranslations,
  },
} as const

export { translations }
