import {
  getCitiesDictionary,
  useGetCities,
  useGetLocations,
} from '@bus-tickets-app/utils-apollo';
import { colorsPalette, LANGUAGES } from '@bus-tickets-app/utils-constants';
import PlaceIcon from '@mui/icons-material/Place';
import { Box, Card, Chip, Link, Typography } from '@mui/material';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import useIsLandscape from '../../../hooks/useIsLandscape';
import ContactUsCard from '../../contactUsCard';

export default function Contact() {
  const { t, i18n } = useTranslation();
  const language = i18n.language as LANGUAGES;
  const isLandscape = useIsLandscape();
  const { cities, loading: citiesAreLoading } = useGetCities();
  const { locations, loading: locationsAreLoading } = useGetLocations();
  const citiesDictionary = useMemo(() => getCitiesDictionary(cities), [cities]);

  const loading = citiesAreLoading || locationsAreLoading;
  if (loading) {
    return null;
  }

  return (
    <Box>
      <Box
        sx={{
          backgroundColor: colorsPalette.blue,
          boxShadow: `0px 2px 4px -1px ${colorsPalette.blue}80, 0px 4px 5px 0px ${colorsPalette.blue}86, 0px 1px 10px 0px ${colorsPalette.blue}88`,
        }}
        display="flex"
        alignItems="center"
        justifyContent="center"
        gap={2}
        py={2}
        px={4}
      >
        <Typography textAlign="center" color="white" variant="h4">
          {t('contact.title')}
        </Typography>
        <PlaceIcon fontSize="large" sx={{ color: 'white' }} />
      </Box>
      <Box
        display="grid"
        gridTemplateColumns={{
          xs: isLandscape ? '1fr 1r' : '1fr',
          md: '1fr 1fr',
        }}
        gap={{
          xs: isLandscape ? 4 : 2,
          md: 4,
        }}
        mx={{
          xs: isLandscape ? 8 : 4,
          md: 8,
        }}
        my={4}
      >
        {locations.map((location) => {
          const city = citiesDictionary[location.city];
          if (!city) {
            return null;
          }
          const cityName = city[language].name;
          const address = location[language].address;
          const openSchedule = location[language].openSchedule;
          const { contactPhone, contactEmail } = location;

          return (
            <Card
              key={location.id}
              sx={{
                py: 4,
                px: { xs: isLandscape ? 4 : 2, md: 4 },
                display: 'flex',
                gap: 4,
                cursor: 'default',
                position: 'relative',
                opacity: '0.5',
              }}
            >
              <Box
                sx={{
                  backgroundImage: `url(${
                    citiesDictionary[location.city].imageLink
                  })`,
                  backgroundRepeat: 'no-repeat',
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
                  height: '150px',
                  width: '150px',
                  borderRadius: '16%',
                }}
              />
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="center"
                width={{ xs: 'min-content', md: 'fit-content' }}
              >
                <Typography variant="h5" fontWeight="bold">
                  {cityName}
                </Typography>
                <Typography variant="h6" fontWeight="bold">
                  <Link
                    target="_blank"
                    rel="noopener"
                    underline="hover"
                    href={location.locationLink}
                  >
                    {address}
                  </Link>
                </Typography>
                <span>
                  {openSchedule && <Typography>{openSchedule}</Typography>}
                </span>
                <span>
                  {contactPhone && (
                    <Typography>
                      <Link href={`tel:${contactPhone}`}>{contactPhone}</Link>
                    </Typography>
                  )}
                </span>
                <span>
                  {contactEmail && (
                    <Typography>
                      <Link href={`mailto:${contactEmail}`}>
                        {contactEmail}
                      </Link>
                    </Typography>
                  )}
                </span>
              </Box>
              <Chip
                sx={{
                  position: 'absolute',
                  top: 8,
                  right: language === LANGUAGES.AR ? 'unset' : 8,
                  left: language === LANGUAGES.AR ? 8 : 'unset',
                }}
                label={t('soon')}
                color="secondary"
              />
            </Card>
          );
        })}
      </Box>
      <Box display="flex" justifyContent="center">
        <ContactUsCard />
      </Box>
    </Box>
  );
}
