import { DISCOUNT_TYPE, Discount } from "../models";

export const calculateDiscount = (sum: number, discounts: Discount[], fidelityPoints: number) => {
  let fidelityPointsAmount = fidelityPoints
  const remainingSum = discounts.reduce((sum: number, discount: Discount) => {
    if (discount.type === DISCOUNT_TYPE.FIDELITY_POINTS) {
      fidelityPointsAmount = calculateFidelityDiscountSum(fidelityPoints, discount.percentage)
      return sum
    }
    sum -= sum * discount.percentage / 100
    return sum
  }, sum)

  if (remainingSum - fidelityPointsAmount < 0) {
    return 0
  }
  return Math.round((remainingSum - fidelityPointsAmount) * 100) / 100;
}

export const calculateFidelityDiscountSum = (points: number, percentage: number) => {
  return Math.round((points * percentage / 100) * 100) / 100;
}

export const dedupDiscounts = (
  directTicketDiscounts?: Discount[],
  returnTicketDiscounts?: Discount[],
) => {
  let discounts = [...(directTicketDiscounts || [])];
  if (returnTicketDiscounts) {
    const returnDiscounts = returnTicketDiscounts.filter(
      (testDiscount) =>
        discounts.findIndex((discount) => discount.id === testDiscount.id) ===
        -1,
    );
    discounts = [...discounts, ...returnDiscounts];
  }
  return discounts;
};
