import { EMAIL_MATCH, NAME_MATCH } from '@bus-tickets-app/utils-constants';

export const EDIT_PROFILE_USER_FIELDS = [
  {
    key: 'firstName',
    required: true,
    validation: NAME_MATCH,
    type: undefined,
  },
  {
    key: 'lastName',
    required: true,
    validation: NAME_MATCH,
    type: undefined,
  },
  {
    key: 'email',
    required: true,
    validation: EMAIL_MATCH,
    type: undefined,
  },
  {
    key: 'phone',
    required: true,
    validation: null,
    type: undefined,
  },
] as const;

export interface EditiProfileData {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
}

export interface EditProfileProps {
  isOpen: boolean;
  close: () => void;
}
