import { colorsPalette } from '@bus-tickets-app/utils-constants';
import styled from 'styled-components';

import TripRoad from '../../tripRoad';

export const StyledTripRoad = styled(TripRoad)`
  cursor: pointer;
  position: relative;

  &:hover {
    box-shadow:
      0px 2px 4px -1px ${colorsPalette.orange}80,
      0px 2px 5px 0px ${colorsPalette.orange}86,
      0px 1px 10px 0px ${colorsPalette.orange}88 !important;
  }
`;
