import { setContext } from '@apollo/client/link/context';
import { ACCESS_TOKEN_KEY } from '@bus-tickets-app/utils-constants';

export const setAuthToken = (
  accessToken: string,
  options: undefined | { reload: boolean } = { reload: true },
) => {
  setContext(() => ({
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  }));
  localStorage.setItem(ACCESS_TOKEN_KEY, accessToken);
  if (options.reload) {
    window.location.reload();
  }
};

export const removeAuthToken = () => {
  setContext(() => ({
    headers: {
      authorization: '',
    },
  }));
  localStorage.removeItem(ACCESS_TOKEN_KEY);
};
