import PolicyIcon from '@mui/icons-material/Policy';
import { Box, IconButton, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import useNameLogoColored from '../../hooks/images/useNameLogoColored';
import { ROUTES } from '../navigation/routes';

export default function Footer() {
  const { t } = useTranslation();
  const NameLogo = useNameLogoColored();
  const navigate = useNavigate();

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      my={2}
      gap={2}
      sx={{ direction: 'ltr' }}
    >
      <img
        src={NameLogo}
        alt="Yalla Bus"
        width="200px"
        style={{ marginLeft: '14px' }}
      />
      <Box>
        <Typography variant="caption">{t('policy.copyright')}</Typography>
        <IconButton
          sx={{ borderRadius: '4px', marginLeft: '16px' }}
          onClick={() => navigate(ROUTES.PUBLIC_ROUTE_POLICY.path)}
        >
          <PolicyIcon />
          <Typography variant="caption" fontSize={10} ml={0.5}>
            {t('policy.title')}
          </Typography>
        </IconButton>
      </Box>
    </Box>
  );
}
