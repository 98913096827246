export enum TICKET_STATUS {
  BOOKED = 'BOOKED',
  CANCELLED = 'CANCELLED',
  PENDING = 'PENDING',
  FAILED_ONLINE_PAYMENT = 'FAILED_ONLINE_PAYMENT',
  PAYMENT_NOT_PROVIDED = 'PAYMENT_NOT_PROVIDED',
  INVALID_PROOF = 'INVALID_PROOF', 
}

export type TicketType = 'DIRECT_TRIP' | 'RETURN_TRIP'

export interface Ticket {
  id: string;
  tripId: string;
  seatNumber: number;
  userId: string;
  startLocationId: string;
  endLocationId: string;
  date: string;
  bookingName: string;
  clientDetails: ClientDetails;
  status: TICKET_STATUS;
  price: number;
  priceAfterDiscount: number;
  discounts: string[];
  transactionOrder?: string;
  selfPurchased: boolean;
  soldInLocation?: string;
  returnedAmount?: number;
  returnTicket: string;
  isReturnTicket?: boolean;
  receipt?: string;
}

export type ClientDetails = {
  name: string;
  phone: string;
  email?: string;
}

export type GroupedTicketsAfterBookingName = {
  _id: {
    bookingName: string
    customerName: string
    customerPhone: string
    date: string
    startLocationId: string
    endLocationId: string
    tripId: string
    status: TICKET_STATUS
    transactionOrder: string
  }
  tickets: Array<{
    _id: string
    seatNumber: number
    price: number
    priceAfterDiscount: number
  }>
}