import { NOTIFICATION_TIMEOUT } from '@bus-tickets-app/utils-constants';
import {
  DATA_TESTID_EDIT_PROFILE_TOAST,
  EDIT_PROFILE_USER_FIELDS,
  EditProfileProps,
  useEditProfile,
} from '@bus-tickets-app/utils-ui';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from '@mui/material';
import { matchIsValidTel, MuiTelInput } from 'mui-tel-input';
import { ChangeEventHandler, Fragment, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import useLogoImage from '../../../hooks/images/useNameColoredImage';
import Loading from '../../common/loading/Loading';
import Notification from '../../notification';
import ValidationError from '../../validationError';

export default function EditProfile({ isOpen, close }: EditProfileProps) {
  const { t } = useTranslation();
  const LogoImage = useLogoImage();

  const {
    user,
    userHasBeenUpdated,
    validationError,
    isLoading,
    onChange,
    editProfile,
    onCloseNotification,
  } = useEditProfile(t, isOpen, close, matchIsValidTel);

  useEffect(() => {
    if (userHasBeenUpdated) {
      setTimeout(() => {
        onCloseNotification();
      }, NOTIFICATION_TIMEOUT);
    }
  }, [onCloseNotification, userHasBeenUpdated]);

  const onChangeField = (event: Event & { target: HTMLTextAreaElement }) => {
    onChange(event.target.name, event.target.value);
  };

  return (
    <Fragment>
      <Dialog
        PaperProps={{ sx: { width: 'fill-available', p: 3 } }}
        onClose={close}
        open={isOpen}
      >
        <DialogTitle
          sx={{
            mt: 2,
            mx: 2,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          {t('customer.editProfile')}
          <img
            src={LogoImage}
            alt="yalla-bus"
            width="100px"
            style={{ margin: '4px' }}
          />
        </DialogTitle>
        <DialogContent
          sx={{
            position: 'relative',
            display: 'flex',
            flexDirection: 'column',
            gap: 3,
            m: 2,
            mt: 2,
            pt: '8px !important',
          }}
        >
          {isLoading && <Loading />}
          {EDIT_PROFILE_USER_FIELDS.map((field) => {
            if (field.key === 'phone') {
              return (
                <MuiTelInput
                  required
                  key="phone"
                  label={t('authentication.phone')}
                  variant="outlined"
                  value={user.phone || undefined}
                  onChange={(value: string) =>
                    onChangeField({
                      target: {
                        value,
                        name: 'phone',
                      },
                    } as unknown as Event & { target: HTMLTextAreaElement })
                  }
                />
              );
            }
            return (
              <TextField
                name={field.key}
                id={field.key}
                key={field.key}
                type={field.type}
                required={field.required}
                label={t(`authentication.${field.key}`)}
                variant="outlined"
                value={user[field.key]}
                disabled={field.key === 'email'}
                onChange={
                  onChangeField as unknown as ChangeEventHandler<
                    HTMLTextAreaElement | HTMLInputElement
                  >
                }
              />
            );
          })}
          <ValidationError validationError={validationError} />
        </DialogContent>
        <DialogActions sx={{ mx: 4 }}>
          <Button sx={{ mr: 2, float: 'right' }} variant="text" onClick={close}>
            {t('cancel')}
          </Button>
          <Button
            sx={{ float: 'right' }}
            variant="contained"
            onClick={editProfile}
          >
            {t('submit')}
          </Button>
        </DialogActions>
      </Dialog>
      <Notification
        dataTestId={DATA_TESTID_EDIT_PROFILE_TOAST}
        open={userHasBeenUpdated}
        severity="success"
        onClose={onCloseNotification}
      >
        {t('customer.updatedSuccessfully')}
      </Notification>
    </Fragment>
  );
}
