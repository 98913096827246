import { useGetLocations } from '@bus-tickets-app/utils-apollo';
import { APOLLO_ERRORS, SearchTicket } from '@bus-tickets-app/utils-constants';
import useSearchBookingValidation from './useSearchBookingValidation';
import { useEffect, useState } from 'react';

export default function useSearchBooking(
  t: any,
  initialTicket: SearchTicket,
  cityId: string | null | undefined,
  onSearchTicket: (searchTicket: SearchTicket, callback: () => void) => void,
) {
  const [apolloError, setApolloError] = useState<null | APOLLO_ERRORS>(null);
  const [searchInProgress, setSearchInProgress] = useState(false);
  const {
    locations,
    error: getLocationConfigurationsQueryDataError,
    loading: loadingLocations,
  } = useGetLocations();

  const [hasReturnTicket, setReturnTicket] = useState(
    !!initialTicket.returnDate,
  );

  const [searchTicket, setSearchTicket] = useState<SearchTicket>(initialTicket);

  const onValidateTicket = useSearchBookingValidation(t);

  useEffect(() => {
    setSearchTicket(initialTicket);
  }, [initialTicket]);

  const [validationError, setValidationError] = useState('');

  useEffect(() => {
    if (hasReturnTicket) {
      setSearchTicket({
        ...searchTicket,
        returnDate: initialTicket.returnDate,
      });
      return;
    }
    const { returnDate, ...ticket } = searchTicket;
    setSearchTicket(ticket);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasReturnTicket]);

  useEffect(() => {
    if (cityId && !loadingLocations) {
      const startLocation = locations.find(
        (location) => location.city === cityId,
      );
      if (!startLocation) {
        return;
      }
      setSearchTicket({
        ...searchTicket,
        startLocationId: startLocation.id,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingLocations]);

  useEffect(() => {
    if (apolloError) {
      setValidationError(t(`apolloErrors.${apolloError}`)!);
      return;
    }
    setValidationError('');
  }, [apolloError, t]);

  useEffect(() => {
    if (getLocationConfigurationsQueryDataError) {
      setApolloError(
        getLocationConfigurationsQueryDataError.message as APOLLO_ERRORS,
      );
    }
  }, [getLocationConfigurationsQueryDataError]);

  const onClickSearchTicket = () => {
    const invalidTicket = onValidateTicket(searchTicket, hasReturnTicket);
    if (invalidTicket) {
      setValidationError(invalidTicket);
      return;
    }
    setSearchInProgress(true);
    onSearchTicket(searchTicket, () => setSearchInProgress(false));
  };

  return {
    searchTicket,
    locations,
    validationError,
    hasReturnTicket,
    searchInProgress,
    setReturnTicket,
    setValidationError,
    onClickSearchTicket,
    setSearchTicket,
  };
}
