import { colorsPalette, LANGUAGES } from '@bus-tickets-app/utils-constants';
import {
  ListItem as MUIListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import ListItemProps from './types';

export default function ListItem({ icon, text, onClick }: ListItemProps) {
  const { i18n } = useTranslation();
  const language = i18n.language as LANGUAGES;

  const style = useMemo(() => {
    if (language === LANGUAGES.AR) {
      return { pl: 2, textAlign: 'right' };
    }
    return { pr: 2, textAlign: 'left' };
  }, [language]);

  return (
    <MUIListItem
      disablePadding
      sx={{ color: colorsPalette.blue }}
      onClick={onClick}
    >
      <ListItemButton
        sx={{ px: 3, py: 1.2, cursor: onClick ? 'pointer' : 'default' }}
      >
        <ListItemIcon sx={{ color: colorsPalette.blue }}>{icon}</ListItemIcon>
        <ListItemText primary={text} sx={style as any} />
      </ListItemButton>
    </MUIListItem>
  );
}
