import { Box, Chip } from '@mui/material';
import { useTranslation } from 'react-i18next';

import DisplayAvailableDaysProps from './types';

export default function DisplayAvailableDays({
  availableDays,
  ...rest
}: DisplayAvailableDaysProps) {
  const { t } = useTranslation();

  return (
    <Box
      display="flex"
      gap={1}
      alignItems="center"
      justifyContent="flex-end"
      overflow="auto"
      {...rest}
    >
      {availableDays.map((day) => (
        <Chip
          key={day}
          label={t(`days.${day}`)}
          color="secondary"
          sx={{
            '& .MuiChip-label': {
              fontSize: 'smaller',
            },
          }}
          size="small"
        />
      ))}
    </Box>
  );
}
