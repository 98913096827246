import {
  colorsPalette,
  LANGUAGES,
  TicketType,
} from '@bus-tickets-app/utils-constants';
import {
  SearchTicketsResultProps,
  useSearchBookingResult,
} from '@bus-tickets-app/utils-ui';
import CloseFullscreenIcon from '@mui/icons-material/CloseFullscreen';
import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Box, Button, Card, IconButton, Snackbar, Tab } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { ROUTES } from '../../navigation/routes';
import BookingSummary from '../bookingSummary';
import DisplayTicketsResult from '../displayTicketsResult';

export default function SearchTicketsResult({
  data,
  locations,
  searchTicket,
  isCustomer,
}: SearchTicketsResultProps) {
  const [isCollapsedBookingSummary, setCollapsedBookingSummary] =
    useState(false);

  const { startLocationId, endLocationId, date, returnDate, numberOfTickets } =
    searchTicket;
  const { t, i18n } = useTranslation();
  const language = i18n.language as LANGUAGES;

  const {
    ticketType,
    startLocationCityName,
    endLocationCityName,
    selectedTrip,
    selectedReturnTrip,
    enableProceedToBooking,
    showTicketsSummary,
    setTicketType,
    setSelectedTrip,
    setSelectedReturnTrip,
  } = useSearchBookingResult(
    language,
    locations,
    startLocationId,
    endLocationId,
    !!returnDate,
  );

  const proceedToBookingLink = isCustomer
    ? ROUTES.BOOK_TICKET.path
    : ROUTES.OPERATOR_PANEL_ROUTE_BOOKING.path;

  const proceedToBookingButton = enableProceedToBooking ? (
    <Link
      to={{
        pathname: proceedToBookingLink,
        search: `?tripId=${selectedTrip?.id}${
          returnDate
            ? `&returnTripId=${selectedReturnTrip?.id}&returnDate=${returnDate}`
            : ''
        }&startLocationId=${startLocationId}&endLocationId=${endLocationId}&date=${date}&numberOfTickets=${numberOfTickets}`,
      }}
    >
      <Button
        disabled={!enableProceedToBooking}
        sx={{ float: 'right' }}
        variant="contained"
      >
        {t('tickets.proceedToBooking')}
      </Button>
    </Link>
  ) : (
    <Button
      disabled={!enableProceedToBooking}
      sx={{ float: 'right' }}
      variant="contained"
    >
      {t('tickets.proceedToBooking')}
    </Button>
  );

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="flex-start"
      gap={2}
      mx={{ xs: 2, md: 8 }}
      my={2}
    >
      <TabContext value={ticketType}>
        <TabList
          onChange={(_event: React.SyntheticEvent, ticketType: TicketType) =>
            setTicketType(ticketType)
          }
        >
          <Tab
            id="DIRECT_TRIP"
            label={`${startLocationCityName} - ${endLocationCityName}`}
            value="DIRECT_TRIP"
          />
          {returnDate && (
            <Tab
              id="RETURN_TRIP"
              label={`${endLocationCityName} - ${startLocationCityName}`}
              value="RETURN_TRIP"
            />
          )}
        </TabList>
        <TabPanel
          value={ticketType}
          sx={{ padding: 0, width: 'fill-available' }}
        >
          <DisplayTicketsResult
            data={ticketType === 'DIRECT_TRIP' ? data : data.returnTrip!}
            isCustomer={isCustomer}
            locations={locations}
            startLocationId={
              ticketType === 'DIRECT_TRIP' ? startLocationId : endLocationId
            }
            endLocationId={
              ticketType === 'DIRECT_TRIP' ? endLocationId : startLocationId
            }
            date={ticketType === 'DIRECT_TRIP' ? date : returnDate!}
            numberOfTickets={numberOfTickets}
            selectedTrip={
              ticketType === 'DIRECT_TRIP' ? selectedTrip : selectedReturnTrip
            }
            setSelectedTrip={
              ticketType === 'DIRECT_TRIP'
                ? setSelectedTrip
                : setSelectedReturnTrip
            }
          />
        </TabPanel>
      </TabContext>
      <Box
        height={
          showTicketsSummary
            ? isCollapsedBookingSummary
              ? '64px'
              : '180px'
            : 0
        }
      />
      <Snackbar
        open={showTicketsSummary}
        sx={{
          left: '24px !important',
          right: '24px !important',
          mb: 1,
          zIndex: 1000,
          bottom: {
            md: 8,
            xs: 1,
          },
        }}
      >
        <Card
          sx={{ width: 'fill-available', position: 'relative' }}
          elevation={5}
        >
          {isCollapsedBookingSummary ? (
            <Box
              display="flex"
              justifyContent="center"
              px={4}
              py={2}
              sx={{
                opacity: 0.8,
                backgroundColor: colorsPalette.orange,
                color: 'white',
              }}
            >
              {proceedToBookingButton}
            </Box>
          ) : (
            <BookingSummary
              locations={locations}
              startLocationId={startLocationId}
              endLocationId={endLocationId}
              startLocationCityName={startLocationCityName}
              endLocationCityName={endLocationCityName}
              date={searchTicket.date}
              returnDate={searchTicket.returnDate}
              trip={selectedTrip}
              returnTrip={selectedReturnTrip}
              nrOfPassangers={searchTicket.numberOfTickets}
              proceedToBookingButton={
                enableProceedToBooking ? proceedToBookingButton : undefined
              }
            />
          )}
          <IconButton
            aria-label="collapse"
            color="primary"
            sx={{
              p: 0.5,
              position: 'absolute',
              left: language === LANGUAGES.AR ? '8px' : 'unset',
              right: language === LANGUAGES.AR ? 'unset' : '8px',
              top: '8px',
            }}
            onClick={() =>
              setCollapsedBookingSummary(!isCollapsedBookingSummary)
            }
          >
            {isCollapsedBookingSummary ? (
              <OpenInFullIcon fontSize="small" />
            ) : (
              <CloseFullscreenIcon fontSize="small" />
            )}
          </IconButton>
        </Card>
      </Snackbar>
    </Box>
  );
}
