import { useState } from 'react';
import { Outlet } from 'react-router-dom';

import { SECTIONS } from '../../../contexts/selectedMenuItem/constants';
import SelectedMenuItemContext from '../../../contexts/selectedMenuItem/SelectedMenuItemContext';

export default function SelectedMenuItemProvider() {
  const [selectedMenuItem, setSelectedMenuItem] = useState<SECTIONS | null>(
    SECTIONS.BOOKING,
  );
  const [clickedMenuItem, setClickedMenuItem] = useState<SECTIONS | null>(
    SECTIONS.BOOKING,
  );

  return (
    <SelectedMenuItemContext.Provider
      value={{
        selectedMenuItem,
        clickedMenuItem,
        setSelectedMenuItem,
        setClickedMenuItem,
      }}
    >
      <Outlet />
    </SelectedMenuItemContext.Provider>
  );
}
