import { colorsPalette, LANGUAGES } from '@bus-tickets-app/utils-constants';
import { useCurrentCustomerContext } from '@bus-tickets-app/utils-ui';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import {
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem as MUIListItem,
} from '@mui/material';
import { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import useLogo from '../../hooks/images/useLogo';
import useNameImage from '../../hooks/images/useNameImage';
import useLogout from '../../hooks/useLogout';
import Loading from '../common/loading/Loading';
import ChangePassword from '../navigation/changePassword';
import { ROUTES } from '../navigation/routes';
import EditProfile from './editProfile/EditProfile';
import ListItem from './menu/listItem';
import { CUSTOMER_OPTION, customerOptions } from './types';

export default function Customer() {
  const { t, i18n } = useTranslation();
  const language = i18n.language as LANGUAGES;
  const NameImage = useNameImage();
  const LogoImage = useLogo();
  const [userOption, setUserOption] = useState<CUSTOMER_OPTION | null>(null);
  const [isDrawerOpened, setIsDrawerOpened] = useState(false);
  const { currentCustomer: user } = useCurrentCustomerContext();
  const { isLogoutInProgress, logout } = useLogout();
  const navigate = useNavigate();

  const openDrawer = () => setIsDrawerOpened(true);
  const closeDrawer = () => setIsDrawerOpened(false);
  const resetUserOption = () => setUserOption(null);
  const closeChangePasswordDialog = () => {
    resetUserOption();
  };

  useEffect(() => {
    if (userOption === CUSTOMER_OPTION.LOGOUT) {
      logout();
      return;
    }
    if (userOption === CUSTOMER_OPTION.MY_BOOKINGS) {
      resetUserOption();
      navigate(ROUTES.MY_BOOKINGS.path);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userOption]);

  if (!user) {
    return null;
  }

  return (
    <Fragment>
      <IconButton size="large" onClick={openDrawer} sx={{ p: 0 }}>
        <AccountCircleIcon
          sx={{
            color: {
              xs: colorsPalette.white,
              md: colorsPalette.blue,
            },
          }}
        />
      </IconButton>
      <Drawer
        anchor={language === LANGUAGES.AR ? 'right' : 'left'}
        open={isDrawerOpened}
        onClose={closeDrawer}
      >
        <List sx={{ pt: 0 }}>
          <MUIListItem
            sx={{
              boxShadow:
                '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)',
              backgroundColor: colorsPalette.orange,
              py: 1.9,
              gap: 3,
              justifyContent: 'center',
            }}
          >
            <img src={LogoImage} alt="yalla-bus" width="18px" />
            <img src={NameImage} alt="yalla-bus" width="150px" />
          </MUIListItem>
          <Divider sx={{ borderWidth: '1px' }} />

          <ListItem
            key="user"
            icon={<AccountCircleIcon sx={{ color: colorsPalette.blue }} />}
            text={`${user.firstName} ${user.lastName}`}
          />
          <Divider sx={{ borderWidth: '1px' }} />
          {customerOptions.map((option) => (
            <ListItem
              key={option.key}
              text={t(`customer.${option.value}`)}
              icon={option.icon}
              onClick={() => {
                setUserOption(option.key);
                closeDrawer();
              }}
            />
          ))}
        </List>
      </Drawer>
      <EditProfile
        isOpen={userOption === CUSTOMER_OPTION.EDIT_PROFILE}
        close={resetUserOption}
      />
      <ChangePassword
        isOpen={userOption === CUSTOMER_OPTION.CHANGE_PASSWORD}
        close={closeChangePasswordDialog}
      />
      {isLogoutInProgress && <Loading />}
    </Fragment>
  );
}
