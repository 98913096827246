import {
  getCitiesDictionary,
  useGetCities,
  useGetDisabledTripsDays,
  useGetTrips,
} from '@bus-tickets-app/utils-apollo';
import { Box } from '@mui/material';
import { useMemo } from 'react';

import useIsSmallScreen from '../../../hooks/useIsSmallScreen';
import useNavigateToSearchBooking from '../../customerScreens/landingScreen/useNavigateToSearchBooking';
import Loading from '../loading/Loading';
import { StyledTripRoad } from './styles';
import ViewTripsProps from './types';

export default function ViewTrips({ className }: ViewTripsProps) {
  const navigateToSearchBooking = useNavigateToSearchBooking();
  const disabledTripsDays = useGetDisabledTripsDays();

  const { cities, loading: citiesAreLoading } = useGetCities();
  const citiesDictionary = useMemo(() => getCitiesDictionary(cities), [cities]);
  const isSmallScreen = useIsSmallScreen();

  const { trips, locations, buses } = useGetTrips();

  if (citiesAreLoading) {
    return <Loading />;
  }

  return (
    <Box
      display="grid"
      gridTemplateColumns={isSmallScreen ? '1fr' : '1fr 1fr'}
      gap={4}
      className={className}
    >
      {trips.map((trip) => {
        const disabledTripDays =
          disabledTripsDays.find((entity) => entity.trip === trip.id)?.days ||
          [];
        const startPointLocation =
          citiesDictionary[
            locations.find(
              (location) => location.id === trip.points[0].location,
            )!.city
          ];
        const endPointLocation =
          citiesDictionary[
            locations.find(
              (location) =>
                location.id === trip.points[trip.points.length - 1].location,
            )!.city
          ];
        const bus = buses.find((bus) => bus.id === trip.busConfigurationId);
        const busFacilities = bus ? bus.facilities : [];

        if (!startPointLocation || !endPointLocation) {
          return null;
        }

        return (
          <StyledTripRoad
            key={trip.id}
            trip={trip}
            locations={locations}
            busFacilities={busFacilities}
            onClick={() =>
              navigateToSearchBooking({
                startLocationId: trip.points[0].location,
                endLocationId: trip.points[trip.points.length - 1].location,
              })
            }
            unavailableDays={disabledTripDays}
          />
        );
      })}
    </Box>
  );
}
