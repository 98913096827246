import { useEffect } from 'react';

import ArName from '../assets/ar-name.png';
import ArNameColored from '../assets/ar-name-colored.png';
import ArNameOnBlue from '../assets/ar-name-on-blue.png';
import ArNameLogo from '../assets/ar-name+logo.png';
import ArNameLogoColored from '../assets/ar-name+logo-colored.png';
import ArNameLogoColoredOnBlue from '../assets/ar-name+logo-colored-on-blue.png';
import ArrowBus from '../assets/arrow-bus.png';
import Background1 from '../assets/background_1.jpg';
import Background2 from '../assets/background_2.png';
import Background3 from '../assets/background_3.png';
import BusFront from '../assets/bus-front.png';
import BusLateral from '../assets/bus-lateral.png';
import EnName from '../assets/en-name.png';
import EnNameColored from '../assets/en-name-colored.png';
import EnNameOnBlue from '../assets/en-name-on-blue.png';
import EnNameLogo from '../assets/en-name+logo.png';
import EnNameLogoColored from '../assets/en-name+logo-colored.png';
import EnNameLogoColoredOnBlue from '../assets/en-name+logo-colored-on-blue.png';
import Logo from '../assets/logo.png';
import LogoColored from '../assets/logo-colored.png';
import LogoColoredOnBlue from '../assets/logo-colored-on-blue.png';
import Map from '../assets/map.png';
import PricesImage from '../assets/price.jpg';
import PricesBackground from '../assets/prices-background.jpeg';
import ZainCashCodePayment from '../assets/zain-cash-code-payment.png';

const cachesImages = (images: string[]) => {
  for (let index = 0; index < images.length; index++) {
    const img = new Image();
    img.src = images[index];
  }
};

export default function useImagesPreloaded() {
  useEffect(() => {
    const images = [
      Logo,
      LogoColored,
      LogoColoredOnBlue,

      ArNameColored,
      ArNameOnBlue,
      ArName,
      ArNameLogoColoredOnBlue,
      ArNameLogoColored,
      ArNameLogo,

      EnNameColored,
      EnNameOnBlue,
      EnName,
      EnNameLogoColoredOnBlue,
      EnNameLogoColored,
      EnNameLogo,

      Background1,
      Background2,
      Background3,
      Map,
      BusLateral,
      BusFront,
      PricesBackground,
      PricesImage,
      ArrowBus,

      ZainCashCodePayment,
    ];

    cachesImages(images);
  }, []);
}
